import { defineStore } from 'pinia';
import { useStoreCountry } from '../client/country';
import { DEFAULT_LOCALE } from '@/config/constants';
import { LangsEnum, LangsType } from '@/types/pages.types';

export const useStoreLanguage = defineStore('language', {
  state: () => ({
    language: DEFAULT_LOCALE as LangsType,
  }),
  getters: {
    langPrefix(state): string {
      return state.language !== DEFAULT_LOCALE ? `/${state.language}` : '';
    },
  },
  actions: {
    setLocale(locale: LangsType) {
      this.language = locale;
    },
    setLocaleByCountry() {
      const country = useStoreCountry().userCountry;

      if (country) {
        const localeMatch = LangsEnum[country.toLowerCase()];

        if (localeMatch) {
          this.language = localeMatch;
        }
      }
    },
  },
});
