<template>
  <div
    class="bitInputCheckbox"
    :class="{
      bitInputCheckboxError: isError,
      bitInputCheckboxDisabled: disabled,
      bitInputCheckboxReadonly: readonly,
      bitInputCheckboxIndeterminate: indeterminate,
    }"
  >
    <label class="bitInputCheckboxLabel">
      <input
        type="checkbox"
        :checked="isChecked"
        :value="value"
        :readonly="readonly"
        :indeterminate="indeterminate"
        class="bitInputCheckboxInput"
        :class="{ active: indeterminate }"
        :gtmid="gtmid"
        @change="onChange"
      />
      <span class="bitInputCheckboxChecked" />

      <span v-if="$slots.prependInputIcon" class="bitInputCheckboxIcon">
        <slot name="prependInputIcon" />
      </span>

      <span v-if="labelText.length >= 1" class="bitInputCheckboxName">
        {{ labelText }}
      </span>
      <span v-else-if="$slots.label" class="bitInputCheckboxName">
        <slot name="label" />
      </span>
    </label>
    <div v-if="isError" class="bitCheckboxErrorText">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        fill="none"
        viewBox="0 0 18 18"
        class="bitCheckboxIcon"
      >
        <path
          fill="#ED3C3C"
          fill-rule="evenodd"
          d="M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9m0 15.75A6.75 6.75 0 1 1 15.75 9 6.757 6.757 0 0 1 9 15.75m0-4.432c.621 0 1.125-.503 1.125-1.125v-5.25a1.125 1.125 0 0 0-2.25 0v5.25c0 .622.504 1.125 1.125 1.125m1.129 1.807a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0"
          clip-rule="evenodd"
        />
      </svg>
      must be completed
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent } from 'vue';

type tValue = Record<string, string | number>;
export default defineComponent({
  props: {
    modelValue: {
      type: [Boolean, Array] as PropType<boolean | Array<string | tValue>>,
      required: true,
    },
    value: {
      type: [String, Object] as PropType<string | tValue>,
      default: null,
    },
    labelText: {
      type: String,
      default: '',
    },
    gtmid: {
      type: String,
      default: null,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    fieldToCompareValue: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const onChange = (event: Event) => {
      const checked = (event.target as HTMLInputElement).checked;
      if (Array.isArray(props.modelValue)) {
        // @ts-ignore
        let newValue = [...props.modelValue];

        if (checked) {
          newValue.push(props.value);
        } else {
          newValue = props.fieldToCompareValue
            ? (newValue as Array<tValue>).filter(
                (v) =>
                  v[props.fieldToCompareValue] !==
                  (props.value as tValue)[props.fieldToCompareValue]
              )
            : newValue.filter((v) => v !== props.value);
        }

        emit('update:modelValue', newValue);
      } else {
        emit('update:modelValue', checked);
      }
    };

    const isChecked = computed(() => {
      if (Array.isArray(props.modelValue)) {
        if (props.fieldToCompareValue) {
          return (props.modelValue as Array<tValue>).some(
            (v) =>
              v[props.fieldToCompareValue] === (props.value as tValue)[props.fieldToCompareValue]
          );
        }
        return props.modelValue.includes(props.value);
      }
      return props.modelValue;
    });

    return {
      onChange,
      isChecked,
    };
  },
});
</script>

<style lang="scss" src="./checkbox.scss"></style>
