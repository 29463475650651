<template>
  <div>
    <div v-if="show === 'lion'" class="modalLoginLion modal-overlay">
      <transition :duration="{ enter: 500, leave: 200 }" name="globalModalAnimation">
        <ModalComponent modal="signup">
          <ModalSignupLion
            :first-title="secondTitle"
            :second-title="template.thirdTitle"
            :step-title="template.stepTitle"
            :modal-redirect-path="modalRedirectPath"
            @change-currency="onChangeCurrency"
          />
        </ModalComponent>
      </transition>
    </div>

    <div v-if="show === 'terms'" class="modalLoginLion modal-overlay">
      <transition :duration="{ enter: 500, leave: 200 }" name="globalModalAnimation">
        <ModalComponent modal="terms">
          <ModalTerms :terms-rules-text="template.termsRulesText" />
        </ModalComponent>
      </transition>
    </div>

    <div class="modern2LionLogos" v-if="storePlatform.isMobile">
      <a :href="template.logoHref" class="modern2LionLogo" :gtmid="`7bitMain${abbr}Promo`">
        <img v-lazy="template.logo.url" alt="logoLion" />
      </a>
      <a :href="template.logoHrefPartner" rel="nofollow" class="modern2LionLogo">
        <img v-if="template.partnersLogoTop" v-lazy="template.partnersLogoTop.url" alt="logoLion" />
      </a>
    </div>

    <div class="modern2LionBanner">
      <div class="modern2LionLogos" v-if="storePlatform.isDesktop || storePlatform.isTablet">
        <a :href="template.logoHref" class="modern2LionLogo" :gtmid="`7bitMain${abbr}Promo`">
          <img v-lazy="template.logo.url" alt="logoLion" />
        </a>
        <a :href="template.logoHrefPartner" rel="nofollow" class="modern2LionLogo">
          <img
            v-if="template.partnersLogoTop"
            v-lazy="template.partnersLogoTop.url"
            alt="logoLion"
          />
        </a>
      </div>

      <div class="modern2LionBannerBorder" />
      <div class="modern2LionBannerBlock" v-bind="storePlatform.dataPlatformAttr">
        <!-- <img :src="bannerSrc" alt="banner" /> -->
        <img class="modern2LionBannerBg" :src="bannerUrl" alt="banner" />
        <div
          v-if="storePlatform.isDesktop || storePlatform.isTablet"
          class="modern2LionBannerText"
          :class="template.bannerTextPosition"
        >
          <Markdown
            :auto-link-parse="false"
            :text="template.firstTitle"
            class="modern2LionTopTitle"
          />
          <Markdown
            :auto-link-parse="false"
            :text="secondTitle"
            class="modern2LionTitle"
            :class="{ smallSize: secondTitle.length > 16 }"
          />
          <Markdown :auto-link-parse="false" :text="template.thirdTitle" class="modern2LionText" />

          <div class="modern2LionBonusCode" :class="{ copied: isCopied }" v-if="template.bonusCode">
            <img
              class="modern2LionBonusCodeBg"
              v-lazy="require('~/assets/images/common/bgBonusCode.svg')"
              alt="codeCode"
            />
            <span class="modern2LionBonusCodeText">
              {{ template.bonusCodeText }}
            </span>
            <div class="modern2LionBonusCodeBlock" @click="copyToClipboard(template.bonusCode)">
              {{ isCopied ? template.bonusCodeTextCopied : template.bonusCode }}
              <span class="modern2LionBonusCodeButton">
                <img
                  class="modern2LionBonusCodeImg"
                  v-lazy="require('~/assets/images/common/bonusCopy.svg')"
                  alt="codeCode"
                />
                <img
                  class="modern2LionBonusCodeCopiedImg"
                  v-lazy="require('~/assets/images/common/copied.svg')"
                  alt="copied"
                />
              </span>
            </div>
          </div>

          <div class="modern2LionAnimation">
            <span class="modern2LionAnimationLine line01" />
            <span class="modern2LionAnimationLine line03" />
            <span class="modern2LionAnimationLine line05" />
            <span class="modern2LionAnimationLine line08" />
            <span class="modern2LionAnimationLine first" />
            <button
              class="modern2LionButton"
              @click.prevent="registerHandler"
              :gtmid="`RegStartMain${abbr}Promo`"
            >
              <span class="modern2LionButtonText">{{ registerTitle }}</span>
            </button>
            <span class="modern2LionAnimationLine" />
            <span class="modern2LionAnimationLine line08" />
            <span class="modern2LionAnimationLine line05" />
            <span class="modern2LionAnimationLine line03" />
            <span class="modern2LionAnimationLine line01" />
          </div>
          <div class="modern2LionTerms" @click="openTermsModal">
            {{ template.termsTitle }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="storePlatform.isMobile"
      class="modern2LionBannerText"
      :class="template.bannerTextPosition"
    >
      <Markdown :auto-link-parse="false" :text="template.firstTitle" class="modern2LionTopTitle" />
      <Markdown :auto-link-parse="false" :text="secondTitle" class="modern2LionTitle" />
      <Markdown :auto-link-parse="false" :text="template.thirdTitle" class="modern2LionText" />

      <div class="modern2LionBonusCode" :class="{ copied: isCopied }" v-if="template.bonusCode">
        <img
          class="modern2LionBonusCodeBg"
          v-lazy="require('~/assets/images/common/bgBonusCode.svg')"
          alt="codeCode"
        />
        <span class="modern2LionBonusCodeText">
          {{ template.bonusCodeText }}
        </span>
        <div class="modern2LionBonusCodeBlock" @click="copyToClipboard(template.bonusCode)">
          {{ isCopied ? template.bonusCodeTextCopied : template.bonusCode }}
          <span class="modern2LionBonusCodeButton">
            <img
              class="modern2LionBonusCodeImg"
              v-lazy="require('~/assets/images/common/bonusCopy.svg')"
              alt="codeCode"
            />
            <img
              class="modern2LionBonusCodeCopiedImg"
              v-lazy="require('~/assets/images/common/copied.svg')"
              alt="copied"
            />
          </span>
        </div>
      </div>

      <div class="modern2LionAnimation">
        <span class="modern2LionAnimationLine line01" />
        <span class="modern2LionAnimationLine line03" />
        <span class="modern2LionAnimationLine line05" />
        <span class="modern2LionAnimationLine line08" />
        <span class="modern2LionAnimationLine first" />
        <button
          class="modern2LionButton"
          @click.prevent="registerHandler"
          :gtmid="`RegStartMain${abbr}Promo`"
        >
          <span class="modern2LionButtonText">{{ registerTitle }}</span>
        </button>
        <span class="modern2LionAnimationLine" />
        <span class="modern2LionAnimationLine line08" />
        <span class="modern2LionAnimationLine line05" />
        <span class="modern2LionAnimationLine line03" />
        <span class="modern2LionAnimationLine line01" />
      </div>
      <div class="modern2LionTerms" @click="openTermsModal">
        {{ template.termsTitle }}
      </div>
    </div>

    <div class="modern2LionStepeContainer">
      <div class="modern2LionSteper">
        <div class="modern2LionSteperBlock modern2LionSteperBlockOne">
          <svg
            v-if="isBonusHubTemplate"
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="66"
            viewBox="0 0 38 66"
            fill="none"
            class="modern2LionSteperNumber"
          >
            <path
              d="M36.9003 1.03886L36.9477 0.80111H36.7053H4.27905H4.11624L4.08408 0.960716L0.805028 17.235L0.757039 17.4732H1H11.6879L2.26227 64.9613L2.21511 65.1989H2.45736H23.9534H24.1166L24.1484 65.0389L36.9003 1.03886Z"
              fill="#C447FF"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
          </svg>
          <svg
            v-else
            class="modern2LionSteperNumber"
            width="71"
            height="127"
            viewBox="0 0 71 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M70.6759 1.14336L70.7233 0.905602H70.4809H7.3809H7.21808L7.18592 1.06521L0.805028 32.7343L0.757039 32.9724H1H22.0274L3.64087 125.607L3.59371 125.845H3.83595H45.6663H45.8294L45.8613 125.685L70.6759 1.14336Z"
              fill="url(#paint0_linear_58_1795)"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
            <defs>
              <linearGradient
                id="paint0_linear_58_1795"
                x1="1"
                y1="1.10449"
                x2="75.5461"
                y2="4.21401"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA300" />
                <stop offset="1" stop-color="#FEC705" />
              </linearGradient>
            </defs>
          </svg>
          <Markdown
            :auto-link-parse="false"
            :text="template.firstStep"
            class="modern2LionSteperCenter"
          />
          <div class="modern2LionAnimation">
            <span class="modern2LionAnimationLine" />
            <span class="modern2LionAnimationLine line08" />
            <span class="modern2LionAnimationLine line05" />
          </div>
        </div>
        <svg
          class="modern2LionSteperArrow"
          width="140"
          height="71"
          viewBox="0 0 140 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_58_1799)">
            <path
              d="M9.69653 12.6494C8.93439 11.411 9.82541 9.81641 11.2796 9.81641H32.08C32.7255 9.81641 33.3248 10.1513 33.6631 10.701L48.8472 35.3752L33.6631 60.0494C33.3248 60.5992 32.7255 60.9341 32.08 60.9341H11.2796C9.82542 60.9341 8.93439 59.3395 9.69653 58.101L23.6816 35.3752L9.69653 12.6494Z"
              fill="#C447FF"
              fill-opacity="0.6"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_d_58_1799)">
            <path
              d="M50.5911 12.6494C49.8289 11.411 50.7199 9.81641 52.1741 9.81641H72.9746C73.6201 9.81641 74.2193 10.1513 74.5576 10.701L89.7418 35.3752L74.5576 60.0494C74.2193 60.5992 73.6201 60.9341 72.9746 60.9341H52.1741C50.7199 60.9341 49.8289 59.3395 50.5911 58.101L64.5762 35.3752L50.5911 12.6494Z"
              fill="#C447FF"
              fill-opacity="0.8"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter2_d_58_1799)">
            <path
              d="M91.4856 12.6494C90.7234 11.411 91.6145 9.81641 93.0687 9.81641H113.869C114.515 9.81641 115.114 10.1513 115.452 10.701L130.636 35.3752L115.452 60.0494C115.114 60.5992 114.515 60.9341 113.869 60.9341H93.0687C91.6145 60.9341 90.7234 59.3395 91.4856 58.101L105.471 35.3752L91.4856 12.6494Z"
              fill="#C447FF"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_58_1799"
              x="0.123851"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_58_1799"
              x="41.0184"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_58_1799"
              x="81.9129"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div class="modern2LionSteperBlock modern2LionSteperBlockTwo">
          <svg
            v-if="isBonusHubTemplate"
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="66"
            viewBox="0 0 60 66"
            fill="none"
            class="modern2LionSteperNumber"
          >
            <path
              d="M54.8813 48.4436H35.0618L44.2992 41.4721C49.8819 37.2954 53.8002 33.4949 56.037 30.0696C58.276 26.6412 59.3967 22.9089 59.3967 18.8771C59.3967 15.4363 58.3328 12.3544 56.2078 9.63819C54.085 6.86539 51.1474 4.70081 47.404 3.14044C43.6596 1.57962 39.4664 0.80111 34.8278 0.80111C28.7441 0.80111 23.2565 2.08854 18.3692 4.66723C13.484 7.18527 9.79732 10.577 7.31825 14.8442L7.22075 15.012L7.38614 15.1136L21.5046 23.784L21.6617 23.8805L21.7698 23.7311C23.1361 21.8416 24.646 20.4017 26.2976 19.4049L26.2993 19.4039C28.0097 18.3487 29.7725 17.8246 31.5904 17.8246C33.2979 17.8246 34.6274 18.234 35.6011 19.031L35.601 19.0311L35.6054 19.0344C36.6355 19.8308 37.1468 20.875 37.1468 22.1844C37.1468 23.6227 36.6268 25.0416 35.5683 26.4445L35.5682 26.4444L35.5651 26.4488C34.5635 27.8544 32.6943 29.6274 29.9402 31.7699C29.94 31.77 29.9398 31.7702 29.9396 31.7704L3.59265 51.881L3.53312 51.9265L3.51834 51.9999L0.910486 64.9608L0.862574 65.1989H1.10547H51.554H51.7165L51.7489 65.0396L55.0762 48.6821L55.1247 48.4436H54.8813Z"
              fill="#C447FF"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
          </svg>
          <svg
            v-else
            class="modern2LionSteperNumber"
            width="113"
            height="127"
            viewBox="0 0 113 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M104.178 93.6157H65.5746L83.7532 79.71C83.7534 79.7098 83.7537 79.7097 83.7539 79.7095C94.4649 71.5869 101.967 64.2071 106.244 57.5688C110.523 50.9274 112.664 43.7006 112.664 35.8925C112.664 29.2394 110.635 23.283 106.581 18.0301C102.528 12.6642 96.9158 8.46998 89.7531 5.44372C82.5893 2.41703 74.5631 0.905602 65.6779 0.905602C54.0239 0.905602 43.5175 3.4052 34.1631 8.40818C24.8109 13.2941 17.7606 19.8709 13.0209 28.1403L12.9255 28.3067L13.0883 28.408L40.1938 45.2802L40.3523 45.3789L40.4608 45.2269C43.0956 41.5335 46.0119 38.7127 49.2076 36.758L49.2093 36.7569C52.5189 34.6873 55.9357 33.6563 59.4626 33.6563C62.7687 33.6563 65.3662 34.4599 67.2773 36.0456L67.2773 36.0457L67.2816 36.0491C69.3045 37.6342 70.3131 39.7214 70.3131 42.3283C70.3131 45.1766 69.2962 47.977 67.2448 50.7326L67.2447 50.7326L67.2416 50.737C65.3023 53.4953 61.6959 56.9594 56.4051 61.1311C56.4049 61.1313 56.4047 61.1315 56.4045 61.1316L5.82058 100.267L5.7617 100.313L5.7472 100.386L0.740463 125.607L0.693293 125.845H0.935547H97.79H97.953L97.985 125.685L104.373 93.8537L104.421 93.6157H104.178Z"
              fill="url(#paint0_linear_58_1806)"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
            <defs>
              <linearGradient
                id="paint0_linear_58_1806"
                x1="0.935547"
                y1="1.10449"
                x2="120.269"
                y2="9.09463"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA300" />
                <stop offset="1" stop-color="#FEC705" />
              </linearGradient>
            </defs>
          </svg>

          <Markdown
            :auto-link-parse="false"
            :text="template.secondStep"
            class="modern2LionSteperCenter"
          />
          <div class="modern2LionAnimation">
            <span class="modern2LionAnimationLine" />
            <span class="modern2LionAnimationLine line08" />
            <span class="modern2LionAnimationLine line05" />
          </div>
        </div>
        <svg
          class="modern2LionSteperArrow"
          width="140"
          height="71"
          viewBox="0 0 140 71"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_58_1799)">
            <path
              d="M9.69653 12.6494C8.93439 11.411 9.82541 9.81641 11.2796 9.81641H32.08C32.7255 9.81641 33.3248 10.1513 33.6631 10.701L48.8472 35.3752L33.6631 60.0494C33.3248 60.5992 32.7255 60.9341 32.08 60.9341H11.2796C9.82542 60.9341 8.93439 59.3395 9.69653 58.101L23.6816 35.3752L9.69653 12.6494Z"
              fill="#C447FF"
              fill-opacity="0.6"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter1_d_58_1799)">
            <path
              d="M50.5911 12.6494C49.8289 11.411 50.7199 9.81641 52.1741 9.81641H72.9746C73.6201 9.81641 74.2193 10.1513 74.5576 10.701L89.7418 35.3752L74.5576 60.0494C74.2193 60.5992 73.6201 60.9341 72.9746 60.9341H52.1741C50.7199 60.9341 49.8289 59.3395 50.5911 58.101L64.5762 35.3752L50.5911 12.6494Z"
              fill="#C447FF"
              fill-opacity="0.8"
              shape-rendering="crispEdges"
            />
          </g>
          <g filter="url(#filter2_d_58_1799)">
            <path
              d="M91.4856 12.6494C90.7234 11.411 91.6145 9.81641 93.0687 9.81641H113.869C114.515 9.81641 115.114 10.1513 115.452 10.701L130.636 35.3752L115.452 60.0494C115.114 60.5992 114.515 60.9341 113.869 60.9341H93.0687C91.6145 60.9341 90.7234 59.3395 91.4856 58.101L105.471 35.3752L91.4856 12.6494Z"
              fill="#C447FF"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_58_1799"
              x="0.123851"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_58_1799"
              x="41.0184"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_58_1799"
              x="81.9129"
              y="0.522288"
              width="58.0179"
              height="69.7054"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="4.64706" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.768627 0 0 0 0 0.278431 0 0 0 0 1 0 0 0 1 0"
              />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_58_1799" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_58_1799"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div class="modern2LionSteperBlock modern2LionSteperBlockThree">
          <svg
            v-if="isBonusHubTemplate"
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="66"
            viewBox="0 0 66 66"
            fill="none"
            class="modern2LionSteperNumber"
          >
            <path
              d="M62.2836 14.1147L62.3376 14.0703L62.352 14.0018L65.0774 1.04093L65.1279 0.80111H64.8828H15.8255H15.6645L15.631 0.958633L12.2477 16.8692L12.1967 17.1095H12.4423H35.1922L24.1577 26.1422L24.1035 26.1866L24.0891 26.2552L21.3637 39.2161L21.3132 39.4559H21.5583H30.6743C32.8548 39.4559 34.4579 39.7681 35.5096 40.3683C36.5447 40.959 37.054 41.832 37.054 43.0112C37.054 44.6603 36.2845 45.9388 34.7113 46.8605C33.125 47.7301 30.8153 48.1754 27.7609 48.1754C24.9599 48.1754 22.0949 47.7906 19.1654 47.0199C16.2379 46.2498 13.5297 45.1245 11.0402 43.6447L10.88 43.5494L10.7746 43.7031L0.718802 58.3624L0.603598 58.5303L0.774222 58.6415C4.17791 60.8596 8.05198 62.5067 12.3944 63.5841L12.395 63.5843C16.7972 64.6609 21.4181 65.1989 26.2573 65.1989C32.5441 65.1989 38.2398 64.1823 43.3413 62.1456C48.4414 60.1095 52.4498 57.2907 55.3569 53.6845C58.329 50.0756 59.8189 46.039 59.8189 41.581C59.8189 37.9026 58.7081 34.6992 56.4846 31.9802C54.3288 29.3439 51.3408 27.4243 47.5326 26.2154L62.2836 14.1147Z"
              fill="#C447FF"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
          </svg>
          <svg
            v-else
            class="modern2LionSteperNumber"
            width="126"
            height="127"
            viewBox="0 0 126 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M120.27 26.4796L120.324 26.4352L120.339 26.3668L125.642 1.14542L125.692 0.905602H125.447H29.9841H29.8231L29.7896 1.06312L23.2059 32.0245L23.1548 32.2647H23.4005H68.1977L46.3173 50.1757L46.2631 50.2201L46.2487 50.2886L40.9452 75.51L40.8948 75.7498H41.1398H58.8792C63.134 75.7498 66.2929 76.3579 68.3818 77.55C70.454 78.7326 71.4819 80.4934 71.4819 82.8564C71.4819 86.1402 69.9419 88.6871 66.8277 90.5107C63.7004 92.2258 59.1683 93.0939 53.2099 93.0939C47.7419 93.0939 42.1506 92.3427 36.4355 90.8392C30.7224 89.3362 25.4362 87.14 20.576 84.2509L20.4158 84.1556L20.3104 84.3093L0.742239 112.836L0.627035 113.003L0.797659 113.115C7.40176 117.418 14.9207 120.616 23.3526 122.708L23.3532 122.708C31.9041 124.799 40.8811 125.845 50.2838 125.845C62.4974 125.845 73.5566 123.87 83.4588 119.916C93.3595 115.964 101.131 110.496 106.765 103.507C112.522 96.5161 115.405 88.7028 115.405 80.0733C115.405 72.9563 113.257 66.7654 108.959 61.5098C104.729 56.3366 98.8371 52.5848 91.2962 50.2479L120.27 26.4796Z"
              fill="url(#paint0_linear_58_1822)"
              stroke="#9E5500"
              stroke-width="0.397781"
            />
            <defs>
              <linearGradient
                id="paint0_linear_58_1822"
                x1="0.90625"
                y1="1.10449"
                x2="134.015"
                y2="11.0568"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFA300" />
                <stop offset="1" stop-color="#FEC705" />
              </linearGradient>
            </defs>
          </svg>

          <Markdown
            :auto-link-parse="false"
            :text="template.thirdStep"
            class="modern2LionSteperCenter"
          />
          <div class="modern2LionAnimation">
            <span class="modern2LionAnimationLine" />
            <span class="modern2LionAnimationLine line08" />
            <span class="modern2LionAnimationLine line05" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import Markdown from '@/components/markdown.vue';
import ModalComponent from '@/components/modalComponent/modalComponent.vue';
import ModalSignupLion from '@/components/modalSignup/modalSignupLion.vue';
import { useAbbrFromPath } from '@/composables/abbrFromPath';
import { useGtmPush } from '@/composables/gtm';
import usePromoModal from '@/composables/promoModalState';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { RegFormInterface } from '@/types/regForm.interface';
import { copyText } from '@/utils/vue3-clipboard2';

const ModalTerms = defineAsyncComponent(() => import('@/components/modalComponent/modalTerms.vue'));

export default defineComponent({
  components: {
    ModalComponent,
    ModalSignupLion,
    ModalTerms,
    Markdown,
  },
  props: {
    modalRedirectPath: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const storeSsPlayer = useStoreSsPlayer();
    const storeRegForm = useStoreRegForm();
    const storeCountry = useStoreCountry();
    const storePlatform = useStorePlatform();
    const storeSystemHosts = useStoreSystemHosts();
    const storeLanding = useStoreLanding();
    const storeCurrencyMenu = useStoreCurrencyMenu();

    const { openModal, show } = usePromoModal();
    const { abbr } = useAbbrFromPath();
    const { push } = useGtmPush();

    const isCopied = ref(false);

    const isPlayerAuthorised = computed((): boolean => storeSsPlayer.isPlayerAuthorised);

    const regForm = computed((): RegFormInterface => storeRegForm.formData);

    const template = computed(() => storeLanding.currentPage?.template);

    const registerTitle = computed(() => {
      return template.value?.formButtonText || regForm.value.register_button_title;
    });

    const isBonusHubTemplate = computed(
      () => template.value.__component === 'landing-templates.bonus-hub'
    );

    const onChangeCurrency = (newCurrency: string) => {
      storeLanding.setCurrency(newCurrency);
    };

    const loadLocalizedUserData = () => {
      if (storeCountry.userCountry) {
        if (Array.isArray(storeLanding.secondTitle)) {
          const titleForCountry = storeLanding.secondTitle.find(
            (c) => c.country === storeCountry.userCountry
          );
          if (titleForCountry) {
            storeLanding.setCurrency(titleForCountry.currency);
            return;
          }
        }

        const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
          currency.defaultForCountries.some((country) => country === storeCountry.userCountry)
        );

        if (storeLanding.currency) {
          storeLanding.setCurrency(storeLanding.currency);
        } else if (defaultCurrencyForCountry?.currencyCode) {
          storeLanding.setCurrency(defaultCurrencyForCountry.currencyCode);
        } else {
          storeLanding.setCurrency(storeCurrencyMenu.activeCurrencies[0]?.currencyCode);
        }
      }
    };

    const registerHandler = () => {
      if (isPlayerAuthorised.value) {
        window.location.href = `https://${storeSystemHosts.redirectHost}`;
      } else {
        push({
          event: `RegStart${abbr.value}Promo`,
          user_id: '',
          timestamp: Math.round(Date.now() / 1000),
        });
        openModal('lion');
        emit('setModalRedirectPath', '');
      }
    };

    const openTermsModal = () => {
      openModal('terms');
    };

    const copyToClipboard = async (text: string) => {
      try {
        await copyText(text);
        isCopied.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    watch(() => storeCurrencyMenu.activeCurrencies, loadLocalizedUserData, { immediate: true });

    watch(
      () => isCopied.value,
      () => {
        if (isCopied.value) {
          window.setTimeout(() => {
            isCopied.value = false;
          }, 3000);
        }
      }
    );

    return {
      storePlatform,
      isCopied,
      template,
      registerTitle,
      isBonusHubTemplate,
      onChangeCurrency,
      registerHandler,
      openTermsModal,
      copyToClipboard,
      abbr,
      show,
      currentPage: computed(() => storeLanding.currentPage),
      secondTitle: computed(() => storeLanding.secondTitle),
      bannerUrl: computed(() => storeLanding.bannerUrl),
    };
  },
});
</script>
