<template>
  <div class="modal-content-signup">
    <div class="modal-banner">
      <BannerSlider
        slider-type="signUp"
        :slider-nav="false"
        :slider-change="false"
        :link-disable="true"
        :firstTitle="firstTitle"
        :secondTitle="secondTitle"
        :thirdTitle="thirdTitle"
      />
    </div>
    <div class="modal-inside">
      <form>
        <div class="modal-first-step" :class="{ 'modal-hidden-step': step != 1 }">
          <h3 class="modal-title">{{ regFormData.header }}</h3>
          <div
            v-if="userCountry != 'US' && !isMiraxFrenchLand"
            class="form-group"
            :class="{ 'form-group-error': !!regErrors.country }"
          >
            <CustomSelect
              v-model="regForm.country"
              :options="countriesList"
              name="country"
              :title="profileFormData.country_title"
              :is-error="regFormValidator.country.$error"
            />
            <label class="placeholder" :class="{ 'placeholder-focus': regForm.country }">
              {{ profileFormData.country_title }}
            </label>
            <span v-if="regFormValidator.country.$errors[0]" class="error-text">
              {{ regFormValidator.country.$errors[0].$message }}
            </span>
          </div>
          <div class="form-group" :class="{ 'form-group-error': !!regErrors.email }">
            <input
              id="email"
              v-model="regForm.email"
              type="email"
              name="email"
              class="input"
              autocomplete="email"
              :class="{
                placeholderFocusInput: regForm.email,
                formInputError: regFormValidator.email.$errors.length,
                formInputSuccess:
                  !regErrors.email &&
                  !regFormValidator.email.$errors.length &&
                  regFormValidator.email.$anyDirty,
              }"
              @keydown.space.prevent
              @input="touchHeap.touch(regFormValidator, 'email')"
            />
            <label class="placeholder" :class="{ 'placeholder-focus': regForm.email }">
              {{ regFormData.email_title }}
            </label>
            <span v-if="regErrors.email" class="error-text">
              {{ regErrors.email }}
            </span>
            <span v-if="regFormValidator.email.$errors[0] && !regErrors.email" class="error-text">
              {{ regFormValidator.email.$errors[0].$message }}
            </span>
          </div>
          <div
            class="form-group form-password"
            :class="{ 'form-group-error': !!regErrors.password }"
          >
            <input
              id="password"
              v-model="regForm.password"
              :type="passwordType"
              name="password"
              class="input"
              autocomplete="current-password"
              :class="{
                placeholderFocusInput: regForm.password,
                formInputError: regFormValidator.password.$errors.length,
                [passwordWeakness]:
                  !regFormValidator.password.$errors.length &&
                  !regErrors.password &&
                  passwordWeakness,
              }"
              @input="touchHeap.touch(regFormValidator, 'password')"
            />
            <span class="form-password__icon" @click="showPasswordToggle()">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0.363647C4.36364 0.363647 1.25818 2.62547 0 5.81819C1.25818 9.01092 4.36364 11.2727 8 11.2727C11.6364 11.2727 14.7418 9.01092 16 5.81819C14.7418 2.62547 11.6364 0.363647 8 0.363647ZM8 9.45456C5.99273 9.45456 4.36364 7.82547 4.36364 5.81819C4.36364 3.81092 5.99273 2.18183 8 2.18183C10.0073 2.18183 11.6364 3.81092 11.6364 5.81819C11.6364 7.82547 10.0073 9.45456 8 9.45456ZM8 3.63637C6.79273 3.63637 5.81818 4.61092 5.8181 5.81819C5.81818 7.02547 6.79273 8.00001 8 8.00001C9.20727 8.00001 10.1818 7.02547 10.1818 5.81819C10.1818 4.61092 9.20727 3.63637 8 3.63637Z"
                  fill="#C6A9E3"
                  fill-opacity="0.65"
                />
              </svg>
            </span>
            <span
              class="form-password__icon marax-form-password__icon"
              @click="showPasswordToggle()"
            >
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.66961 6.02727C9.66961 7.05994 8.83247 7.89708 7.79981 7.89708C6.76714 7.89708 5.93 7.05994 5.93 6.02727C5.93 4.99461 6.76714 4.15747 7.79981 4.15747C8.83247 4.15747 9.66961 4.99461 9.66961 6.02727Z"
                  fill="#C9CBCF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.424458 5.47327C1.74717 2.73088 4.55188 0.833374 7.7998 0.833374C11.0477 0.833374 13.8524 2.73088 15.1751 5.47327C15.3414 5.81953 15.3414 6.23504 15.1751 6.5813C13.8524 9.32368 11.0477 11.2212 7.7998 11.2212C4.55188 11.2212 1.74717 9.32368 0.424458 6.5813C0.258253 6.23504 0.258253 5.81953 0.424458 5.47327ZM4.68346 6.02728C4.68346 7.74474 6.08235 9.14363 7.7998 9.14363C9.51726 9.14363 10.9161 7.74474 10.9161 6.02728C10.9161 4.30983 9.51726 2.91094 7.7998 2.91094C6.08235 2.91094 4.68346 4.30983 4.68346 6.02728Z"
                  fill="#C9CBCF"
                />
              </svg>
            </span>
            <span
              class="form-password__icon form-password__show-password"
              @click="showPasswordToggle()"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.1172 17.0654C10.7231 17.201 11.3531 17.2726 12 17.2726C15.6364 17.2726 18.7418 15.0108 20 11.8181C19.343 10.1509 18.1823 8.7375 16.7053 7.76749L15.1377 9.97983C15.4546 10.5193 15.6364 11.1476 15.6364 11.8181C15.6364 13.8253 14.0073 15.4544 12 15.4544C11.7626 15.4544 11.5304 15.4316 11.3056 15.3881L10.1172 17.0654ZM12.3041 13.979C13.3668 13.8315 14.1818 12.9221 14.1818 11.8181C14.1818 11.6709 14.1673 11.5272 14.1397 11.3883L12.3041 13.979ZM11.6889 9.65815L9.8589 12.2408C9.83218 12.1041 9.81818 11.9628 9.81818 11.8181C9.81818 10.7165 10.6296 9.80861 11.6889 9.65815ZM12.6888 8.24695C12.4657 8.20413 12.2355 8.18171 12 8.18171C9.99273 8.18171 8.36364 9.8108 8.36364 11.8181C8.36364 12.4865 8.5443 13.113 8.85944 13.6514L7.29042 15.8658C5.81547 14.8959 4.65637 13.4837 4 11.8181C5.25818 8.62534 8.36364 6.36353 12 6.36353C12.6449 6.36353 13.2732 6.43467 13.8773 6.56953L12.6888 8.24695Z"
                  fill="#C6A9E3"
                  fill-opacity="0.65"
                />
                <rect
                  x="6.78003"
                  y="18.2397"
                  width="18"
                  height="1"
                  transform="rotate(-54.6804 6.78003 18.2397)"
                  fill="#8C77A0"
                />
              </svg>
            </span>
            <label class="placeholder" :class="{ 'placeholder-focus': regForm.password }">
              {{ regFormData.password_title }}
            </label>
            <span v-if="regErrors.password" class="error-text">
              {{ regErrors.password }}
            </span>
            <span
              v-if="regFormValidator.password.$errors[0] && !regErrors.password"
              class="error-text"
            >
              {{ regFormValidator.password.$errors[0].$message }}
            </span>
            <span
              v-if="
                !regFormValidator.password.$errors[0] &&
                !regErrors.password &&
                passwordWeaknsssMessage
              "
              class="error-text"
              :class="{
                [passwordWeakness]: passwordWeakness,
              }"
            >
              {{ passwordWeaknsssMessage }}
            </span>
          </div>
          <div class="form-group" :class="{ 'form-group-error': !!regErrors.currency }">
            <CustomSelect v-model="regForm.currency" :options="activeCurrenciesOptions" />
            <label class="placeholder" :class="{ 'placeholder-focus': regForm.currency }">
              {{ regFormData.currency_title }}
            </label>
            <span class="error-text" v-if="regErrors.currency">
              {{ regErrors.currency }}
            </span>
          </div>
          <div class="form-group formPromoGroup">
            <div class="form-checkbox">
              <input
                id="checkboxEmail"
                v-model="profileForm.receive_promos"
                type="checkbox"
                name="checkbox-email"
                class="form-checkbox__checkbox"
                :title="profileFormData.receive_promos_title"
              />
              <label for="checkboxEmail" class="form-checkbox__label">
                {{ profileFormData.receive_promos_title }}
              </label>
            </div>
          </div>
          <div class="form-group formPromoGroup">
            <div
              class="form-checkbox"
              :class="{
                'form-group-error':
                  !!regErrors.terms_acceptance || !!regFormValidator.terms_acceptance.$errors[0],
              }"
            >
              <input
                id="agree"
                v-model="regForm.terms_acceptance"
                class="form-checkbox__checkbox"
                type="checkbox"
                name="agree"
              />
              <label for="agree" class="form-checkbox__label">
                {{ regFormData.terms_title }}
                <a :href="termsLink" class="link">{{ regFormData.terms_link_title }}</a>
                <span class="form-checkbox-span">*</span>
              </label>
              <span class="error-text modal-error-show-modern" v-if="regErrors.terms_acceptance">
                {{ regErrors.terms_acceptance }}
              </span>
            </div>
          </div>

          <div class="form-group bonus-code">
            <input
              id="bonus"
              v-model="regForm.bonus_code"
              type="text"
              name="bonus"
              class="input"
              :class="{
                placeholderFocusInput: regForm.bonus_code,
                formInputError: regFormValidator.bonus_code.$errors.length,
                formInputSuccess:
                  !regErrors.bonus_code &&
                  !regFormValidator.bonus_code.$errors.length &&
                  regFormValidator.bonus_code.$anyDirty,
              }"
              @input="regForm.bonus_code = $event.target.value"
            />
            <label class="placeholder" :class="{ 'placeholder-focus': regForm.bonus_code }">
              {{ regFormData.enter_bonus_code_title }}
            </label>
            <span v-if="regErrors.bonus_code" class="error-text">
              {{ regErrors.bonus_code }}
            </span>
            <span
              v-if="regFormValidator.bonus_code.$errors[0] && !regErrors.bonus_code"
              class="error-text"
            >
              {{ regFormValidator.bonus_code.$errors[0].$message }}
            </span>
          </div>

          <div id="captcha_v2" />

          <div class="form-group text-center">
            <button
              class="button loader"
              name="sign-up"
              :disabled="waitPlease"
              @click.prevent="nextStep()"
            >
              {{
                isMiraxFrenchLand
                  ? profileFormData.next_button_title
                  : regFormData.register_button_title
              }}
              <span class="icon-loader" />
            </button>
            <p v-if="isMobile" class="modal-text">
              {{ regFormData.have_account_question }}
              <a :href="loginLink" class="link" target="_blank">{{ regFormData.signin_title }}</a>
            </p>
          </div>
          <div class="modal-footer" style="display: none">
            <a href="#">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M18.6399 2.34842e-05H1.62717C1.04082 2.34842e-05 0.56543 0.497024 0.56543 1.11102V18.903C0.573082 19.51 1.04656 20 1.62813 20H10.7945V12.265H8.29986V9.24002H10.784V7.01702C10.784 4.43302 12.2933 3.02702 14.5048 3.02702C15.248 3.02402 15.9922 3.06302 16.7316 3.14402V5.83402H15.206C14.0103 5.83402 13.7731 6.42902 13.7731 7.30302V9.23902H16.6407L16.2677 12.265H13.7731V20H18.6399C19.2214 19.996 19.692 19.504 19.6959 18.896V1.11002C19.6959 0.500023 19.2243 0.00302344 18.6399 -0.000976562V2.34842e-05Z"
                  fill="white"
                />
              </svg>
              Faceboock
            </a>
            <a href="#">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.6084 3.48C10.2249 3.48 11.3154 4.21 11.9371 4.82L14.3667 2.34C12.8745 0.89 10.9328 0 8.6084 0C5.24145 0 2.33362 2.02 0.917969 4.96L3.70145 7.22C4.39971 5.05 6.33188 3.48 8.6084 3.48Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.8727 9.20011C16.8727 8.46011 16.8154 7.92011 16.691 7.36011H8.6084V10.7001H13.3527C13.2571 11.5301 12.7406 12.7801 11.5927 13.6201L14.3093 15.8201C15.9354 14.2501 16.8727 11.9401 16.8727 9.20011Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3.7113 10.78C3.52957 10.22 3.42435 9.61996 3.42435 8.99996C3.42435 8.37996 3.52957 7.77996 3.70174 7.21996L0.918261 4.95996C0.334783 6.17996 0 7.54996 0 8.99996C0 10.45 0.334783 11.82 0.918261 13.04L3.7113 10.78Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.6086 18C10.933 18 12.8843 17.2 14.3095 15.82L11.593 13.62C10.866 14.15 9.89034 14.52 8.6086 14.52C6.33208 14.52 4.39991 12.95 3.71121 10.78L0.927734 13.04C2.34339 15.98 5.24165 18 8.6086 18Z"
                  fill="white"
                />
              </svg>
              Google
            </a>
          </div>
        </div>
      </form>
      <form v-if="isMiraxFrenchLand">
        <div class="modal-two-step" :class="{ 'modal-hidden-step': step != 2 }">
          <div class="arrowBack" @click="prevStep">
            <svg
              width="18"
              height="12"
              viewBox="0 0 18 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0387 0.34375L10.6278 1.76127L13.898 5.01624L0.292969 5.02993L0.294982 7.02993L13.8627 7.01627L10.6476 10.2465L12.0651 11.6574L17.7087 5.98735L12.0387 0.34375Z"
                fill="white"
              />
            </svg>
          </div>
          <div class="form-group" :class="{ 'form-group-error': !!profileErrors.first_name }">
            <input
              id="name"
              v-model="profileForm.first_name"
              type="text"
              name="first-name"
              class="input"
              :class="{
                formInputError: profileFormFirstValidator.first_name.$errors.length,
                formInputSuccess:
                  !profileFormFirstValidator.first_name.$errors.length &&
                  profileFormFirstValidator.first_name.$anyDirty,
              }"
              @input="touchHeap.touch(profileFormFirstValidator, 'first_name')"
            />
            <label class="placeholder" :class="{ 'placeholder-focus': profileForm.first_name }">
              {{ profileFormData.first_name_title }}
            </label>
            <span v-if="profileErrors.first_name" class="error-text">
              {{ profileErrors.first_name }}
            </span>
            <span
              v-if="profileFormFirstValidator.first_name.$errors[0] && !profileErrors.first_name"
              class="error-text"
            >
              {{ profileFormFirstValidator.first_name.$errors[0].$message }}
            </span>
          </div>
          <div class="form-group" :class="{ 'form-group-error': !!profileErrors.last_name }">
            <input
              id="last-name"
              v-model="profileForm.last_name"
              type="text"
              name="last-name"
              class="input"
              :class="{
                formInputError: profileFormFirstValidator.last_name.$errors.length,
                formInputSuccess:
                  !profileFormFirstValidator.last_name.$errors.length &&
                  profileFormFirstValidator.last_name.$anyDirty,
              }"
              @input="touchHeap.touch(profileFormFirstValidator, 'last_name')"
            />
            <label class="placeholder" :class="{ 'placeholder-focus': profileForm.last_name }">
              {{ profileFormData.last_name_title }}
            </label>
            <span v-if="profileErrors.last_name" class="error-text">
              {{ profileErrors.last_name }}
            </span>
            <span
              v-if="profileFormFirstValidator.last_name.$errors[0] && !profileErrors.last_name"
              class="error-text"
            >
              {{ profileFormFirstValidator.last_name.$errors[0].$message }}
            </span>
          </div>
          <div class="form-group" :class="{ 'form-group-error': !!profileErrors.date_of_birth }">
            <span class="modal-three-block">
              <div>
                <CustomSelect
                  v-model="profileForm.year"
                  :options="listYears"
                  :is-error="profileFormFirstValidator.year.$error"
                />
                <label class="placeholder" :class="{ 'placeholder-focus': profileForm.year }">
                  {{ profileFormData.year_title }}
                </label>
              </div>
              <div>
                <CustomSelect
                  v-model="profileForm.month"
                  :options="listMonths"
                  :is-error="profileFormFirstValidator.month.$error"
                />
                <label class="placeholder" :class="{ 'placeholder-focus': profileForm.month }">
                  {{ profileFormData.month_title }}
                </label>
              </div>
              <div>
                <CustomSelect
                  v-model="profileForm.day"
                  :options="listDays"
                  :is-error="profileFormFirstValidator.day.$error"
                />
                <label class="placeholder" :class="{ 'placeholder-focus': profileForm.day }">
                  {{ profileFormData.day_title }}
                </label>
              </div>
            </span>
            <span v-if="profileErrors.date_of_birth" class="error-text">
              {{ profileErrors.date_of_birth }}
            </span>
            <span
              v-if="
                (profileFormFirstValidator.day.$errors[0] ||
                  profileFormFirstValidator.month.$errors[0] ||
                  profileFormFirstValidator.year.$errors[0]) &&
                !profileErrors.date_of_birth
              "
              class="error-text"
            >
              {{
                (profileFormFirstValidator.day.$errors[0] &&
                  profileFormFirstValidator.day.$errors[0].$message) ||
                (profileFormFirstValidator.month.$errors[0] &&
                  profileFormFirstValidator.month.$errors[0].$message) ||
                (profileFormFirstValidator.year.$errors[0] &&
                  profileFormFirstValidator.year.$errors[0].$message)
              }}
            </span>
          </div>
          <div
            class="form-group modal-phone"
            :class="{ 'form-group-error': !!profileErrors.mobile_phone }"
          >
            <TelephoneInput
              v-if="!isMiraxFrenchLand"
              v-model="profileForm.mobile_phone"
              :profile-form-data="profileFormData"
              :player-country="userCountry"
              :is-error="
                !!profileFormFirstValidator.mobile_phone.$errors[0] || !!profileErrors.mobile_phone
              "
              :is-success="
                !profileErrors.mobile_phone &&
                !profileFormFirstValidator.mobile_phone.$error &&
                profileFormFirstValidator.mobile_phone.$anyDirty
              "
              @blur="profileFormFirstValidator.mobile_phone.$touch()"
              @input="clearProfileErrorField('mobile_phone')"
            />
            <template v-else>
              <div class="form-group">
                <input
                  id="name"
                  type="number"
                  name="mobile_phone_code"
                  v-model="mobile_phone_code"
                  class="input"
                  :class="{
                    formInputError: profileFormFirstValidator.mobile_phone.$errors.length,
                    formInputSuccess:
                      !profileFormFirstValidator.mobile_phone.$errors.length &&
                      profileFormFirstValidator.mobile_phone.$anyDirty,
                  }"
                />
                <label class="placeholder" :class="{ 'placeholder-focus': mobile_phone_code }">
                  Code de téléphone
                </label>
              </div>
              <div class="form-group">
                <input
                  id="name"
                  v-model="profileForm.mobile_phone"
                  type="text"
                  name="mobile_phone"
                  class="input"
                  :class="{
                    formInputError: profileFormFirstValidator.mobile_phone.$errors.length,
                    formInputSuccess:
                      !profileFormFirstValidator.mobile_phone.$errors.length &&
                      profileFormFirstValidator.mobile_phone.$anyDirty,
                  }"
                  @input="touchHeap.touch(profileFormFirstValidator, 'mobile_phone')"
                />
                <label
                  class="placeholder"
                  :class="{ 'placeholder-focus': profileForm.mobile_phone }"
                >
                  {{ profileFormData.mobile_phone_title }}
                </label>
              </div>
            </template>
            <span v-if="profileErrors.mobile_phone" class="error-text">
              {{ profileErrors.mobile_phone }}
            </span>
            <span
              v-if="
                profileFormFirstValidator.mobile_phone.$errors[0] && !profileErrors.mobile_phone
              "
              class="error-text"
            >
              {{ profileFormFirstValidator.mobile_phone.$errors[0].$message }}
            </span>
          </div>
          <div class="modal-footer">
            <button
              class="modal-back"
              disabled="disabled"
              style="visibility: hidden"
              :title="profileFormData.back_button_title"
            >
              {{ profileFormData.back_button_title }}
            </button>
            <button
              class="button default"
              :title="profileFormData.next_button_title"
              @click.prevent="nextStep()"
            >
              {{
                isMiraxFrenchLand
                  ? regFormData.register_button_title
                  : profileFormData.next_button_title
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  useModalSignup,
  commonComponents,
  commonEmits,
  commonProps,
} from '@/composables/useModalSignup';

export default defineComponent({
  components: commonComponents,
  props: commonProps,
  emits: commonEmits,
  setup(props, { emit }) {
    const {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    } = useModalSignup(props, emit);

    return {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    };
  },
});
</script>
<style lang="scss" src="./modalSignupMirax.scss"></style>
