import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bitInputCheckboxLabel" }
const _hoisted_2 = ["checked", "value", "readonly", "indeterminate", "gtmid"]
const _hoisted_3 = {
  key: 0,
  class: "bitInputCheckboxIcon"
}
const _hoisted_4 = {
  key: 1,
  class: "bitInputCheckboxName"
}
const _hoisted_5 = {
  key: 2,
  class: "bitInputCheckboxName"
}
const _hoisted_6 = {
  key: 0,
  class: "bitCheckboxErrorText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bitInputCheckbox", {
      bitInputCheckboxError: _ctx.isError,
      bitInputCheckboxDisabled: _ctx.disabled,
      bitInputCheckboxReadonly: _ctx.readonly,
      bitInputCheckboxIndeterminate: _ctx.indeterminate,
    }])
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "checkbox",
        checked: _ctx.isChecked,
        value: _ctx.value,
        readonly: _ctx.readonly,
        indeterminate: _ctx.indeterminate,
        class: _normalizeClass(["bitInputCheckboxInput", { active: _ctx.indeterminate }]),
        gtmid: _ctx.gtmid,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 42, _hoisted_2),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "bitInputCheckboxChecked" }, null, -1)),
      (_ctx.$slots.prependInputIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "prependInputIcon")
          ]))
        : _createCommentVNode("", true),
      (_ctx.labelText.length >= 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.labelText), 1))
        : (_ctx.$slots.label)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "label")
            ]))
          : _createCommentVNode("", true)
    ]),
    (_ctx.isError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[2] || (_cache[2] = [
          _createElementVNode("svg", {
            xmlns: "http://www.w3.org/2000/svg",
            width: "18",
            height: "18",
            fill: "none",
            viewBox: "0 0 18 18",
            class: "bitCheckboxIcon"
          }, [
            _createElementVNode("path", {
              fill: "#ED3C3C",
              "fill-rule": "evenodd",
              d: "M9 0a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9m0 15.75A6.75 6.75 0 1 1 15.75 9 6.757 6.757 0 0 1 9 15.75m0-4.432c.621 0 1.125-.503 1.125-1.125v-5.25a1.125 1.125 0 0 0-2.25 0v5.25c0 .622.504 1.125 1.125 1.125m1.129 1.807a1.125 1.125 0 1 1-2.25 0 1.125 1.125 0 0 1 2.25 0",
              "clip-rule": "evenodd"
            })
          ], -1),
          _createTextVNode(" must be completed ")
        ])))
      : _createCommentVNode("", true)
  ], 2))
}