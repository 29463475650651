<template>
  <div id="promo">
    <router-view />
  </div>
</template>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid';
import { computed, onBeforeMount, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import usePromoModal from './composables/promoModalState';
import { useCookie } from './composables/useCookie';
import { useOneSignalID } from './composables/useOneSignalID';
import { useStorePlatform } from './store/client/platform';
import { useStoreStag } from './store/client/stag';
import { useStoreAutochangeData } from './store/strapi/autochangeData';
import { useUbidex } from '@/composables/useUbidex';
import { scriptLoader } from '~/utils/scriptLoader';

const route = useRoute();
const storeAutochangeData = useStoreAutochangeData();
const platformStore = useStorePlatform();
platformStore.checkPlatform();

const { saveUserType } = useCookie();
const { show } = usePromoModal();

const bodyClass = computed(() => {
  const bodyClass: string[] = [];

  if (platformStore.isDesktop) {
    bodyClass.push('isDesktop');
  } else if (platformStore.isTablet) {
    bodyClass.push('isTablet');
  } else if (platformStore.isMobile) {
    bodyClass.push('isMobile');
  }

  if (show.value) {
    bodyClass.push('stop-scroll');
  }

  bodyClass.push(platformStore.isPortraitOrientation ? 'isPortrait' : 'isAlbum');

  return bodyClass.join(' ');
});

watch(
  bodyClass,
  (classes) => {
    const classesArr = [
      'isDesktop',
      'isTablet',
      'isMobile',
      'isPortrait',
      'isAlbum',
      'stop-scroll',
    ];
    classesArr.forEach((cl) => {
      document.body.classList.remove(cl);
    });
    classes.split(' ').forEach((cl) => {
      if (!document.body.classList.contains(cl)) {
        document.body.classList.add(cl);
      }
    });
  },
  { immediate: true }
);

onBeforeMount(() => {
  saveUserType();
  useStoreStag().init();
  useOneSignalID().init();
  storeAutochangeData.load();
});

onMounted(() => {
  scriptLoader('https://cdn.seondf.com/js/v5/agent.js', 'seondf').then(() => {
    // @ts-ignore
    window.seon.config({
      host: 'seondf.com',
      session_id: uuid(),
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      max_proxy_delay: 1000,
    });

    const stag = String(route?.query?.stag || '');

    useUbidex().sendEvent({
      event: 'visit',
      stag,
    });
  });
});
</script>

<style lang="scss">
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 300;
  src: url('~assets/fonts/BlissPro-Light.eot');
  src: url('~assets/fonts/BlissPro-Light.woff') format('woff');
  src: url('~assets/fonts/BlissPro-Light.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 400;
  src: url('~assets/fonts/BlissPro.eot');
  src: url('~assets/fonts/BlissPro.woff') format('woff');
  src: url('~assets/fonts/BlissPro.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 500;
  src: url('~assets/fonts/BlissPro-Medium.eot');
  src: url('~assets/fonts/BlissPro-Medium.woff') format('woff');
  src: url('~assets/fonts/BlissPro-Medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 800;
  src: url('~assets/fonts/BlissPro-ExtraBold.eot');
  src: url('~assets/fonts/BlissPro-ExtraBold.woff') format('woff');
  src: url('~assets/fonts/BlissPro-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'NunitoSans';
  font-weight: 300;
  src: url('~assets/fonts/NunitoSans_7pt-Light.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'NunitoSans';
  font-weight: 400;
  src: url('~assets/fonts/NunitoSans_7pt-Regular.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'NunitoSans';
  font-weight: 500;
  src: url('~assets/fonts/NunitoSans_7pt-Medium.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'NunitoSans';
  font-weight: 700;
  src: url('~assets/fonts/NunitoSans_7pt-Bold.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'NunitoSans';
  font-weight: 800;
  src: url('~assets/fonts/NunitoSans_7pt-ExtraBold.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-indifferent;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    height: 60px;
    background-color: $color-active-border;
    border: 1px solid $color-7bit-accent;
    border-radius: 50px;
  }
}

body.stop-scroll {
  overflow: hidden;
  width: 100%;
  .page-level-one {
    pointer-events: none;
  }
}
</style>
