export enum PasswordSecurityTypeEnum {
  STRONG = 'STRONG',
  MEDIUM = 'MEDIUM',
  WEAK = 'WEAK',
  NONE = 0,
}

export default (password: string): PasswordSecurityTypeEnum => {
  if (!password) {
    return PasswordSecurityTypeEnum.NONE;
  }

  if (/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{11,40}$/.test(password)) {
    return PasswordSecurityTypeEnum.STRONG;
  }
  if (password.length > 9) {
    return PasswordSecurityTypeEnum.MEDIUM;
  }
  if (password.length > 7) {
    return PasswordSecurityTypeEnum.WEAK;
  }

  return PasswordSecurityTypeEnum.NONE;
};
