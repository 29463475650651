<template>
  <div class="bitGenderSelection">
    <BitRadioButton
      :model-value="modelValue"
      radio-value="m"
      variant="group"
      :label="maleTitle"
      gtmid="SignUp_TypeSex"
      form-field-name="checkboxGender"
      :is-error="isError"
      :disabled="isDisabled"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #prependIcon>
        <img src="~assets/images/common/ico_male.svg" />
      </template>
    </BitRadioButton>
    <BitRadioButton
      :model-value="modelValue"
      radio-value="f"
      variant="group"
      :label="femaleTitle"
      gtmid="SignUp_TypeSex"
      form-field-name="checkboxGender"
      :is-error="isError"
      :disabled="isDisabled"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #prependIcon>
        <img src="~assets/images/common/ico_female.svg" />
      </template>
    </BitRadioButton>
    <p v-if="isError && errorMessage" class="bitGenderSelectionErrorMessage">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import BitRadioButton from '../controlElements/radioButton/radioButton.vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maleTitle: {
      type: String,
      required: true,
    },
    femaleTitle: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  components: {
    BitRadioButton,
  },
  emits: ['update:modelValue'],
});
</script>

<style lang="scss" src="./genderSelection.scss"></style>
