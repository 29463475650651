import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { useTransport } from '../abstract/transport';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { StrapiProfileFormInterface } from '~/types/strapiProfileForm.interface';

export const useStoreProfileForm = defineStore('profileForm', {
  state: () => ({
    data: {} as StrapiProfileFormInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    formData(state): StrapiProfileFormInterface {
      return state.data;
    },
    isLoading(state): boolean {
      return state.status === LoadingStatusEnum.loading;
    },
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    /**
     * function for load profile form from strapi
     *
     * @param context - context object
     * @param options - request options
     **/
    async load(): Promise<void> {
      if (!this.isLoaded) {
        this.status = LoadingStatusEnum.loading;
      }

      // const host = `7bitcasino.com/dinamic-content`;
      const host = `${
        location.host === 'localhost' || location.host.includes('local.7bitcasino.com')
          ? '7bitcasino.com'
          : location.host
      }/dinamic-content`;
      const response = await useTransport().get(`https://${host}/profile-forms`, {
        lang: useStoreLanguage().language,
      });

      if (response && response[0].lang) {
        this.data = response[0];
        this.status = LoadingStatusEnum.loaded;
      } else {
        this.status = LoadingStatusEnum.error;
      }
    },
  },
});
