import { defineStore } from 'pinia';

export const useStoreAuth = defineStore('authStrapi', {
  state: () => ({
    promoTooltipText: '',
    partnerpromotionsTooltipText: '',
    crossSaleTitle: '',
    signUpGoogleTitle: '',
    dividerTitle: '',
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI(itemAuth) {
      this.promoTooltipText = itemAuth.data.promoTooltipText;
      this.partnerpromotionsTooltipText = itemAuth.data.partnerpromotionsTooltipText;
      this.crossSaleTitle = itemAuth.data.crossSaleTitle;
      this.signUpGoogleTitle = itemAuth.data.signUpGoogleTitle;
      this.dividerTitle = itemAuth.data.dividerTitle;
    },
  },
});
