import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref } from "vue"

const _hoisted_1 = {
  class: "home-slider__wrapper",
  "data-slider-type": "signUp"
}
const _hoisted_2 = { class: "home-slider" }
const _hoisted_3 = { class: "home-slider-item" }
const _hoisted_4 = { class: "home-sliderText" }
const _hoisted_5 = {
  key: 0,
  class: "home-sliderFirstTitle"
}
const _hoisted_6 = {
  key: 1,
  class: "home-sliderSecondTitle"
}
const _hoisted_7 = {
  key: 2,
  class: "home-sliderThirdTitle"
}
const _hoisted_8 = {
  class: "home-slider-item-element active",
  "data-link-disabled": true
}
const _hoisted_9 = ["src", "data-orientation", "width", "height"]

import { computed } from 'vue';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreLanding } from '@/store/strapi/landing';


export default /*@__PURE__*/_defineComponent({
  __name: 'bannerSlide',
  props: {
    firstTitle: {},
    secondTitle: {},
    thirdTitle: {},
    imageLocal: { type: Boolean }
  },
  setup(__props: any) {



const storePlatform = useStorePlatform();
const storeLanding = useStoreLanding();

const template = computed(() => storeLanding.currentPage?.template);

const bannerImg = computed(() => {
  if (storePlatform.isMobile) {
    return template.value.modalBannerMobile;
  } else {
    return template.value.modalBannerDesktop;
  }
});

const url = computed((): string => bannerImg.value?.url);

const width = computed(() => bannerImg.value?.width);

const height = computed(() => bannerImg.value?.height);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_ctx.firstTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.firstTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.secondTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.secondTitle), 1))
            : _createCommentVNode("", true),
          (_ctx.thirdTitle)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.thirdTitle), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("img", {
            ref: "banner",
            src: url.value,
            class: "home-slider-item-element-img",
            "data-orientation": _unref(storePlatform).isPortraitOrientation ? 'portrait' : 'landscape',
            "data-slider-type": "signUp",
            alt: "",
            width: width.value,
            height: height.value
          }, null, 8, _hoisted_9)
        ])
      ])
    ])
  ]))
}
}

})