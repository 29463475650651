export default (): {
  touch: (validator: any, propname: string) => void;
  [key: string]: unknown;
} => ({
  touch(validator: any, propname): void {
    if (this[propname]) {
      // @ts-ignore
      clearInterval(this[propname] as NodeJS.Timeout);
    }
    this[propname] = setTimeout(() => {
      validator[propname].$touch();
      delete this[propname];
    }, 1500);
  },
});
