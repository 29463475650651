<template>
  <div v-if="isLoaded && currentPage" :data-theme="theme" class="bonusHubNeonWrapper">
    <BonusHubNeonMainPage
      :modal-redirect-path="modalRedirectPath"
      @setModalRedirectPath="setModalRedirectPath($event)"
    />
    <div class="contentBlocksContainer">
      <div
        v-if="welcomePackBonuses.length && currentPage.template.welcomePackBlock"
        class="contentBlock contentBlockWelcome"
        :data-position="currentPage.template.welcomePackBlock.position"
      >
        <h3 class="contentBlockTitle">
          {{ currentPage.template.welcomePackBlock.title }}
        </h3>
        <h4 class="contentBlockSubTitle">{{ currentPage.template.welcomePackBlock.subtitle }}</h4>
        <div class="welcomeBonusesWrapper">
          <WelcomeCard
            v-for="(bonus, indx) in welcomePackBonuses"
            :key="bonus.bonusId"
            :title="currentPage.template.welcomePackBlock[`depTitle${indx + 1}`]"
            :offer="currentPage.template.welcomePackBlock[`depOfferTitle${indx + 1}`]"
            :card-icon="currentPage.template.welcomePackBlock[`cardIconFront${indx + 1}`]"
            :card-icon-back="currentPage.template.welcomePackBlock[`cardIconBack${indx + 1}`]"
          />
        </div>
      </div>
      <div
        v-if="promotionBonusesToShow.length && currentPage.template.promotionsBlock"
        class="contentBlock"
        :data-position="currentPage.template.promotionsBlock.position"
      >
        <h3 class="contentBlockTitle">
          {{ currentPage.template.promotionsBlock.title }}
        </h3>
        <h4 class="contentBlockSubTitle">{{ currentPage.template.promotionsBlock.subtitle }}</h4>
        <div class="promotionsWrapper">
          <PromotionCard
            v-for="(bonus, index) in promotionBonusesToShow"
            :key="bonus.bonusId"
            :bonus="bonus"
            variant="neon"
            :index="index"
            :get-bonus-title="currentPage.template.promotionsBlock.getBonusTitle"
            @setModalRedirectPath="setModalRedirectPath($event)"
          />
        </div>
        <BitButton
          v-if="!storePlatform.isDesktop && promotionBonuses.length > 4"
          type="secondary"
          class="promoShowMoreBtn"
          :class="{ showMoreButtonRotated: isAllPromotionBonusesShown }"
          prependIcon="arrow-down.svg"
          @click="togglePromotionBonusesDisplayCount"
        >
          {{ isAllPromotionBonusesShown ? 'Show less' : 'Show more' }}
        </BitButton>
      </div>
      <div
        v-if="tournamentsToShow.length && currentPage.template.tournamentsBlock"
        class="contentBlock"
        :data-position="currentPage.template.tournamentsBlock.position"
      >
        <h3 class="contentBlockTitle">
          {{ currentPage.template.tournamentsBlock.title }}
        </h3>
        <h4 class="contentBlockSubTitle">{{ currentPage.template.tournamentsBlock.subtitle }}</h4>
        <div class="tournamentsWrapper">
          <!-- <pre style="color: #fff">{{ tournamentsToShow }}</pre> -->
          <TournamentCard
            v-for="(race, index) in tournamentsToShow"
            :key="race.raceData.ssId"
            :index="index"
            :race="race"
            variant="neon"
            :prize-pool-title="currentPage.template.tournamentsBlock.prizePoolTitle"
            @setModalRedirectPath="setModalRedirectPath($event)"
          />
        </div>
        <BitButton
          v-if="!storePlatform.isDesktop && tournamentsExtended.length > 4"
          class="promoShowMoreBtn"
          :class="{ showMoreButtonRotated: isAllPromotionBonusesShown }"
          prependIcon="arrow-down.svg"
          type="secondary"
          @click="toggleTournamentsDisplayCount"
        >
          {{ isAllTournamentsShown ? 'Show less' : 'Show more' }}
        </BitButton>
      </div>
      <BuyCryptoBlock
        v-if="currentPage.template.buyCryptoBlock"
        class="contentBlock"
        :data-position="currentPage.template.buyCryptoBlock.position"
        :block="currentPage.template.buyCryptoBlock"
        @setModalRedirectPath="setModalRedirectPath($event)"
      />
    </div>

    <BonusHubNeonFooter :template="currentPage.template" />
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from 'vue';
import { BitButton } from '@/components/lib/shared';
import BonusHubNeonFooter from '@/components/partials/bonusHubNeonFooter.vue';
import BonusHubNeonMainPage from '@/components/partials/bonusHubNeonMainPage.vue';
import { useStoreStrapi } from '@/store/abstract/strapi';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsTournaments } from '@/store/softswiss/ssTournaments';
import { useBonusesStore } from '@/store/strapi/bonuses';
import { useStoreLanding } from '@/store/strapi/landing';

const PromotionCard = defineAsyncComponent(() => import('@/components/partials/promotionCard.vue'));
const TournamentCard = defineAsyncComponent(
  () => import('@/components/partials/tournamentCard.vue')
);
const WelcomeCard = defineAsyncComponent(() => import('@/components/partials/welcomeCardNeon.vue'));
const BuyCryptoBlock = defineAsyncComponent(
  () => import('@/components/partials/buyCryptoBlock.vue')
);

export default defineComponent({
  components: {
    BonusHubNeonMainPage,
    BonusHubNeonFooter,
    WelcomeCard,
    PromotionCard,
    TournamentCard,
    BitButton,
    BuyCryptoBlock,
  },
  setup() {
    const storeBonuses = useBonusesStore();
    const storeTournaments = useStoreSsTournaments();
    const storePlatform = useStorePlatform();
    const storeLanding = useStoreLanding();
    const storeStrapi = useStoreStrapi();

    const modalRedirectPath = ref('');
    const isAllPromotionBonusesShown = ref(false);
    const isAllTournamentsShown = ref(false);

    const promotionBonusesToShow = computed(() =>
      storePlatform.isDesktop || isAllPromotionBonusesShown.value
        ? storeBonuses.promotionBonuses
        : storeBonuses.promotionBonuses.slice(0, 4)
    );

    const tournamentsToShow = computed(() =>
      storePlatform.isDesktop || isAllTournamentsShown.value
        ? storeTournaments.tournamentsExtended
        : storeTournaments.tournamentsExtended.slice(0, 4)
    );

    const togglePromotionBonusesDisplayCount = () => {
      isAllPromotionBonusesShown.value = !isAllPromotionBonusesShown.value;
    };

    const toggleTournamentsDisplayCount = () => {
      isAllTournamentsShown.value = !isAllTournamentsShown.value;
    };

    const setModalRedirectPath = (path: string) => {
      modalRedirectPath.value = path;
    };

    onBeforeMount(() => {
      storeTournaments.loadTournaments();
      storeStrapi.loadBonusesAndRacesData();
    });

    onMounted(() => {
      document.body.classList.add('modern2HubNeonLand');
    });

    return {
      storePlatform,
      promotionBonusesToShow,
      tournamentsToShow,
      modalRedirectPath,
      isAllPromotionBonusesShown,
      isAllTournamentsShown,
      togglePromotionBonusesDisplayCount,
      toggleTournamentsDisplayCount,
      setModalRedirectPath,
      theme: computed(() => storeLanding.currentPage?.theme),
      currentPage: computed(() => storeLanding.currentPage),
      isLoaded: computed(() => storeLanding.isLoaded),
      welcomePackBonuses: computed(() => storeBonuses.welcomePackBonuses),
      promotionBonuses: computed(() => storeBonuses.promotionBonuses),
      tournamentsExtended: computed(() => storeTournaments.tournamentsExtended),
    };
  },
});
</script>

<style lang="scss" src="./bonusHubPromoNeon.scss"></style>
