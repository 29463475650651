<template>
  <div class="home-slider__wrapper" data-slider-type="signUp">
    <div class="home-slider">
      <div class="home-slider-item">
        <div class="home-sliderText">
          <p class="home-sliderFirstTitle" v-if="firstTitle">
            {{ firstTitle }}
          </p>
          <p class="home-sliderSecondTitle" v-if="secondTitle">
            {{ secondTitle }}
          </p>
          <p class="home-sliderThirdTitle" v-if="thirdTitle">
            {{ thirdTitle }}
          </p>
        </div>
        <div class="home-slider-item-element active" :data-link-disabled="true">
          <img
            ref="banner"
            :src="url"
            class="home-slider-item-element-img"
            :data-orientation="storePlatform.isPortraitOrientation ? 'portrait' : 'landscape'"
            data-slider-type="signUp"
            alt=""
            :width="width"
            :height="height"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreLanding } from '@/store/strapi/landing';

defineProps<{
  firstTitle?: string;
  secondTitle?: string;
  thirdTitle?: string;
  imageLocal?: boolean;
}>();

const storePlatform = useStorePlatform();
const storeLanding = useStoreLanding();

const template = computed(() => storeLanding.currentPage?.template);

const bannerImg = computed(() => {
  if (storePlatform.isMobile) {
    return template.value.modalBannerMobile;
  } else {
    return template.value.modalBannerDesktop;
  }
});

const url = computed((): string => bannerImg.value?.url);

const width = computed(() => bannerImg.value?.width);

const height = computed(() => bannerImg.value?.height);
</script>

<style lang="scss" scoped src="./bannerSlider.scss"></style>
<style lang="scss" src="./bannerSliderElement.scss"></style>
