<template>
  <div class="modern2LionContent modern2LionNeonContent">
    <div v-if="isConfirmCloseShowing" class="modalConfirmCloseLion modal-overlay">
      <div class="modalConfirmCloseLionContent">
        <p class="modalConfirmCloseLionHeading">
          {{ storeLandingComponents.signUpCloseConfirm.title }}
        </p>
        <Markdown
          :auto-link-parse="false"
          :text="`${storeLandingComponents.signUpCloseConfirm.textBeforeVariable} ${secondTitle} ${storeLandingComponents.signUpCloseConfirm.textAfterVariable}`"
          class="modalConfirmCloseLionText"
          :class="{ smallSize: secondTitle.length > 16 }"
        />
        <BitButton @click.prevent="hideConfirmClose" type="secondary" is-fluid-width>
          {{ storeLandingComponents.signUpCloseConfirm.btnBackToSignup }}
        </BitButton>
        <BitLinkButton class="modalConfirmCloseLionCloseBtn" @click.prevent="closeModalConfirmed">
          {{ storeLandingComponents.signUpCloseConfirm.btnClose }}
        </BitLinkButton>
      </div>
    </div>
    <div v-if="show === 'lion'" class="modalLoginLion modal-overlay">
      <transition :duration="{ enter: 500, leave: 200 }" name="globalModalAnimation">
        <ModalComponent modal="signup" isWithCloseConfirm @confirmClose="showConfirmClose">
          <ModalSignupBonusHubNeon
            :first-title="secondTitle"
            :second-title="template.thirdTitle"
            :step-title="template.stepTitle"
            :title-highlight="template.signUpHighlightText"
            :modal-redirect-path="modalRedirectPath"
            @change-currency="onChangeCurrency"
            @close="showConfirmClose"
            @stepChanged="onStepChanged"
          />
        </ModalComponent>
      </transition>
    </div>

    <div v-if="show === 'terms'" class="modalLoginLion modal-overlay">
      <transition :duration="{ enter: 500, leave: 200 }" name="globalModalAnimation">
        <ModalComponent modal="terms">
          <ModalTerms
            :terms-rules-text="template.termsRulesText"
            :title="template.termsTitle"
            @close="closeModal"
          />
        </ModalComponent>
      </transition>
    </div>
    <div class="modern2LionBanner">
      <div class="modern2LionBannerContent">
        <div class="modern2LionLogos">
          <a :href="template.logoHref" class="modern2LionLogo" :gtmid="`7bitMain${abbr}Promo`">
            <img :src="template.logo.url" alt="logoLion" />
          </a>
          <a
            v-if="template.partnersLogoTop"
            :href="template.logoHrefPartner"
            rel="nofollow"
            class="modern2LionLogo"
          >
            <img :src="template.partnersLogoTop.url" alt="logoLion" />
          </a>
        </div>
        <div class="modern2LionBannerBlock" v-bind="storePlatform.dataPlatformAttr">
          <div class="modern2LionBannerHolder">
            <img
              class="modern2LionBannerBlockBg modern2LionBannerBlockBgDesk"
              :src="template.bannerDesktop.url"
              alt=""
            />
            <img
              class="modern2LionBannerBlockBg modern2LionBannerBlockBgMob"
              :src="template.bannerMobile.url"
              alt=""
            />
          </div>

          <div class="modern2LionBannerText" :class="template.bannerTextPosition">
            <Markdown
              :auto-link-parse="false"
              :text="template.firstTitle"
              class="modern2LionTopTitle"
            />
            <Markdown
              :auto-link-parse="false"
              :text="secondTitle"
              class="modern2LionTitle"
              :class="{ smallSize: secondTitle.length > 16 }"
            />
            <Markdown
              :auto-link-parse="false"
              :text="template.thirdTitle"
              class="modern2LionText"
            />
            <div class="modern2LionBannerCodeButtonContent">
              <div
                class="modern2LionBonusCode"
                :class="{ copied: isCopied }"
                v-if="template.bonusCode"
              >
                <img
                  class="modern2LionBonusCodeBorder modern2LionBonusCodeBorderTop"
                  src="~/assets/images/common/ico_bonus_code_border.svg"
                  alt="codeCode"
                />
                <img
                  class="modern2LionBonusCodeBorder modern2LionBonusCodeBorderBot"
                  src="~/assets/images/common/ico_bonus_code_border.svg"
                  alt="codeCode"
                />

                <div class="modern2LionBonusCodeHolder">
                  <span class="modern2LionBonusCodeText">
                    {{ template.bonusCodeText }}
                  </span>
                  <div
                    class="modern2LionBonusCodeBlock"
                    @click="copyToClipboard(template.bonusCode)"
                  >
                    {{ template.bonusCode }}
                    <span class="modern2LionBonusCodeButton">
                      <span v-if="isCopied" class="modern2LionBonusCodeButtonCopied">
                        {{ template.bonusCodeTextCopied }}
                      </span>
                      <img
                        class="modern2LionBonusCodeImg"
                        src="~/assets/images/common/ico_copy_grey.svg"
                        alt="codeCode"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="modern2LionActionButtonHolder">
                <BitButton
                  @click.prevent="registerHandler"
                  is-fluid-width
                  no-margin
                  variant="landing-lg"
                  :gtmid="`RegStartMain${abbr}Promo`"
                >
                  {{ registerTitle }}
                </BitButton>
                <div class="modern2LionTerms" @click="openTermsModal">
                  {{ template.termsTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modern2LionTopPartnersLines">
      <div class="modern2LionTopPartnersLine modern2LionTopPartnersLineReversed">
        <ul class="modern2LionTopPartnersLineContent">
          <li
            class="modern2LionTopPartnersLineItem"
            v-for="(partner, i) in storeFooter.partnersLink"
            :key="i"
          >
            <img :src="partner.img_src.url" :alt="partner.alt || ''" />
          </li>
        </ul>
        <!-- Mirrors the content above -->
        <ul class="modern2LionTopPartnersLineContent" aria-hidden="true">
          <li
            class="modern2LionTopPartnersLineItem"
            v-for="(partner, i) in storeFooter.partnersLink"
            :key="i"
          >
            <img :src="partner.img_src.url" :alt="partner.alt || ''" />
          </li>
        </ul>
      </div>
      <div class="modern2LionTopPartnersLine">
        <ul class="modern2LionTopPartnersLineContent">
          <li
            class="modern2LionTopPartnersLineItemSmall"
            v-for="(payment, i) in equalPaymentMethodsLink"
            :key="i"
          >
            <img :src="payment.img_src.url" :alt="payment.alt || ''" />
          </li>
        </ul>
        <!-- Mirrors the content above -->
        <ul class="modern2LionTopPartnersLineContent" aria-hidden="true">
          <li
            class="modern2LionTopPartnersLineItemSmall"
            v-for="(payment, i) in equalPaymentMethodsLink"
            :key="i"
          >
            <img :src="payment.img_src.url" :alt="payment.alt || ''" />
          </li>
        </ul>
      </div>
    </div>

    <div class="modern2LionStepsContainer">
      <div class="modern2LionSteper">
        <div class="modern2LionSteperBlock modern2LionSteperBlockOne">
          <p class="modern2LionSteperBlockNumber">1</p>
          <Markdown
            :auto-link-parse="false"
            :text="template.firstStep"
            class="modern2LionSteperCenter"
          />
        </div>
        <img
          class="modern2LionSteperArrowTriple"
          v-lazy="require('~/assets/images/common/ico_arrows_steps.svg')"
          alt=""
        />
        <img
          class="modern2LionSteperArrowSingle"
          v-lazy="require('~/assets/images/common/ico_arrow_steps_single.svg')"
          alt=""
        />

        <div class="modern2LionSteperBlock modern2LionSteperBlockTwo">
          <p class="modern2LionSteperBlockNumber">2</p>
          <Markdown
            :auto-link-parse="false"
            :text="template.secondStep"
            class="modern2LionSteperCenter"
          />
        </div>
        <img
          class="modern2LionSteperArrowTriple"
          v-lazy="require('~/assets/images/common/ico_arrows_steps.svg')"
          alt=""
        />
        <img
          class="modern2LionSteperArrowSingle"
          v-lazy="require('~/assets/images/common/ico_arrow_steps_single.svg')"
          alt=""
        />

        <div class="modern2LionSteperBlock modern2LionSteperBlockThree">
          <p class="modern2LionSteperBlockNumber">3</p>
          <Markdown
            :auto-link-parse="false"
            :text="template.thirdStep"
            class="modern2LionSteperCenter"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue';
import { BitButton, BitLinkButton } from '@/components/lib/shared';
import Markdown from '@/components/markdown.vue';
import ModalComponent from '@/components/modalComponent/modalComponent.vue';
import ModalSignupBonusHubNeon from '@/components/modalSignup/modalSignupBonusHubNeon.vue';
import { useAbbrFromPath } from '@/composables/abbrFromPath';
import { useGtmPush } from '@/composables/gtm';
import usePromoModal from '@/composables/promoModalState';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreFooter } from '@/store/strapi/footer';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreLandingComponents } from '@/store/strapi/landingsComponents';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { RegFormInterface } from '@/types/regForm.interface';
import { copyText } from '@/utils/vue3-clipboard2';

const ModalTerms = defineAsyncComponent(
  () => import('@/components/modalComponent/modalTermsBonusHubNeon.vue')
);

export default defineComponent({
  components: {
    ModalComponent,
    ModalSignupBonusHubNeon,
    ModalTerms,
    Markdown,
    BitButton,
    BitLinkButton,
  },
  props: {
    modalRedirectPath: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(_props, { emit }) {
    const storeSsPlayer = useStoreSsPlayer();
    const storeRegForm = useStoreRegForm();
    const storeFooter = useStoreFooter();
    const storeLandingComponents = useStoreLandingComponents();
    const storeCountry = useStoreCountry();
    const storePlatform = useStorePlatform();
    const storeSystemHosts = useStoreSystemHosts();
    const storeLanding = useStoreLanding();
    const storeCurrencyMenu = useStoreCurrencyMenu();

    const { openModal, show, closeModal } = usePromoModal();
    const { abbr } = useAbbrFromPath();
    const { push } = useGtmPush();

    const isCopied = ref(false);
    const isConfirmCloseShowing = ref(false);
    const signupStep = ref(1);

    const isPlayerAuthorised = computed((): boolean => storeSsPlayer.isPlayerAuthorised);

    const regForm = computed((): RegFormInterface => storeRegForm.formData);

    const template = computed(() => storeLanding.currentPage?.template);

    const registerTitle = computed(
      () => template.value?.formButtonText || regForm.value.register_button_title
    );

    const isBonusHubTemplate = computed(
      () =>
        template.value.__component === 'landing-templates.bonus-hub' ||
        template.value.__component === 'landing-templates.bonus-hub-neon'
    );

    const equalPaymentMethodsLink = computed(() => {
      const biggest = [...storeFooter.paymentMethodsLink];

      while (biggest.length < storeFooter.partnersLink.length) {
        biggest.push(...storeFooter.paymentMethodsLink);
      }

      return biggest.slice(0, storeFooter.partnersLink.length);
    });

    const onChangeCurrency = (newCurrency: string) => {
      storeLanding.setCurrency(newCurrency);
    };

    const onStepChanged = (val: number) => {
      signupStep.value = val;
    };

    const showConfirmClose = () => {
      if (signupStep.value === 1) {
        isConfirmCloseShowing.value = true;
      } else {
        closeModal();
      }
    };

    const hideConfirmClose = () => {
      isConfirmCloseShowing.value = false;
    };

    const closeModalConfirmed = () => {
      hideConfirmClose();
      closeModal();
    };

    const loadLocalizedUserData = () => {
      if (storeCountry.userCountry) {
        if (Array.isArray(storeLanding.secondTitle)) {
          const titleForCountry = storeLanding.secondTitle.find(
            (c) => c.country === storeCountry.userCountry
          );
          if (titleForCountry) {
            storeLanding.setCurrency(titleForCountry.currency);
            return;
          }
        }

        const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
          currency.defaultForCountries.some((country) => country === storeCountry.userCountry)
        );

        if (storeLanding.currency) {
          storeLanding.setCurrency(storeLanding.currency);
        } else if (defaultCurrencyForCountry?.currencyCode) {
          storeLanding.setCurrency(defaultCurrencyForCountry.currencyCode);
        } else {
          storeLanding.setCurrency(storeCurrencyMenu.activeCurrencies[0]?.currencyCode);
        }
      }
    };

    const registerHandler = () => {
      if (isPlayerAuthorised.value) {
        window.location.href = `https://${storeSystemHosts.redirectHost}`;
      } else {
        push({
          event: `RegStart${abbr.value}Promo`,
          user_id: '',
          timestamp: Math.round(Date.now() / 1000),
        });
        openModal('lion');
        emit('setModalRedirectPath', '');
      }
    };

    const openTermsModal = () => {
      openModal('terms');
    };

    const copyToClipboard = async (text: string) => {
      try {
        await copyText(text);
        isCopied.value = true;
      } catch (e) {
        console.error(e);
      }
    };

    watch(() => storeCurrencyMenu.activeCurrencies, loadLocalizedUserData, { immediate: true });

    watch(
      () => isCopied.value,
      () => {
        if (isCopied.value) {
          window.setTimeout(() => {
            isCopied.value = false;
          }, 3000);
        }
      }
    );

    return {
      storePlatform,
      show,
      isCopied,
      template,
      registerTitle,
      isBonusHubTemplate,
      onChangeCurrency,
      registerHandler,
      openTermsModal,
      copyToClipboard,
      abbr,
      showConfirmClose,
      hideConfirmClose,
      isConfirmCloseShowing,
      closeModalConfirmed,
      onStepChanged,
      signupStep,
      closeModal,
      equalPaymentMethodsLink,
      storeFooter,
      storeLandingComponents,
      currentPage: computed(() => storeLanding.currentPage),
      secondTitle: computed(() => storeLanding.secondTitle),
      bannerUrl: computed(() => storeLanding.bannerUrl),
    };
  },
});
</script>

<style lang="scss" src="./bonusHubNeonMainPage.scss"></style>
