import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { RaceInterface, useStoreRaces } from '../strapi/races';
import { filterDateActiveItem } from '@/utils/filters';

export interface SsTournamentsGettersInterface {
  activeTournaments: ISsTournamentResponse[];
}

export interface ISsTournamentResponse {
  id?: number;
  title?: string;
  in_progress?: boolean;
  user_confirmation_required?: boolean;
  frontend_identifier?: string;
  strategy?: string;
  currencies?: string[];
  game_category_identity?: string;
  start_at?: Date;
  end_at?: Date;
  finished_at?: Date;
  currency?: string;
  money_budget?: string;
  money_budget_cents?: number;
  chargeable_comp_points_budget?: number;
  persistent_comp_points_budget?: number;
  prizes?: any[];
  top_players?: any[];
  chargeable_comp_points_prize_pool?: number;
  persistent_comp_points_prize_pool?: number;
  money_prize_pool?: string;
  money_prize_pool_cents?: number;
  games_taken_limit?: number;
  group_ids?: number[];
}

export interface ITournamentExtended {
  tournamentData: ISsTournamentResponse;
  raceData: RaceInterface;
}

export const useStoreSsTournaments = defineStore('ssTournaments', {
  state: () => ({
    tournaments: [] as ISsTournamentResponse[],
  }),
  getters: {
    activeTournaments(state): ISsTournamentResponse[] {
      return state.tournaments.filter((t) =>
        filterDateActiveItem({ startDate: t.start_at, finishDate: t.end_at })
      );
    },
    tournamentsExtended(): ITournamentExtended[] {
      const tournamentsExtended: ITournamentExtended[] = [];

      this.activeTournaments.forEach((t) => {
        const raceById = useStoreRaces().activeRaces.find(
          (r: RaceInterface) => r.ssId === t.frontend_identifier
        );

        if (raceById) {
          tournamentsExtended.push({
            tournamentData: t,
            raceData: raceById,
          });
        }
      });

      return tournamentsExtended.sort(
        (a, b) => Number(a.raceData.position) - Number(b.raceData.position)
      );
    },
  },
  actions: {
    async loadTournaments() {
      const tournaments = await useTransport().getSs('/api/tournaments');

      if (tournaments) {
        this.tournaments = tournaments;
      }
    },
  },
});
