import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-modal-name"]
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = {
  key: 0,
  class: "icon-modal-loader"
}

import usePromoModal from '@/composables/promoModalState';
import { useStoreLanding } from '@/store/strapi/landing';

const isLoading = false;


export default /*@__PURE__*/_defineComponent({
  __name: 'modalComponent',
  props: {
    modal: {},
    isWithCloseConfirm: { type: Boolean }
  },
  emits: ["confirmClose"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const storeLanding = useStoreLanding();
const { closeModal } = usePromoModal();

const close = () => {
  closeModal();
};

const closeModalOutside = (event) => {
  if (event.target.classList.contains('modal-wrapper') && !storeLanding.isMiraxFrenchLand) {
    if (props.isWithCloseConfirm) {
      emit('confirmClose');
    } else {
      close();
    }
  }
};

return (_ctx: any,_cache: any) => {
  return (_ctx.modal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-wrapper",
        "data-modal-name": _ctx.modal,
        "data-is-fiat": null,
        onClick: closeModalOutside
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default"),
          isLoading
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          (!_unref(storeLanding).isMiraxFrenchLand)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "modal-close-modal",
                onClick: _withModifiers(close, ["stop"])
              }))
            : _createCommentVNode("", true)
        ])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})