import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from 'assets/images/landing/7bit_logo_small.svg'


const _hoisted_1 = { class: "modalContentSignup" }
const _hoisted_2 = { class: "modalContentSignupHeader" }
const _hoisted_3 = { class: "signUpLogoBlock" }
const _hoisted_4 = ["href", "gtmid"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["href", "gtmid"]
const _hoisted_7 = { class: "modalContentSignupHolder" }
const _hoisted_8 = { class: "modalBanner" }
const _hoisted_9 = { class: "modalLoginLionText" }
const _hoisted_10 = { class: "modalLoginLionBannerHighlight" }
const _hoisted_11 = { class: "modalLoginLionBanner" }
const _hoisted_12 = { class: "modalLoginLionBannerSecond" }
const _hoisted_13 = ["src", "alt", "width", "height"]
const _hoisted_14 = { class: "modalInside" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["href"]
const _hoisted_18 = { class: "formGroup formPromoGroup formCheckbox" }
const _hoisted_19 = { class: "formPromoCheckbox" }
const _hoisted_20 = { class: "checkboxWithTooltip" }
const _hoisted_21 = { class: "infoTooltip" }
const _hoisted_22 = { class: "infoTooltipItem" }
const _hoisted_23 = { class: "formGroup formCheckbox formCheckboxLast" }
const _hoisted_24 = { class: "formGroup" }
const _hoisted_25 = { class: "" }
const _hoisted_26 = { class: "formGroupTwoBlock" }
const _hoisted_27 = { class: "modalThreeBlock" }
const _hoisted_28 = { class: "modalThreeBlockSelect" }
const _hoisted_29 = { class: "modalThreeBlockSelect" }
const _hoisted_30 = { class: "modalThreeBlockSelect" }
const _hoisted_31 = {
  key: 0,
  class: "errorText"
}
const _hoisted_32 = {
  key: 1,
  class: "errorText"
}
const _hoisted_33 = {
  key: 0,
  class: "errorText"
}
const _hoisted_34 = {
  key: 1,
  class: "errorText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BitCloseButton = _resolveComponent("BitCloseButton")!
  const _component_BitInput = _resolveComponent("BitInput")!
  const _component_BitPasswordInput = _resolveComponent("BitPasswordInput")!
  const _component_BitSelect = _resolveComponent("BitSelect")!
  const _component_BitCheckbox = _resolveComponent("BitCheckbox")!
  const _component_BitButton = _resolveComponent("BitButton")!
  const _component_TelephoneInput = _resolveComponent("TelephoneInput")!
  const _component_BitGenderSelection = _resolveComponent("BitGenderSelection")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.template.logoHref,
          class: "signUpLogo",
          gtmid: `7bitMain${_ctx.abbr}Promo`
        }, [
          _createElementVNode("img", {
            src: _ctx.template.logo.url,
            alt: "logoLion"
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4),
        _createElementVNode("a", {
          href: _ctx.template.logoHref,
          class: "signUpLogo signUpLogoMobile",
          gtmid: `7bitMain${_ctx.abbr}Promo`
        }, _cache[30] || (_cache[30] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "7bit_logo_small"
          }, null, -1)
        ]), 8, _hoisted_6),
        _createElementVNode("p", null, _toDisplayString(_ctx.regFormData.header), 1)
      ]),
      _createVNode(_component_BitCloseButton, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      })
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.titleHighlight), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.firstTitle), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.secondTitle), 1)
        ]),
        _createElementVNode("img", {
          ref: "banner",
          src: _ctx.url,
          class: "modalBannerSignUp",
          alt: _ctx.nameImg,
          width: _ctx.width,
          height: _ctx.height
        }, null, 8, _hoisted_13)
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", {
          class: _normalizeClass(["modalFirstStep", { 'modal-hidden-step': _ctx.step != 1 }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.regErrors.email }])
          }, [
            _createVNode(_component_BitInput, {
              modelValue: _ctx.regForm.email,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.regForm.email) = $event)),
                _cache[2] || (_cache[2] = ($event: any) => (_ctx.touchHeap.touch(_ctx.regFormValidator, 'email')))
              ],
              type: "email",
              "form-field-name": "email",
              placeholder: _ctx.regFormData.email_title,
              "text-label": _ctx.regFormData.email_title,
              "is-valid": 
                !_ctx.regErrors.email &&
                !_ctx.regFormValidator.email.$errors.length &&
                _ctx.regFormValidator.email.$anyDirty
              ,
              "is-error": !!_ctx.regFormValidator.email.$errors.length || !!_ctx.regErrors.email,
              "error-message": _ctx.regFormValidator.email.$errors[0]?.$message || _ctx.regErrors.email
            }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.regErrors.password }])
          }, [
            _createVNode(_component_BitPasswordInput, {
              modelValue: _ctx.regForm.password,
              "onUpdate:modelValue": [
                _cache[3] || (_cache[3] = ($event: any) => ((_ctx.regForm.password) = $event)),
                _cache[4] || (_cache[4] = ($event: any) => (_ctx.touchHeap.touch(_ctx.regFormValidator, 'password')))
              ],
              "form-field-name": "password",
              "space-bottom": "16",
              gtmid: "SignUp_fild_Pass",
              placeholder: _ctx.regFormData.password_title,
              "text-label": _ctx.regFormData.password_title,
              "is-valid": 
                _ctx.regErrors.password &&
                !_ctx.regFormValidator.password.$error &&
                !_ctx.regFormValidator.email.$anyDirty
              ,
              "is-error": !!_ctx.regFormValidator.password.$errors.length,
              "error-message": _ctx.regErrors.password || _ctx.regFormValidator.password.$errors[0]?.$message
            }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.regErrors.currency }])
          }, [
            _createVNode(_component_BitSelect, {
              modelValue: _ctx.regForm.currency,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.regForm.currency) = $event)),
              "text-label": _ctx.regFormData.currency_title,
              "is-error": !!_ctx.regErrors.currency,
              "error-message": _ctx.regErrors.currency,
              options: _ctx.activeCurrenciesOptions
            }, {
              appendInputIcon: _withCtx(() => [
                (_ctx.selectedCurrencyIconUrl)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.selectedCurrencyIconUrl
                    }, null, 8, _hoisted_15))
                  : _createCommentVNode("", true)
              ]),
              appendOptionIcon: _withCtx(({ value }) => [
                _createElementVNode("img", {
                  src: _ctx.activeCurrenciesOptionsIcons[value]
                }, null, 8, _hoisted_16)
              ]),
              _: 1
            }, 8, ["modelValue", "text-label", "is-error", "error-message", "options"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroup formCheckbox", {
              formGroupError:
                !!_ctx.regErrors.terms_acceptance || !!_ctx.regFormValidator.terms_acceptance.$errors[0],
            }])
          }, [
            _createVNode(_component_BitCheckbox, {
              modelValue: _ctx.regForm.terms_acceptance,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.regForm.terms_acceptance) = $event)),
              "is-error": 
                !!_ctx.regErrors.terms_acceptance || !!_ctx.regFormValidator.terms_acceptance.$errors[0]
              
            }, {
              label: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.regFormData.terms_title) + " ", 1),
                _createElementVNode("a", {
                  href: _ctx.termsLink,
                  class: "inputFormCheckboxLink"
                }, _toDisplayString(_ctx.regFormData.terms_link_title), 9, _hoisted_17)
              ]),
              _: 1
            }, 8, ["modelValue", "is-error"])
          ], 2),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createVNode(_component_BitCheckbox, {
                modelValue: _ctx.profileForm.receive_promos,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.profileForm.receive_promos) = $event))
              }, {
                label: _withCtx(() => [
                  _createElementVNode("span", _hoisted_20, [
                    _createTextVNode(_toDisplayString(_ctx.profileFormData.receive_promos_title) + " ", 1),
                    _createElementVNode("div", _hoisted_21, [
                      _cache[31] || (_cache[31] = _createElementVNode("svg", {
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }, [
                        _createElementVNode("path", {
                          d: "M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z",
                          fill: "white"
                        })
                      ], -1)),
                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.storeAuth.promoTooltipText), 1)
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_BitCheckbox, {
              modelValue: _ctx.profileForm.agreed_to_partner_promotions,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.profileForm.agreed_to_partner_promotions) = $event)),
              "label-text": _ctx.storeAuth.crossSaleTitle
            }, null, 8, ["modelValue", "label-text"])
          ]),
          _cache[32] || (_cache[32] = _createElementVNode("div", { id: "captcha_v2" }, null, -1)),
          _createElementVNode("div", _hoisted_24, [
            _createVNode(_component_BitButton, {
              class: _normalizeClass({ margined: _ctx.isGoogleRegTemplate }),
              name: "sign-up",
              "is-fluid-width": "",
              disabled: _ctx.waitPlease,
              loading: _ctx.waitPlease,
              onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.nextStep()), ["prevent"]))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.regFormData.register_button_title), 1)
              ]),
              _: 1
            }, 8, ["class", "disabled", "loading"])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["modalTwoStep", { 'modal-hidden-step': _ctx.step != 2 }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.country }])
          }, [
            _createVNode(_component_BitSelect, {
              modelValue: _ctx.profileForm.country,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.profileForm.country) = $event)),
              options: _ctx.countriesList,
              "text-label": _ctx.profileFormData.country_title,
              "is-error": !!_ctx.profileFormFirstValidator.country.$error,
              "error-message": _ctx.profileFormFirstValidator.country.$errors[0]?.$message
            }, null, 8, ["modelValue", "options", "text-label", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", _hoisted_25, [
            (_ctx.isStateFieldShown)
              ? (_openBlock(), _createBlock(_component_BitSelect, {
                  key: 0,
                  modelValue: _ctx.profileForm.state,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.profileForm.state) = $event)),
                  options: _ctx.statesList,
                  "text-label": 'State',
                  "is-error": !!_ctx.profileFormFirstValidator.state.$error,
                  "error-message": 'Can`t be blank'
                }, null, 8, ["modelValue", "options", "is-error"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", {
              class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.city }])
            }, [
              _createVNode(_component_BitInput, {
                modelValue: _ctx.profileForm.city,
                "onUpdate:modelValue": [
                  _cache[12] || (_cache[12] = ($event: any) => ((_ctx.profileForm.city) = $event)),
                  _cache[13] || (_cache[13] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'city')))
                ],
                type: "text",
                "form-field-name": "city",
                "space-bottom": "16",
                placeholder: _ctx.profileFormData.city_title,
                "text-label": _ctx.profileFormData.city_title,
                "is-valid": 
                  !_ctx.profileFormFirstValidator.city.$errors.length &&
                  _ctx.profileFormFirstValidator.city.$anyDirty
                ,
                "is-error": !!_ctx.profileFormFirstValidator.city.$errors.length,
                "error-message": _ctx.profileFormFirstValidator.city.$errors[0]?.$message
              }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.postal_code }])
            }, [
              _createVNode(_component_BitInput, {
                modelValue: _ctx.profileForm.postal_code,
                "onUpdate:modelValue": [
                  _cache[14] || (_cache[14] = ($event: any) => ((_ctx.profileForm.postal_code) = $event)),
                  _cache[15] || (_cache[15] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'postal_code')))
                ],
                type: "text",
                "form-field-name": "code",
                "space-bottom": "16",
                placeholder: _ctx.profileFormData.postal_code_title,
                "text-label": _ctx.profileFormData.postal_code_title,
                "is-valid": 
                  !_ctx.profileFormFirstValidator.postal_code.$errors.length &&
                  _ctx.profileFormFirstValidator.postal_code.$anyDirty &&
                  !_ctx.profileErrors.postal_code
                ,
                "is-error": 
                  !!_ctx.profileFormFirstValidator.postal_code.$errors.length ||
                  !!_ctx.profileErrors.postal_code ||
                  _ctx.isErrorPostalCodeIncorrect
                ,
                "error-message": 
                  _ctx.profileFormFirstValidator.postal_code.$errors[0]?.$message ||
                  _ctx.isErrorPostalCodeIncorrect
                    ? 'postal code correct format is xxxx'
                    : ''
                
              }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.address }])
          }, [
            _createVNode(_component_BitInput, {
              modelValue: _ctx.profileForm.address,
              "onUpdate:modelValue": [
                _cache[16] || (_cache[16] = ($event: any) => ((_ctx.profileForm.address) = $event)),
                _cache[17] || (_cache[17] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'address')))
              ],
              type: "text",
              "form-field-name": "address",
              "space-bottom": "16",
              placeholder: _ctx.profileFormData.address_title,
              "text-label": _ctx.profileFormData.address_title,
              "is-valid": 
                !_ctx.profileFormFirstValidator.address.$errors.length &&
                _ctx.profileFormFirstValidator.address.$anyDirty
              ,
              "is-error": !!_ctx.profileFormFirstValidator.address.$errors.length,
              "error-message": _ctx.profileFormFirstValidator.address.$errors[0]?.$message,
              gtmid: "SignUp_Adress"
            }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.first_name }])
          }, [
            _createVNode(_component_BitInput, {
              modelValue: _ctx.profileForm.first_name,
              "onUpdate:modelValue": [
                _cache[18] || (_cache[18] = ($event: any) => ((_ctx.profileForm.first_name) = $event)),
                _cache[19] || (_cache[19] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'first_name')))
              ],
              type: "text",
              "form-field-name": "first-name",
              placeholder: _ctx.profileFormData.first_name_title,
              "text-label": _ctx.profileFormData.first_name_title,
              "space-bottom": "16",
              "is-valid": 
                !_ctx.profileFormFirstValidator.first_name.$errors.length &&
                _ctx.profileFormFirstValidator.first_name.$anyDirty
              ,
              "is-error": !!_ctx.profileFormFirstValidator.first_name.$errors.length,
              "error-message": 
                _ctx.profileErrors.first_name ||
                _ctx.profileFormFirstValidator.first_name.$errors[0]?.$message
              
            }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["", { formGroupError: !!_ctx.profileErrors.last_name }])
          }, [
            _createVNode(_component_BitInput, {
              modelValue: _ctx.profileForm.last_name,
              "onUpdate:modelValue": [
                _cache[20] || (_cache[20] = ($event: any) => ((_ctx.profileForm.last_name) = $event)),
                _cache[21] || (_cache[21] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'last_name')))
              ],
              type: "text",
              "form-field-name": "last-name",
              "space-bottom": "16",
              placeholder: _ctx.profileFormData.last_name_title,
              "text-label": _ctx.profileFormData.last_name_title,
              "is-valid": 
                !_ctx.profileFormFirstValidator.last_name.$errors.length &&
                _ctx.profileFormFirstValidator.last_name.$anyDirty
              ,
              "is-error": !!_ctx.profileFormFirstValidator.last_name.$errors.length,
              "error-message": 
                _ctx.profileErrors.last_name || _ctx.profileFormFirstValidator.last_name.$errors[0]?.$message
              
            }, null, 8, ["modelValue", "placeholder", "text-label", "is-valid", "is-error", "error-message"])
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.date_of_birth }])
          }, [
            _createElementVNode("span", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_BitSelect, {
                  modelValue: _ctx.profileForm.year,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.profileForm.year) = $event)),
                  "text-label": _ctx.profileFormData.year_title,
                  "is-error": _ctx.profileFormFirstValidator.year.$error,
                  options: _ctx.listYears,
                  placeholder: _ctx.profileFormData.year_title,
                  isNoMargin: ""
                }, null, 8, ["modelValue", "text-label", "is-error", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createVNode(_component_BitSelect, {
                  modelValue: _ctx.profileForm.month,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.profileForm.month) = $event)),
                  "text-label": _ctx.profileFormData.month_title,
                  "is-error": _ctx.profileFormFirstValidator.month.$error,
                  options: _ctx.listMonths,
                  placeholder: _ctx.profileFormData.month_title,
                  isNoMargin: ""
                }, null, 8, ["modelValue", "text-label", "is-error", "options", "placeholder"])
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_BitSelect, {
                  modelValue: _ctx.profileForm.day,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.profileForm.day) = $event)),
                  "text-label": _ctx.profileFormData.day_title,
                  "is-error": _ctx.profileFormFirstValidator.day.$error,
                  options: _ctx.listDays,
                  placeholder: _ctx.profileFormData.day_title,
                  isNoMargin: ""
                }, null, 8, ["modelValue", "text-label", "is-error", "options", "placeholder"])
              ])
            ]),
            (_ctx.profileErrors.date_of_birth)
              ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.profileErrors.date_of_birth), 1))
              : _createCommentVNode("", true),
            (
                (_ctx.profileFormFirstValidator.day.$errors[0] ||
                  _ctx.profileFormFirstValidator.month.$errors[0] ||
                  _ctx.profileFormFirstValidator.year.$errors[0]) &&
                !_ctx.profileErrors.date_of_birth
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString((_ctx.profileFormFirstValidator.day.$errors[0] &&
                  _ctx.profileFormFirstValidator.day.$errors[0].$message) ||
                (_ctx.profileFormFirstValidator.month.$errors[0] &&
                  _ctx.profileFormFirstValidator.month.$errors[0].$message) ||
                (_ctx.profileFormFirstValidator.year.$errors[0] &&
                  _ctx.profileFormFirstValidator.year.$errors[0].$message)), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroup modal-phone", { formGroupError: !!_ctx.profileErrors.mobile_phone }])
          }, [
            _createVNode(_component_TelephoneInput, {
              modelValue: _ctx.profileForm.mobile_phone,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.profileForm.mobile_phone) = $event)),
              "profile-form-data": _ctx.profileFormData,
              "player-country": _ctx.userCountry,
              "is-error": 
                !!_ctx.profileFormFirstValidator.mobile_phone.$errors[0] || !!_ctx.profileErrors.mobile_phone
              ,
              "is-success": 
                !_ctx.profileErrors.mobile_phone &&
                !_ctx.profileFormFirstValidator.mobile_phone.$error &&
                _ctx.profileFormFirstValidator.mobile_phone.$anyDirty
              ,
              onBlur: _cache[26] || (_cache[26] = ($event: any) => (_ctx.profileFormFirstValidator.mobile_phone.$touch())),
              onInput: _cache[27] || (_cache[27] = ($event: any) => (_ctx.clearProfileErrorField('mobile_phone')))
            }, null, 8, ["modelValue", "profile-form-data", "player-country", "is-error", "is-success"]),
            (_ctx.profileErrors.mobile_phone)
              ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.profileErrors.mobile_phone), 1))
              : _createCommentVNode("", true),
            (
                _ctx.profileFormFirstValidator.mobile_phone.$errors[0] && !_ctx.profileErrors.mobile_phone
              )
              ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.profileFormFirstValidator.mobile_phone.$errors[0].$message), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroup", {
              formGroupError: !!_ctx.profileFormFirstValidator.gender.$errors[0],
            }])
          }, [
            _createVNode(_component_BitGenderSelection, {
              modelValue: _ctx.profileForm.gender,
              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.profileForm.gender) = $event)),
              "male-title": _ctx.regFormData.label_text_male || 'Male',
              "female-title": _ctx.regFormData.label_text_female || 'Female',
              "is-error": !!_ctx.profileFormFirstValidator.gender.$errors[0]
            }, null, 8, ["modelValue", "male-title", "female-title", "is-error"])
          ], 2),
          _createVNode(_component_BitButton, {
            title: _ctx.profileFormData.next_button_title,
            isFluidWidth: "",
            loading: _ctx.waitPlease,
            onClick: _cache[29] || (_cache[29] = _withModifiers(($event: any) => (_ctx.nextStep()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.profileFormData.next_button_title), 1)
            ]),
            _: 1
          }, 8, ["title", "loading"])
        ], 2)
      ])
    ])
  ]))
}