import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bitLinkButtonIconLeft"
}
const _hoisted_2 = { class: "bitLinkButtonTextInside" }
const _hoisted_3 = {
  key: 1,
  class: "bitLinkButtonIconRight"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag === 'link' ? 'NuxtLink' : _ctx.tag), { class: "bitLinkButton" }, {
    default: _withCtx(() => [
      (_ctx.$slots.appendIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "appendIcon")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.$slots.prependIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "prependIcon")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}