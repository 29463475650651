<template>
  <div class="promopage">
    <component :is="promoComponent" />
  </div>
</template>

<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { load as loadRecaptcha } from 'recaptcha-v3';
import { useHead } from 'unhead';
import {
  computed,
  // defineAsyncComponent,
  onBeforeMount,
  onMounted,
  provide,
  reactive,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { RECAPTCCHA_SITEKEYS } from '../config/constants';
import promoComponents from '@/components/promoPage/index';
import { useStoreLanguage } from '@/store/abstract/language';
import { useStoreStrapi } from '@/store/abstract/strapi';
import { useStoreCountry } from '@/store/client/country';
import { useStoreStag } from '@/store/client/stag';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreSsUpdateLocale } from '@/store/softswiss/ssUpdateLocale';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreProfileForm } from '@/store/strapi/profileForm';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { StrapiTemplatesEnum } from '@/types/strapiTemplateEnum';

const route = useRoute();
const country = useStoreCountry();
const storeLanguage = useStoreLanguage();
const stagStore = useStoreStag();
const storeSsPlayer = useStoreSsPlayer();
const ssUpdateLocaleStore = useStoreSsUpdateLocale();
const profileFormStore = useStoreProfileForm();
const storeRegForm = useStoreRegForm();
const storeLanding = useStoreLanding();
const storeStrapi = useStoreStrapi();
const storeSystemHosts = useStoreSystemHosts();

let intervalId: number;
const titleData: {
  titles: string[];
  index: number;
} = reactive({
  titles: [],
  index: 0,
});

const path = computed(() => String(route.params.path).toLowerCase());

const promoComponent = computed(() => {
  if (storeLanding.currentPage?.template?.__component) {
    const template = StrapiTemplatesEnum[storeLanding.currentPage.template.__component];

    if (template) {
      // return defineAsyncComponent(() => import(`@/components/promoPage/${template}.vue`));
      return promoComponents[template];
    }
  }

  return null;
});

watch(
  () => titleData.index,
  (i) => (document.title = titleData.titles[i])
);

const startTitleBleems = (): void => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }

  const title = storeLanding.currentPage?.head?.title || '';

  if (title.split('|').length > 1) {
    titleData.titles = title.split('|') || [''];
    titleData.index = 0;
  } else {
    document.title = title;
  }

  intervalId = window.setInterval(() => {
    if (titleData.index >= titleData.titles.length - 1) {
      titleData.index = 0;
    } else {
      titleData.index++;
    }
  }, 1000);
};

const changeFavicon = (url: string): void => {
  let link = document.head.querySelector("link[rel~='icon']") as HTMLLinkElement;

  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  link.href = url;
};

const setFavicon = (): void => {
  if (storeLanding.isLoaded && storeLanding.currentPage?.template?.favicon) {
    changeFavicon(storeLanding.currentPage.template.favicon.data.attributes.url);
  } else {
    changeFavicon('/favicon-7bit.ico');
  }
};

const setDescription = (): void => {
  document.getElementsByTagName('meta')['description'].content =
    storeLanding.currentPage?.head.description || '';
};

const recaptchaKey = Object.keys(RECAPTCCHA_SITEKEYS).find((key) =>
  location.host.includes(key)
) as string;
provide('recaptchaKey', recaptchaKey);

const recaptcha = loadRecaptcha(RECAPTCCHA_SITEKEYS[recaptchaKey], {
  renderParameters: { hl: 'en' },
});
provide('recaptcha', recaptcha);

onBeforeMount(async () => {
  await country.check();
  storeLanguage.setLocaleByCountry();
  ssUpdateLocaleStore.patch();
  storeRegForm.load();
  profileFormStore.load();
  storeStrapi.loadCommonData();
  await Promise.all([
    storeStrapi.loadLandingsData(path.value),
    storeStrapi.loadSystemHostsData(),
    storeSsPlayer.loadPlayerInfo(),
  ]);

  if (storeLanding.pathes.length === 0 || storeSsPlayer.isPlayerAuthorised) {
    window.location.href = `https://${storeSystemHosts.redirectHost}${window.location.search}`;
    return;
  }

  if (!storeLanding.currentPage?.template.__component) {
    Bugsnag.notify(`No such promo page [ ${path.value} ]!`);
  }

  startTitleBleems();
  setFavicon();
  setDescription();
});

watch(
  () => [storeLanding.bannerMobile, storeLanding.bannerDesktop],
  (value) => {
    value.forEach((banner) => {
      if (banner) {
        useHead({
          link: [
            {
              rel: 'preload',
              as: 'image',
              href: banner.url,
            },
          ],
        });
      }
    });
  },
  { immediate: true }
);

onMounted(() => {
  stagStore.checkStag();
  stagStore.saveCurrentStag();
});
</script>

<style lang="scss">
#promo {
  margin: 0;
}

.grecaptcha-badge,
.grecaptcha-logo {
  opacity: 0 !important;
}

#onesignal-slidedown-container {
  display: none;
}
</style>
