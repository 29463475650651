import * as CryptoJS from 'crypto-js';
import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { ESeonStatus, ISeonResponse } from '@/types/seon';
import { SsPlayerProfileUpdateInterface } from '@/types/softswiss/ssPlayerProfileUpdate.interface';
import { SsUsersRegisterInterface } from '@/types/softswiss/ssUsers.request.interface';
import { SsUsersResponseInterface } from '@/types/softswiss/ssUsers.response.interface';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import {
  ISsserGroupsUpdateRequestInterface,
  SsPlayerRegistrationResultInterface,
  SsPlayerResponseInterface,
} from '~/types/softswiss/ssPlayer.response.interface';

export interface DebounceResponse {
  debounce: {
    send_transactional: string;
  };
}

export const useStoreSsPlayer = defineStore('ssPlayer', {
  state: () => ({
    playerInfo: {} as SsPlayerResponseInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    isPlayerAuthorised(state): boolean {
      return !!state.playerInfo.id;
    },
  },
  actions: {
    async loadPlayerInfo() {
      this.status = LoadingStatusEnum.loading;

      try {
        const playerInfo = await useTransport().getSs('/api/player');

        this.playerInfo = { ...this.playerInfo, ...playerInfo };
        this.status = LoadingStatusEnum.loaded;
      } catch (e) {
        this.status = LoadingStatusEnum.error;
      }
    },
    async updatePlayerGroups(body: ISsserGroupsUpdateRequestInterface): Promise<void> {
      try {
        const groups: ISsserGroupsUpdateRequestInterface = {};

        if (body.add?.length) {
          groups.add = body.add;
        }

        if (body.remove?.length) {
          groups.remove = body.remove;
        }

        await useTransport().postSs('/api/player/groups', { groups });
        await this.loadPlayerInfo();
      } catch (e: any) {
        console.error(new Error('update of groups failed!'));
      }
    },
    async checkIfFraudEmailWithSeon(email: string) {
      try {
        // @ts-ignore
        const session: string = await window.seon.getBase64Session();
        const response: ISeonResponse = await useTransport().post('/seon', {
          email,
          session,
        });

        return response.data.state === ESeonStatus.decline;
      } catch (e) {
        console.info(e);

        return false;
      }
    },
    async checkEmail(email: string) {
      try {
        const debounceKey = 'public_ay9qVWVuS3dXeW9mRkZmTzExbTZpUT09';
        const debounceApiUrl = `https://api.debounce.io/v1/?api=`;
        const data = (await useTransport().get(
          `${debounceApiUrl}${debounceKey}&email=${email}`
        )) as DebounceResponse;

        return !!parseInt(data.debounce?.send_transactional);
      } catch (e) {
        // @ts-ignore
        Bugsnag.notify(e);
        return true;
      }
    },
    async registerPlayer(
      playerData: SsUsersRegisterInterface,
      headers: any
    ): Promise<SsPlayerRegistrationResultInterface> {
      try {
        const playerInfo = (await useTransport().postSs(
          '/api/users',
          { user: playerData },
          headers
        )) as SsUsersResponseInterface;

        if (playerInfo.id) {
          window.localStorage.setItem('playerData', JSON.stringify(playerInfo));
          return { playerInfo };
        }

        return {
          playerInfo,
          errors: { result: 'login failed' },
        };
      } catch (e) {
        // @ts-ignore
        const errors = e?.response?.data?.errors;
        if (errors) {
          return {
            errors,
            playerInfo: {} as SsUsersResponseInterface,
          };
        } else {
          return {
            playerInfo: {} as SsUsersResponseInterface,
            errors: { result: 'unknown error' },
          };
        }
      }
    },
    generateAuthToken(data: {
      login: string;
      password: string;
      stag: string;
      modal: string;
    }): string {
      const key = String(Date.now()).substring(0, 8);

      // Encrypt
      const encryptedData = CryptoJS.AES.encrypt(
        [data.login, data.password, data.stag, data.modal].join('\n'),
        key
      ).toString();

      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedData));
    },
    async updateProfile(
      playerData: SsPlayerProfileUpdateInterface
    ): Promise<SsPlayerRegistrationResultInterface | any> {
      try {
        const playerInfo = (await useTransport().patchSs('/api/player', {
          context: 'edition',
          player: playerData,
        })) as SsUsersResponseInterface;

        return { playerInfo };
      } catch (e) {
        // @ts-ignore
        const errors = e?.response?.data?.errors;
        if (errors) {
          return { errors };
        } else {
          return {
            playerInfo: {} as SsUsersResponseInterface,
            errors: { result: 'unknown error' },
          };
        }
      }
    },
    async validateUserEmail(
      playerData: SsUsersRegisterInterface
    ): Promise<SsPlayerRegistrationResultInterface | true> {
      try {
        await useTransport().postSs('/api/users/validate', {
          validate_attributes: ['email'],
          user: playerData,
        });

        return true;
      } catch (e) {
        // @ts-ignore
        const errors = e?.response?.data?.errors;
        if (errors) {
          return {
            errors,
            playerInfo: {} as SsUsersResponseInterface,
          };
        } else {
          return {
            playerInfo: {} as SsUsersResponseInterface,
            errors: { result: 'unknown error' },
          };
        }
      }
    },
  },
});
