import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { SsCountriesResponseInterface } from '~/types/softswiss/ssCountriesResponse.interface';

export const useStoreSsCountries = defineStore('ssCountries', {
  state: () => ({
    countries: [] as SsCountriesResponseInterface[],
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    countriesGetter(state): SsCountriesResponseInterface[] {
      return state.countries;
    },
    isCountriesLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    async load(): Promise<SsCountriesResponseInterface[] | null> {
      try {
        if (this.isCountriesLoaded) {
          return this.countriesGetter;
        }

        this.status = LoadingStatusEnum.loading;

        const countries = await useTransport().getSs('/api/info/countries');

        if (countries) {
          this.countries = countries;
        }

        this.status = LoadingStatusEnum.loaded;

        return this.countriesGetter;
      } catch (e) {
        this.status = LoadingStatusEnum.error;

        return null;
      }
    },
  },
});
