<template>
  <component :is="tag === 'link' ? 'NuxtLink' : tag" class="bitLinkButton">
    <span v-if="$slots.appendIcon" class="bitLinkButtonIconLeft">
      <slot name="appendIcon" />
    </span>
    <span class="bitLinkButtonTextInside"><slot /></span>
    <span v-if="$slots.prependIcon" class="bitLinkButtonIconRight">
      <slot name="prependIcon" />
    </span>
  </component>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    tag: {
      type: String as PropType<'button' | 'link' | 'a'>,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {};
  },
});
</script>

<style lang="scss" src="./linkButton.scss"></style>
