<template>
  <div
    v-if="modal"
    class="modal-wrapper"
    :data-modal-name="modal"
    :data-is-fiat="null"
    @click="closeModalOutside"
  >
    <div class="modal-content">
      <slot />
      <div v-if="isLoading" class="icon-modal-loader" />
      <div v-if="!storeLanding.isMiraxFrenchLand" class="modal-close-modal" @click.stop="close" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import usePromoModal from '@/composables/promoModalState';
import { useStoreLanding } from '@/store/strapi/landing';

const props = defineProps<{
  modal: string;
  isWithCloseConfirm?: boolean;
}>();

const emit = defineEmits<{ (event: 'confirmClose'): void }>();

const storeLanding = useStoreLanding();
const { closeModal } = usePromoModal();

const isLoading = false;

const close = () => {
  closeModal();
};

const closeModalOutside = (event) => {
  if (event.target.classList.contains('modal-wrapper') && !storeLanding.isMiraxFrenchLand) {
    if (props.isWithCloseConfirm) {
      emit('confirmClose');
    } else {
      close();
    }
  }
};
</script>

<style lang="scss" src="./modalComponent.scss"></style>
