<template>
  <div>
    <vue-tel-input
      v-if="playerCountry"
      v-bind="optionsPhoneInput"
      :modelValue="fullPhoneNumber"
      :class="{ hasError: isError || !isValid, isSuccess: isSuccess && isValid }"
      @on-input="onChange"
      @blur="$emit('blur')"
      @input="$emit('input')"
    />
    <p v-if="!isError && !isValid" class="errorText phoneErrorText">Invalid</p>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref, computed } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import { PhoneVueTelInputInterface } from '@/types/country.vuetelinput.interface';
import 'vue-tel-input/dist/vue-tel-input.css';

const props = defineProps<{
  modelValue: string;
  readonly?: boolean;
  profileFormData: any;
  playerCountry: string;
  isError: boolean;
  isSuccess: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'blur'): void;
  (e: 'input'): void;
}>();

const input = (value) => {
  emit('update:modelValue', value);
};
const fullPhoneNumber = ref('');
const isValid = ref(true);

const phoneObject = ref({} as { number?: string });

/**
 * Props options for plugin `vue-tel-input`:
 * https://iamstevendao.github.io/vue-tel-input/documentation/props.html
 */
const optionsPhoneInput = computed(() => {
  return {
    mode: 'international',
    inputOptions: {
      id: 'phone',
      name: 'mobile-phone',
      class: 'modal-input',
      placeholder: props.profileFormData.mobile_phone_title,
      readonly: props.readonly,
    },
    dropdownOptions: {
      validCharactersOnly: true,
      showDialCodeInList: false,
      showDialCodeInSelection: false,
      showFlags: true,
    },
    defaultCountry: props.playerCountry.toLowerCase(),
    validCharactersOnly: false,
    preferredCountries: ['au', 'ca', 'nz', 'at', 'ch'],
    // TODO: bug - when you tiping number of ignored country, component will down, PR sended
    // ignoredCountries: ['sk', 'il', 'lt', 'es', 'ua', 'uk', 'fr', 'nl', 'us'],
    autoDefaultCountry: false,
  };
});

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue && props.modelValue !== fullPhoneNumber.value) {
      fullPhoneNumber.value = props.modelValue;
    }
  },
  { immediate: true }
);

watch(fullPhoneNumber, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    input(newValue);
  }
});
watch(
  phoneObject,
  (newPhone, oldPhone) => {
    if (newPhone.country && newPhone.country.dialCode !== oldPhone.country?.dialCode) {
      fullPhoneNumber.value = `+${newPhone.country.dialCode}`;
    }
  },
  { deep: true }
);
/**
 * Method that formatted phone number on every change
 */
const onChange = (number: string, phone: PhoneVueTelInputInterface) => {
  if (phone.valid !== undefined) {
    isValid.value = phone.valid;
  } else if (number.length > (phone.country?.dialCode.length || -1) + 1) {
    isValid.value = false;
  }

  phoneObject.value = phone;
  if (!props.modelValue && !phone.nationalNumber) {
    if (phone.country?.iso2 === 'CH') {
      fullPhoneNumber.value = `+${phone.country.dialCode} 860`;
    } else {
      fullPhoneNumber.value = `+${phone.country.dialCode}`;
    }
  }
  // SoftSwiss CRUNCH!
  // if country === SWITZERLAND without voice email we must add 860 prefix before nationl number
  if (
    phone.countryCode === 'CH' &&
    !phone.nationalNumber.startsWith('860') &&
    phone.nationalNumber.length === 9 &&
    phone.valid
  ) {
    return input(`+${phone.countryCallingCode} 860 ${phone.nationalNumber}`);
  }
  input(number);
};
</script>

<style lang="scss">
.phoneErrorText {
  margin: 0;
}
</style>
