<template>
  <BitInput
    class="bitPasswordInput"
    :class="!isWithoutMessages ? `bitPasswordInput-${passwordSecurityType}` : ''"
    v-bind="{ ...props }"
    :type="passwordDisplayType"
    is-with-append-main-icon
    input-wrapper-class="bitPasswordInputWrapper"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <template #appendInputIcon>
      <img
        v-if="isPasswordShown"
        src="~assets/images/common/formPasswordIcon.svg"
        @click="togglePasswordDisplay"
      />
      <img
        v-else
        src="~assets/images/common/formPasswordIcon2.svg"
        @click="togglePasswordDisplay"
      />
    </template>
    <template v-if="Boolean(passwordSecurityType) && !isWithoutMessages" #inputMessage>
      <span class="bitPasswordInputMessage">
        {{ translatedPasswordSecurityType }}
      </span>
    </template>
  </BitInput>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref } from 'vue';
import { basicInputProps } from '../constants';
import BitInput from '../input/input.vue';
import { passSecurityFn } from '@/utils/validators';

export default defineComponent({
  components: {
    BitInput,
  },
  props: {
    ...basicInputProps,
    isWithoutMessages: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isPasswordShown = ref<boolean>(false);
    const focusTrigger = ref(false);

    const passwordDisplayType = computed(() => {
      return isPasswordShown.value ? 'text' : 'password';
    });

    const passwordSecurityType = computed(() => passSecurityFn(String(props.modelValue)));

    const translatedPasswordSecurityType = computed(() => {
      switch (passwordSecurityType.value) {
        case 'STRONG':
          return 'Your password is Strong';
        case 'MEDIUM':
          return 'Your password is Medium';
        case 'WEAK':
          return 'Your password is Weak';
        default:
          return '';
      }
    });

    const togglePasswordDisplay = () => {
      isPasswordShown.value = !isPasswordShown.value;
      nextTick(() => (focusTrigger.value = !focusTrigger.value));
    };

    return {
      isPasswordShown,
      translatedPasswordSecurityType,
      passwordDisplayType,
      props,
      focusTrigger,
      togglePasswordDisplay,
      passwordSecurityType,
    };
  },
});
</script>

<style lang="scss">
.bitPasswordInput-STRONG {
  .bitPasswordInputMessage {
    color: $color-text-color-statuses-success-100;
  }
  .bitPasswordInputWrapper {
    border-color: $color-text-color-statuses-success-100 !important;
  }
}
.bitPasswordInput-MEDIUM {
  .bitPasswordInputMessage {
    color: $color-secondary-s-40-hover;
  }
  .bitPasswordInputWrapper {
    border-color: $color-secondary-s-40-hover !important;
  }
}
.bitPasswordInput-WEAK {
  .bitPasswordInputMessage {
    color: $color-text-color-statuses-pending-100;
  }
  .bitPasswordInputWrapper {
    border-color: $color-text-color-statuses-pending-100 !important;
  }
}
</style>
