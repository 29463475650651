import { defineStore } from 'pinia';

import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { filterDateActiveItem } from '@/utils/filters';

export enum Enum_Componentbonusesbonustype_Bonustype {
  Cashback = 'Cashback',
  Event = 'Event',
  Exclusive = 'Exclusive',
  Reload = 'Reload',
  Telegram = 'Telegram',
  Vip = 'VIP',
  Welcome = 'Welcome',
}

export interface BonusInterface {
  id: number;
  bonusId: string;
  title: string;
  position: number;
  locale: string;
  offer: string;
  bonusType: Enum_Componentbonusesbonustype_Bonustype;
  bannerCardFrontDesktop: StrapiImageInterface;
  bannerCardFrontMobile: StrapiImageInterface;
  groupFilter?: {
    includedGroups?: string | null;
  };
  finishDate?: Date | null;
  bannerCardBack: StrapiImageInterface;
  bannerCardFront: StrapiImageInterface;
  labelColor?: string;
  labelText: string;
}

export const useBonusesStore = defineStore('bonuses', {
  state: () => ({
    bonuses: [] as BonusInterface[],
  }),
  getters: {
    welcomePackBonuses(state): BonusInterface[] {
      return state.bonuses.filter(
        (bonus) => bonus.bonusType === Enum_Componentbonusesbonustype_Bonustype.Welcome
      );
    },
    promotionBonuses(state): BonusInterface[] {
      return state.bonuses
        .filter((bonus) => bonus.bonusType !== Enum_Componentbonusesbonustype_Bonustype.Welcome)
        .filter(filterDateActiveItem)
        .filter((bonus) => !bonus.groupFilter?.includedGroups);
    },
  },
  actions: {
    SAVE_DATA_FROM_STRAPI({ data }) {
      this.bonuses = data
        .map((bonus) => ({
          id: Number(bonus.id),
          bonusId: bonus.bonusId,
          title: bonus.title,
          position: +bonus.position,
          locale: bonus.locale,
          offer: bonus.offer,
          bonusType: bonus.bonusType.bonusType,
          bannerCardFrontDesktop: bonus.bannerCardFrontDesktop,
          bannerCardFrontMobile: bonus.bannerCardFrontMobile,
          groupFilter: bonus.groupFilter,
          finishDate: bonus.finishDate,
          bannerCardBack: bonus.bannerCardBack,
          bannerCardFront: bonus.bannerCardFront,
          labelText: bonus.customLabelText || bonus.bonusType.bonusType,
        }))
        .sort((a, b) => a.position - b.position);
    },
  },
});
