import { ref } from 'vue';

const RECAPTCHA_V2_KEY = '6LcpwjYmAAAAAJyUCCrgkVLD2zQ5TmHaQd-OelzU';

export const useRecaptcha = () => {
  let widgetCaptcha = false;
  const recaptchaTokenV2 = ref('');

  const getTokenV2 = (): Promise<string> => {
    return new Promise((resolve) => {
      if (widgetCaptcha === false) {
        // @ts-ignore
        widgetCaptcha = grecaptcha.render('captcha_v2', {
          sitekey: RECAPTCHA_V2_KEY,
          // @ts-ignore
          theme: 'dark',
          callback: (token: string) => {
            recaptchaTokenV2.value = token;
            resolve(token);
          },
          hl: 'en',
          lang: 'en',
        } as any);
      } else {
        resolve('');
      }
    });
  };

  const resetCaptchaV2 = () => {
    if (widgetCaptcha !== false) {
      // @ts-ignore
      grecaptcha.reset(widgetCaptcha);
    }
  };

  const resetTokenV2 = () => {
    recaptchaTokenV2.value = '';
  };

  return {
    getTokenV2,
    resetCaptchaV2,
    resetTokenV2,
    recaptchaTokenV2,
    RECAPTCHA_V2_KEY,
  };
};
