<template>
  <div
    class="bitRadioButton"
    :class="[
      variant,
      {
        bitRadioButtonDisabled: disabled,
        bitRadioButtonError: isError,
        bitRadioButtonChecked: modelValue === radioValue,
      },
    ]"
  >
    <label class="bitRadioButtonLabel">
      <input
        type="radio"
        :value="radioValue"
        :checked="modelValue === radioValue"
        :name="formFieldName"
        :gtmid="gtmid"
        class="bitRadioButtonInput"
        :disabled="disabled"
        @change="onChange"
      />
      <span class="bitRadioButtonCheck" />
      {{ label }}
      <span class="bitRadioButtonLabelIcon">
        <slot name="prependIcon" />
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    formFieldName: {
      type: String,
      required: true,
    },
    variant: {
      type: String as PropType<'group'>,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    radioValue: {
      type: String,
      required: true,
    },
    gtmid: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(_props, { emit }) {
    const onChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('update:modelValue', target.value);
    };

    return {
      onChange,
    };
  },
});
</script>

<style lang="scss" src="./radioButton.scss"></style>
