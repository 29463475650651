import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from 'assets/images/common/done.svg'


const _hoisted_1 = { class: "bitSelectItemOption" }
const _hoisted_2 = {
  key: 0,
  class: "bitSelectItemIconAppend"
}
const _hoisted_3 = { class: "bitSelectItemText" }
const _hoisted_4 = {
  key: 1,
  class: "bitSelectItemAdditionalText"
}
const _hoisted_5 = { class: "bitSelectItemSelectedIcon" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  alt: "done"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(["bitSelectItem", { bitSelectItemSelected: _ctx.isSelected, bitSelectItemLong: _ctx.additionalText }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        (_ctx.$slots.appendOptionIcon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _renderSlot(_ctx.$slots, "appendOptionIcon")
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.optionText), 1),
        (_ctx.additionalText)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.additionalText), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", _hoisted_5, [
        (_ctx.isSelected)
          ? (_openBlock(), _createElementBlock("img", _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}