<template>
  <div
    class="bitBirthSelect"
    :class="[
      {
        bitBirthSelectSelectError: !!lessThen18ErrorMessage || isError,
        bitBirthSelectDisabled: locked || disabled,
      },
    ]"
  >
    <div class="bitBirthSelectLabelText">{{ labelText }}</div>
    <div class="bitBirthSelectWrapper">
      <BitSelect
        v-model="dateForm.day"
        class="bitBirthSelectItem"
        is-no-margin
        :options="listDays"
        :is-error="!!lessThen18ErrorMessage || isError"
        :is-success="!isAnyDateOfBirthValidatorErrors && isAnyDirtyDateOfBirthFields"
        :placeholder="dayPlaceholder"
        :locked="locked"
        @update:modelValue="dirtyDateStatus.day = true"
      />
      <BitSelect
        v-model="dateForm.month"
        class="bitBirthSelectItem"
        :options="listMonths"
        is-no-margin
        :is-error="!!lessThen18ErrorMessage || isError"
        :is-success="!isAnyDateOfBirthValidatorErrors && isAnyDirtyDateOfBirthFields"
        :placeholder="monthPlaceholder"
        :locked="locked"
        @update:modelValue="dirtyDateStatus.month = true"
      />
      <BitSelect
        v-model="dateForm.year"
        class="bitBirthSelectItem"
        :options="listYears"
        is-no-margin
        :is-error="!!lessThen18ErrorMessage || isError"
        :is-success="!isAnyDateOfBirthValidatorErrors && isAnyDirtyDateOfBirthFields"
        :placeholder="yearPlaceholder"
        :locked="locked"
        @update:modelValue="dirtyDateStatus.year = true"
      />
    </div>
    <span v-if="isAnyDateOfBirthValidatorErrors" class="bitBirthSelectErrorMessage">
      {{ dateOfBirthValidatorMessage }}
    </span>
    <span v-else-if="isError" class="bitBirthSelectErrorMessage">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, watch } from 'vue';
import BitSelect from '../select/select.vue';

export default defineComponent({
  components: {
    BitSelect,
  },
  props: {
    errorMessage: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    notEnoughYearsMessage: {
      type: String,
      default: '',
    },
    yearPlaceholder: {
      type: String,
      default: '',
    },
    monthPlaceholder: {
      type: String,
      default: '',
    },
    dayPlaceholder: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'hasEnoughYears'],
  setup(props, { emit }) {
    const dateForm = reactive<{
      month: string | number;
      year: string | number;
      day: string | number;
    }>({
      month: '',
      year: '',
      day: '',
    });

    const dirtyDateStatus = reactive({
      month: false,
      year: false,
      day: false,
    });

    const listYears = computed(() => {
      const arrYears: number[] = [];
      const listYears = new Date().getFullYear();

      for (let i = listYears; i > listYears - 100; --i) {
        arrYears.push(i);
      }

      return arrYears;
    });

    // @ts-ignore
    const listMonths = computed(() => [...Array(12 + 1).keys()].slice(1));
    // @ts-ignore
    const listDays = computed(() => [...Array(31 + 1).keys()].slice(1));

    const isAnyDateOfBirthValidatorErrors = computed(() =>
      Boolean(!dateForm.day || !dateForm.month || !dateForm.year || lessThen18ErrorMessage.value)
    );

    const getAge = (birthDateMS: number) => {
      const today = new Date();
      const birthDate = new Date(birthDateMS);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    };

    const birthDate = computed(() => {
      const day = +dateForm.day > 9 ? +dateForm.day : `0${dateForm.day}`;
      const month = +dateForm.month > 9 ? +dateForm.month : `0${dateForm.month}`;

      return `${dateForm.year}-${month}-${day}`;
    });

    const lessThen18ErrorMessage = computed(() => {
      let errorMessage: null | string = null;

      if (dateForm.day && dateForm.month && dateForm.year) {
        const age = getAge(Date.parse(birthDate.value));

        if (age < 18) {
          errorMessage = props.notEnoughYearsMessage;
        }
      }

      return errorMessage;
    });

    const dateOfBirthValidatorMessage = computed(
      () => (lessThen18ErrorMessage.value && lessThen18ErrorMessage.value) || props.errorMessage
    );

    const isAnyDirtyDateOfBirthFields = computed(() => {
      return dirtyDateStatus.day && dirtyDateStatus.month && dirtyDateStatus.year;
    });

    watch([() => birthDate.value, () => lessThen18ErrorMessage.value], () => {
      if (dateForm.day && dateForm.month && dateForm.year && !lessThen18ErrorMessage.value) {
        emit('update:modelValue', birthDate.value);
        emit('hasEnoughYears', true);
      }
    });

    watch(
      () => lessThen18ErrorMessage.value,
      (val) => {
        emit('hasEnoughYears', !val);
      }
    );

    onMounted(() => {
      if (props.modelValue) {
        const year = Number(props.modelValue.split('-')?.[0]) || dateForm.year;
        const month = Number(props.modelValue.split('-')?.[1]) || dateForm.month;
        const day = Number(props.modelValue.split('-')?.[2]) || dateForm.day;
        dateForm.day = day;
        dateForm.month = month;
        dateForm.year = year;
      }
    });

    return {
      listDays,
      listMonths,
      listYears,
      dateForm,
      isAnyDirtyDateOfBirthFields,
      isAnyDateOfBirthValidatorErrors,
      dateOfBirthValidatorMessage,
      lessThen18ErrorMessage,
      dirtyDateStatus,
    };
  },
});
</script>

<style lang="scss" src="./dateOfBirthSelect.scss"></style>
