<template>
  <div>
    <!-- DESKTOP MODE  -->
    <div v-show="isDesktop" class="form-dropdown">
      <span
        data-name="dropdown_name"
        class="form-dropdown__name"
        :class="{
          active: oneListVisible,
          placeholderActive: !selectedOptionValue,
          errored: isError,
        }"
        @click="toggleList()"
      >
        {{ selectedOptionValue || placeholderValue }}

        <svg
          class="form-dropdown__icon"
          width="16"
          height="9"
          viewBox="0 0 16 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.34317 0L0.928955 1.41421L8.00001 8.48531L15.0711 1.41424L13.6569 2.90871e-05L8.00003 5.65686L2.34317 0Z"
            fill="#C6A9E3"
            fill-opacity="0.65"
          />
        </svg>
      </span>
      <ul class="form-dropdown__list" :class="{ selectShow: oneListVisible }">
        <li
          v-for="(option, i) of options"
          :key="i"
          class="form-dropdown__item"
          :class="{ selected: i == selectedOptionIndex }"
          @click="optionSelect(i)"
        >
          {{ option }}
        </li>
      </ul>
    </div>
    <!-- TABLET or MOBILE MODE -->
    <div v-show="isMobile || isTablet">
      <select
        class="select"
        :value="selectedOptionIndex"
        @change="optionSelect($event.target.value)"
      >
        <option disabled selected>
          {{ placeholderValue }}
        </option>
        <option v-for="(option, i) of options" :key="i" :value="i">
          {{ option }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, Ref, watch } from 'vue';
import { useStorePlatform } from '@/store/client/platform';

export interface ChangeValueEventInterface {
  index: string | null;
  value: string;
}

const props = defineProps<{
  options: number[] | string[] | { [key: string]: string | number };
  defaultIndex?: number;
  modelValue?: string;
  defaultValue?: string;
  placeholder?: string;
  readonly?: boolean;
  isError?: boolean;
  targetHost?: string;
  customIndex?: number;
  isSelectionListVisible?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change', params: ChangeValueEventInterface): void;
  (e: 'update:modelValue', value: string): void;
  (e: 'toggleList', value: number): void;
  (e: 'closeList', value: number): void;
}>();

/**
 * method for passing values
 */
const onInput = (value: string | null) => {
  emit('update:modelValue', value);
};

/**
 * method for change the value in the interface
 */
const onChange = (params: ChangeValueEventInterface) => {
  if (Array.isArray(props.options)) {
    onInput(params.value);
  } else {
    onInput(params.index);
  }
  emit('change', params);
};

const index: Ref<string | null> = ref(null);
const isListVisble = ref(false);

const platformStore = useStorePlatform();
const isDesktop = computed(() => platformStore.isDesktop);
const isMobile = computed(() => platformStore.isMobile);
const isTablet = computed(() => platformStore.isTablet);

const getIndexByValue = (value: string | number): string | null => {
  if (Array.isArray(props.options)) {
    const index = props.options.findIndex((v: string | number) => String(v) === String(value));
    if (index >= 0) {
      return String(index);
    }
  } else {
    return String(value);
  }

  return null;
};

/**
 * to maintain the connectedness of the value by v-model, if the value changes there, then we automatically change it in the select
 */
watch(
  () => props.modelValue,
  () => {
    // console.debug('value must be', this.value, ' with index ', this.getIndexByValue(this.value), typeof this.value);
    // console.debug('options now is', JSON.stringify(this.options));
    if (props.modelValue) {
      index.value = getIndexByValue(props.modelValue);
    }
  },
  { immediate: true }
);

/**
 * method for shows a list of options
 */
const toggleList = () => {
  if (props.readonly) {
    return;
  }
  if (props.customIndex) {
    emit('toggleList', props.customIndex);
  } else {
    isListVisble.value = !isListVisble.value;
  }
};

/**
 * method for closing after the selected item
 */
const hideList = () => {
  if (props.customIndex) {
    emit('toggleList', props.customIndex);
  } else {
    isListVisble.value = false;
  }
};

const oneListVisible = computed(() => {
  if (props.customIndex) {
    return props.isSelectionListVisible;
  } else {
    return isListVisble.value;
  }
});

const selectOptions = computed((): { [key: string]: string | number } => {
  if (Array.isArray(props.options)) {
    const parsedOptions: { [key: string]: string | number } = {};
    props.options.forEach((value: string | number, index: number) => {
      parsedOptions[String(index)] = value;
    });
    return parsedOptions;
  } else {
    return props.options;
  }
});

/**
 * getter for placeholder show or an empty string
 */
const placeholderValue = computed((): string => {
  return props.placeholder || '';
});

/**
 * getter for return index of selected option
 *
 * @returns selected optionindex number if exist
 * @returns null if no selected data
 *  */
const selectedOptionIndex = computed((): string | null => {
  // console.debug('index is >>>>>> ', this.index);
  if (index.value === null) {
    if (props.defaultIndex >= 0) {
      return String(props.defaultIndex);
    } else if (props.defaultValue) {
      return getIndexByValue(props.defaultValue);
    } else {
      return null;
    }
  } else {
    return String(index.value);
  }
});

/**
 * getter for return value of option
 *
 * @returns selected option if exist or selected
 * @returns empty string if not selected or not exist
 *  */
const selectedOptionValue = computed((): string => {
  if (selectedOptionIndex.value === null || !selectOptions.value[selectedOptionIndex.value]) {
    return '';
  } else {
    return String(selectOptions.value[selectedOptionIndex.value]);
  }
});

/**
 *  method for selection and close the list
 *
 * @param optionIndex - parameters for assigning a value
 */
const optionSelect = (optionIndex: any) => {
  index.value = optionIndex;
  hideList();
  onChange({
    index: selectedOptionIndex.value,
    value: selectedOptionValue.value,
  });
};
</script>

<style scoped lang="scss" src="./customSelect.scss"></style>
