export const useCookie = () => {
  const rootDomain = (hostname: string) => {
    let parts = hostname.split('.');
    if (parts.length <= 2) {
      return hostname;
    }

    parts = parts.slice(-3);
    if (['co', 'com'].includes(parts[1])) {
      return parts.join('.');
    }

    return parts.slice(-2).join('.');
  };

  const getCookieValue = (name: string) => {
    const regex = new RegExp(`(^| )${name}=([^;]+)`);
    const match = document.cookie.match(regex);
    if (match) {
      return match[2];
    }
  };

  const saveUserType = () => {
    if (!window) {
      return;
    }

    const userType = getCookieValue('UserType');

    if (userType && userType === 'DepN') {
      return;
    }

    const domain = rootDomain(document.location.hostname);

    window.document.cookie = `UserType=Dep0;Max-Age=63158400;Domain=${domain};Path=/`;
  };

  const saveUserId = (playerId: string | number) => {
    const domain = rootDomain(document.location.hostname);

    window.document.cookie = `UserID=${playerId};Max-Age=63158400;Domain=${domain};Path=/`;
    window.localStorage.setItem('UserID', playerId?.toString() || '');
  };

  return {
    rootDomain,
    getCookieValue,
    saveUserType,
    saveUserId,
  };
};
