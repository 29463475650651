<template>
  <component
    :is="tag"
    class="bitSelectItem"
    :class="{ bitSelectItemSelected: isSelected, bitSelectItemLong: additionalText }"
    @click="$emit('click')"
  >
    <span class="bitSelectItemOption">
      <span v-if="$slots.appendOptionIcon" class="bitSelectItemIconAppend">
        <slot name="appendOptionIcon" />
      </span>

      <span class="bitSelectItemText">{{ optionText }}</span>
      <span v-if="additionalText" class="bitSelectItemAdditionalText">{{ additionalText }}</span>
    </span>
    <span class="bitSelectItemSelectedIcon">
      <img v-if="isSelected" src="~assets/images/common/done.svg" alt="done" />
    </span>
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';

export default defineComponent({
  props: {
    tag: {
      type: String as PropType<'li' | 'div' | 'span' | 'p'>,
      default: 'li',
    },
    isSelected: {
      type: Boolean as PropType<boolean | undefined | null>,
      default: false,
    },
    optionText: {
      type: [String, Number],
      default: '',
    },
    additionalText: {
      type: [String, Number],
      default: '',
    },
  },
  components: {},
  emits: ['click'],
});
</script>

<style lang="scss" src="./selectItem.scss"></style>
