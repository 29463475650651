import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "errorText phoneErrorText"
}

import { watch, ref, computed } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import { PhoneVueTelInputInterface } from '@/types/country.vuetelinput.interface';
import 'vue-tel-input/dist/vue-tel-input.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'telephoneInput',
  props: {
    modelValue: {},
    readonly: { type: Boolean },
    profileFormData: {},
    playerCountry: {},
    isError: { type: Boolean },
    isSuccess: { type: Boolean }
  },
  emits: ["update:modelValue", "blur", "input"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const input = (value) => {
  emit('update:modelValue', value);
};
const fullPhoneNumber = ref('');
const isValid = ref(true);

const phoneObject = ref({} as { number?: string });

/**
 * Props options for plugin `vue-tel-input`:
 * https://iamstevendao.github.io/vue-tel-input/documentation/props.html
 */
const optionsPhoneInput = computed(() => {
  return {
    mode: 'international',
    inputOptions: {
      id: 'phone',
      name: 'mobile-phone',
      class: 'modal-input',
      placeholder: props.profileFormData.mobile_phone_title,
      readonly: props.readonly,
    },
    dropdownOptions: {
      validCharactersOnly: true,
      showDialCodeInList: false,
      showDialCodeInSelection: false,
      showFlags: true,
    },
    defaultCountry: props.playerCountry.toLowerCase(),
    validCharactersOnly: false,
    preferredCountries: ['au', 'ca', 'nz', 'at', 'ch'],
    // TODO: bug - when you tiping number of ignored country, component will down, PR sended
    // ignoredCountries: ['sk', 'il', 'lt', 'es', 'ua', 'uk', 'fr', 'nl', 'us'],
    autoDefaultCountry: false,
  };
});

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue && props.modelValue !== fullPhoneNumber.value) {
      fullPhoneNumber.value = props.modelValue;
    }
  },
  { immediate: true }
);

watch(fullPhoneNumber, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    input(newValue);
  }
});
watch(
  phoneObject,
  (newPhone, oldPhone) => {
    if (newPhone.country && newPhone.country.dialCode !== oldPhone.country?.dialCode) {
      fullPhoneNumber.value = `+${newPhone.country.dialCode}`;
    }
  },
  { deep: true }
);
/**
 * Method that formatted phone number on every change
 */
const onChange = (number: string, phone: PhoneVueTelInputInterface) => {
  if (phone.valid !== undefined) {
    isValid.value = phone.valid;
  } else if (number.length > (phone.country?.dialCode.length || -1) + 1) {
    isValid.value = false;
  }

  phoneObject.value = phone;
  if (!props.modelValue && !phone.nationalNumber) {
    if (phone.country?.iso2 === 'CH') {
      fullPhoneNumber.value = `+${phone.country.dialCode} 860`;
    } else {
      fullPhoneNumber.value = `+${phone.country.dialCode}`;
    }
  }
  // SoftSwiss CRUNCH!
  // if country === SWITZERLAND without voice email we must add 860 prefix before nationl number
  if (
    phone.countryCode === 'CH' &&
    !phone.nationalNumber.startsWith('860') &&
    phone.nationalNumber.length === 9 &&
    phone.valid
  ) {
    return input(`+${phone.countryCallingCode} 860 ${phone.nationalNumber}`);
  }
  input(number);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.playerCountry)
      ? (_openBlock(), _createBlock(_unref(VueTelInput), _mergeProps({ key: 0 }, optionsPhoneInput.value, {
          modelValue: fullPhoneNumber.value,
          class: { hasError: _ctx.isError || !isValid.value, isSuccess: _ctx.isSuccess && isValid.value },
          onOnInput: onChange,
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('blur'))),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input')))
        }), null, 16, ["modelValue", "class"]))
      : _createCommentVNode("", true),
    (!_ctx.isError && !isValid.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "Invalid"))
      : _createCommentVNode("", true)
  ]))
}
}

})