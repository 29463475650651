import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modern2LionFooter" }
const _hoisted_2 = { class: "modern2LionFooterInfoLinks" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "portals" }
const _hoisted_5 = { class: "portalsHeading" }
const _hoisted_6 = { class: "portalsHeadingList" }
const _hoisted_7 = ["width", "height"]
const _hoisted_8 = { class: "footerLicense" }
const _hoisted_9 = { class: "footerLicenseLeft" }
const _hoisted_10 = { class: "footerLicenseText" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "footerLicenseLogo" }
const _hoisted_13 = ["width", "height"]
const _hoisted_14 = { class: "licenseInfo" }
const _hoisted_15 = {
  key: 0,
  class: "licenseInfoImagesBlock"
}
const _hoisted_16 = {
  key: 0,
  class: "licenseImg"
}
const _hoisted_17 = {
  href: "https://7bitpartners.com/",
  target: "_blank"
}
const _hoisted_18 = ["data-src", "width", "height", "alt"]
const _hoisted_19 = { class: "licenseImg" }
const _hoisted_20 = ["data-src", "width", "height", "alt"]
const _hoisted_21 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFooter.informationLink, (link) => {
        return (_openBlock(), _createElementBlock("li", {
          key: link.link
        }, [
          _createElementVNode("a", {
            href: `https://${_ctx.regionalDomain}${link.link}`,
            class: "modern2LionFooterLink",
            target: "_blank"
          }, _toDisplayString(link.title), 9, _hoisted_3)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.storeFooter.partnersTitle), 1),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFooter.partnersLink, (img, imgIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            key: imgIndex,
            class: "portalsHeadingImg footerImageGray"
          }, [
            _withDirectives(_createElementVNode("img", {
              width: img.img_src.width,
              height: img.img_src.height,
              alt: ""
            }, null, 8, _hoisted_7), [
              [_directive_lazy, img.img_src.url]
            ])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("ul", _hoisted_10, [
          _createElementVNode("li", null, _toDisplayString(_ctx.storeFooter.responsibleGamingTitle), 1),
          _createElementVNode("a", {
            href: `https://${_ctx.regionalDomain}${_ctx.storeFooter.responsibleGamingLink.link}`,
            target: "_blank",
            class: "modern2LionFooterLink"
          }, _toDisplayString(_ctx.storeFooter.responsibleGamingLink.title), 9, _hoisted_11)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFooter.gamblers, (img, imgIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: imgIndex,
              class: "footerLicenseLogoImg"
            }, [
              _withDirectives(_createElementVNode("img", {
                width: img.img_src.width,
                height: img.img_src.height,
                class: "imageLinkLicenceImg",
                alt: ""
              }, null, 8, _hoisted_13), [
                [_directive_lazy, img.img_src.url]
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.storeFooter.logo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_ctx.storeFooter.partnersLogo.url)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("a", _hoisted_17, [
                      _withDirectives(_createElementVNode("img", {
                        "data-src": _ctx.storeFooter.partnersLogo.url,
                        width: _ctx.storeFooter.partnersLogo.width,
                        height: _ctx.storeFooter.partnersLogo.height,
                        alt: _ctx.storeFooter.partnersLogo.alternativeText || ''
                      }, null, 8, _hoisted_18), [
                        [_directive_lazy, _ctx.storeFooter.partnersLogo.url]
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_19, [
                _withDirectives(_createElementVNode("img", {
                  "data-src": _ctx.storeFooter.logo.url,
                  width: _ctx.storeFooter.logo.width,
                  height: _ctx.storeFooter.logo.height,
                  alt: _ctx.storeFooter.logo.alternativeText || ''
                }, null, 8, _hoisted_20), [
                  [_directive_lazy, _ctx.storeFooter.logo.url]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Markdown, {
          class: "licenseText",
          text: _ctx.storeFooter.licenseText
        }, null, 8, ["text"])
      ])
    ]),
    _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.copyright), 1)
  ]))
}