import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-theme"]
const _hoisted_2 = { class: "contentBlocksContainer" }
const _hoisted_3 = ["data-position"]
const _hoisted_4 = { class: "contentBlockTitle" }
const _hoisted_5 = { alt: "welcome pack" }
const _hoisted_6 = { class: "contentBlockSubTitle" }
const _hoisted_7 = { class: "welcomeBonusesWrapper" }
const _hoisted_8 = ["data-position"]
const _hoisted_9 = { class: "contentBlockTitle" }
const _hoisted_10 = { alt: "promotions" }
const _hoisted_11 = { class: "contentBlockSubTitle" }
const _hoisted_12 = { class: "promotionsWrapper" }
const _hoisted_13 = ["data-position"]
const _hoisted_14 = { class: "contentBlockTitle" }
const _hoisted_15 = { alt: "tournaments" }
const _hoisted_16 = { class: "contentBlockSubTitle" }
const _hoisted_17 = { class: "tournamentsWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modern2LionMainPage = _resolveComponent("Modern2LionMainPage")!
  const _component_WelcomeCard = _resolveComponent("WelcomeCard")!
  const _component_PromotionCard = _resolveComponent("PromotionCard")!
  const _component_TournamentCard = _resolveComponent("TournamentCard")!
  const _component_Modern2LionFooter = _resolveComponent("Modern2LionFooter")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.isLoaded && _ctx.currentPage)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-theme": _ctx.theme,
        class: "modern2LionContainer bonusHubContainer"
      }, [
        _createVNode(_component_Modern2LionMainPage, {
          "modal-redirect-path": _ctx.modalRedirectPath,
          onSetModalRedirectPath: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setModalRedirectPath($event)))
        }, null, 8, ["modal-redirect-path"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.welcomePackBonuses.length && _ctx.currentPage.template.welcomePackBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.welcomePackBlock.position
              }, [
                _createElementVNode("h3", _hoisted_4, [
                  _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
                    [_directive_lazy, require('~/assets/images/common/crown.svg')]
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.currentPage.template.welcomePackBlock.title), 1)
                ]),
                _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.currentPage.template.welcomePackBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.welcomePackBonuses, (bonus, indx) => {
                    return (_openBlock(), _createBlock(_component_WelcomeCard, {
                      key: bonus.bonusId,
                      title: _ctx.currentPage.template.welcomePackBlock[`depTitle${indx + 1}`],
                      offer: _ctx.currentPage.template.welcomePackBlock[`depOfferTitle${indx + 1}`],
                      "card-icon": _ctx.currentPage.template.welcomePackBlock.cardIcon
                    }, null, 8, ["title", "offer", "card-icon"]))
                  }), 128))
                ])
              ], 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.promotionBonusesToShow.length && _ctx.currentPage.template.promotionsBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.promotionsBlock.position
              }, [
                _createElementVNode("h3", _hoisted_9, [
                  _withDirectives(_createElementVNode("img", _hoisted_10, null, 512), [
                    [_directive_lazy, require('~/assets/images/common/pack.svg')]
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.currentPage.template.promotionsBlock.title), 1)
                ]),
                _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.currentPage.template.promotionsBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionBonusesToShow, (bonus, index) => {
                    return (_openBlock(), _createBlock(_component_PromotionCard, {
                      key: bonus.bonusId,
                      bonus: bonus,
                      index: index,
                      "get-bonus-title": _ctx.currentPage.template.promotionsBlock.getBonusTitle,
                      onSetModalRedirectPath: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setModalRedirectPath($event)))
                    }, null, 8, ["bonus", "index", "get-bonus-title"]))
                  }), 128))
                ]),
                (!_ctx.storePlatform.isDesktop && _ctx.promotionBonuses.length > 4)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "showMoreButton",
                      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePromotionBonusesDisplayCount && _ctx.togglePromotionBonusesDisplayCount(...args)))
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.isAllPromotionBonusesShown ? 'show less' : 'show more') + " ", 1),
                      _withDirectives(_createElementVNode("img", {
                        class: _normalizeClass({ rotated: _ctx.isAllPromotionBonusesShown }),
                        alt: "arrow"
                      }, null, 2), [
                        [_directive_lazy, require('~/assets/images/common/arrow-down.svg')]
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_8))
            : _createCommentVNode("", true),
          (_ctx.tournamentsToShow.length && _ctx.currentPage.template.tournamentsBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.tournamentsBlock.position
              }, [
                _createElementVNode("h3", _hoisted_14, [
                  _withDirectives(_createElementVNode("img", _hoisted_15, null, 512), [
                    [_directive_lazy, require('~/assets/images/common/cup.svg')]
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.currentPage.template.tournamentsBlock.title), 1)
                ]),
                _createElementVNode("h4", _hoisted_16, _toDisplayString(_ctx.currentPage.template.tournamentsBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_17, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tournamentsToShow, (race, index) => {
                    return (_openBlock(), _createBlock(_component_TournamentCard, {
                      key: race.raceData.ssId,
                      index: index,
                      race: race,
                      "prize-pool-title": _ctx.currentPage.template.tournamentsBlock.prizePoolTitle,
                      onSetModalRedirectPath: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setModalRedirectPath($event)))
                    }, null, 8, ["index", "race", "prize-pool-title"]))
                  }), 128))
                ]),
                (!_ctx.storePlatform.isDesktop && _ctx.tournamentsExtended.length > 4)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "showMoreButton",
                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.toggleTournamentsDisplayCount && _ctx.toggleTournamentsDisplayCount(...args)))
                    }, [
                      _createTextVNode(_toDisplayString(_ctx.isAllTournamentsShown ? 'show less' : 'show more') + " ", 1),
                      _withDirectives(_createElementVNode("img", {
                        class: _normalizeClass({ rotated: _ctx.isAllTournamentsShown }),
                        alt: "arrow"
                      }, null, 2), [
                        [_directive_lazy, require('~/assets/images/common/arrow-down.svg')]
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_13))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Modern2LionFooter, {
          template: _ctx.currentPage.template
        }, null, 8, ["template"])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}