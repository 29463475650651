import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, defineAsyncComponent } from 'vue';
import { useStoreAutochangeData } from '@/store/strapi/autochangeData';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { AutochangeStrapiItem } from '~/types/strapi/autochangeItem.strapi.interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'markdown',
  props: {
    text: {},
    autoLinkParse: { type: Boolean },
    isLinkNewPageOpen: { type: Boolean }
  },
  setup(__props: any) {

const VueMarkdown = defineAsyncComponent(() => import('vue3-markdown-it'));

const props = __props;

const storeSystemHosts = useStoreSystemHosts();
const autochangeDataStore = useStoreAutochangeData();

const redirectDomain = computed((): string => {
  return storeSystemHosts.redirectHost;
});

const regionalDomain = computed((): string => {
  return storeSystemHosts.regional;
});

const linkify = computed(() => {
  return props.autoLinkParse || false;
});

const autochangeItems = computed((): AutochangeStrapiItem[] => {
  return autochangeDataStore.autochangeItemsGetter;
});

const anchorAttributes = computed((): { target?: string } =>
  props.isLinkNewPageOpen ? { target: '_blank' } : {}
);

const replaceAutochangedTarget = (match: string, autochangeItems: AutochangeStrapiItem[]) => {
  const oddUnderscoresAmount = 1; // @key@ -> key

  const croppedKey = match.slice(oddUnderscoresAmount, match.length - oddUnderscoresAmount);

  const replaycment = autochangeItems.find((changeItem) => changeItem.label === croppedKey);

  return replaycment?.value ?? match;
};

const md = computed((): string => {
  if (props.text) {
    return (
      String(props.text)
        // href links parser, because content manager is not so smart
        .replace(/\[([^[]+)\]\(([^(]+)\)/gm, (...v: string[]) => {
          if (v[2] && v[2].startsWith('http')) {
            return `[${v[1]}](${v[2]})`;
          } else {
            let domain = `https://${redirectDomain.value}`;
            if (v[2] === '/') {
              return `[${v[1]}](${domain})`;
            }
            if (v[2][0] !== '/') {
              domain += '/';
            }
            return `[${v[1]}](${domain}${v[2]})`;
          }
        })
        .replace(/%domain_regional%/gm, () => regionalDomain.value)
        .replace(/%[a-z0-9\-_]+%/gm, (match: string) =>
          replaceAutochangedTarget(match, autochangeItems.value)
        )
    );
  }
  return '';
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(VueMarkdown), {
    linkify: linkify.value,
    anchor: anchorAttributes.value,
    breaks: true,
    html: true,
    source: md.value
  }, null, 8, ["linkify", "anchor", "source"]))
}
}

})