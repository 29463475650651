import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useAbbrFromPath = () => {
  const route = useRoute();

  const abbr = computed(() => {
    // @ts-ignore
    return route.path
      .split('/')
      .at(-1)
      .split('-')
      .reduce((result, value) => {
        result += value[0].toUpperCase();
        return result;
      }, '');
  });

  return {
    abbr,
  };
};
