import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from 'assets/images/common/ico_slider_arrow_right.svg'
import _imports_1 from 'assets/images/common/lock.svg'


const _hoisted_1 = {
  key: 0,
  class: "bitSelectLabelText"
}
const _hoisted_2 = ["gtmid"]
const _hoisted_3 = {
  key: 0,
  class: "bitSelectIconAppendInput"
}
const _hoisted_4 = ["gtmid"]
const _hoisted_5 = { class: "bitSelectIconDefault" }
const _hoisted_6 = ["gtmid"]
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  alt: "lock"
}
const _hoisted_8 = { class: "bitSelectList" }
const _hoisted_9 = {
  key: 1,
  class: "bitSelectErrorText"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectItem = _resolveComponent("SelectItem")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bitSelect", [
      {
        bitSelectNoMargin: _ctx.isNoMargin,
        bitSelectError: _ctx.isError && _ctx.isSelectActive,
        bitSelectErrorNoMessage: _ctx.isError && _ctx.isNoErrorMessage,
        bitSelectDisabled: _ctx.disabled || _ctx.locked,
        bitSelectVariantFilter: _ctx.variant === 'filter',
        bitSelectVariantCurrencySm: _ctx.variant === 'currency-small',
        bitSelectVariantLanguage: _ctx.variant === 'language',
      },
    ]])
  }, [
    (_ctx.textLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.textLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      "data-name": "dropdown_name",
      class: _normalizeClass(["bitSelectName", [
        {
          bitSelectNameActive: _ctx.isSelectionListVisble,
          bitSelectNamePlaceholderActive: !_ctx.selectedOptionValue,
        },
      ]]),
      gtmid: _ctx.gtmid,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleList()))
    }, [
      (_ctx.$slots.appendInputIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "appendInputIcon")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: "bitSelectText",
        gtmid: _ctx.gtmid
      }, _toDisplayString(_ctx.customSelectedLabel || _ctx.selectedOptionValue || _ctx.placeholderValue), 9, _hoisted_4),
      _createElementVNode("span", _hoisted_5, [
        (!_ctx.locked)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "bitSelectIconArrow",
              src: _imports_0,
              gtmid: _ctx.gtmid
            }, null, 8, _hoisted_6))
          : (_openBlock(), _createElementBlock("img", _hoisted_7))
      ])
    ], 10, _hoisted_2),
    _createElementVNode("div", {
      class: _normalizeClass(["bitSelectContainer", { bitSelectShow: _ctx.isSelectionListVisble }])
    }, [
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createBlock(_component_SelectItem, {
            key: i,
            "option-text": option,
            "is-selected": i == _ctx.selectedOptionIndex,
            onClick: ($event: any) => (_ctx.optionSelect(String(i)))
          }, _createSlots({ _: 2 }, [
            (_ctx.$slots.appendOptionIcon)
              ? {
                  name: "appendOptionIcon",
                  fn: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "appendOptionIcon", { value: option })
                  ]),
                  key: "0"
                }
              : undefined
          ]), 1032, ["option-text", "is-selected", "onClick"]))
        }), 128))
      ])
    ], 2),
    (_ctx.isError && _ctx.isSelectActive && !_ctx.isNoErrorMessage && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, _ctx.hideList]
  ])
}