<template>
  <button :type="typeButton" class="bitButton" :class="buttonClasses" :gtmid="gtmid">
    <span v-if="appendIcon" class="buttonIconLeft" :gtmid="gtmid">
      <img :src="require(`~/assets/images/common/${appendIcon}`)" alt="buttonIcon" :gtmid="gtmid" />
    </span>
    <span class="buttonTextInside" :gtmid="gtmid"><slot /></span>
    <span v-if="prependIcon" class="buttonIconRight" :gtmid="gtmid">
      <img
        :src="require(`~/assets/images/common/${prependIcon}`)"
        alt="buttonIcon"
        :gtmid="gtmid"
      />
    </span>
    <span v-if="loading" class="buttonLoadingicon" />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import type { ButtonHeightSize, ButtonType, ButtonWidthSize } from './types';
import type { PropType } from 'vue';

export default defineComponent({
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: 'primary',
    },
    variant: {
      type: String as PropType<'input' | 'landing-lg'>,
      default: null,
    },
    isFluidWidth: {
      type: Boolean,
      default: false,
    },
    isFitWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    widthSize: {
      type: String as PropType<ButtonWidthSize>,
      default: 'max',
    },
    maxWidthSize: {
      type: String as PropType<ButtonWidthSize>,
      default: null,
    },
    heightSize: {
      type: String as PropType<ButtonHeightSize>,
      default: 'md',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    typeButton: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button',
    },
    mobileOnly: {
      type: Boolean,
      default: false,
    },
    noMargin: {
      type: Boolean,
      default: false,
    },
    gtmid: {
      type: String as PropType<string | null>,
      default: null,
    },
    isSpecialButton: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const buttonClasses = computed(() => {
      return [
        {
          fluidWidth: props.isFluidWidth,
          fitWidth: props.isFitWidth,
          disabled: props.disabled,
          loading: props.loading,
          mobileOnly: props.mobileOnly,
          noMargin: props.noMargin,
          specialButton: props.isSpecialButton,
        },
        props.type,
        'width' + props.widthSize,
        props.maxWidthSize ? 'maxWidth' + props.maxWidthSize : '',
        'height' + props.heightSize,
        props.variant,
      ];
    });

    return {
      buttonClasses,
    };
  },
});
</script>
<style lang="scss" src="./button.scss"></style>
