import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "promo" }

import { v4 as uuid } from 'uuid';
import { computed, onBeforeMount, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import usePromoModal from './composables/promoModalState';
import { useCookie } from './composables/useCookie';
import { useOneSignalID } from './composables/useOneSignalID';
import { useStorePlatform } from './store/client/platform';
import { useStoreStag } from './store/client/stag';
import { useStoreAutochangeData } from './store/strapi/autochangeData';
import { useUbidex } from '@/composables/useUbidex';
import { scriptLoader } from '~/utils/scriptLoader';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const route = useRoute();
const storeAutochangeData = useStoreAutochangeData();
const platformStore = useStorePlatform();
platformStore.checkPlatform();

const { saveUserType } = useCookie();
const { show } = usePromoModal();

const bodyClass = computed(() => {
  const bodyClass: string[] = [];

  if (platformStore.isDesktop) {
    bodyClass.push('isDesktop');
  } else if (platformStore.isTablet) {
    bodyClass.push('isTablet');
  } else if (platformStore.isMobile) {
    bodyClass.push('isMobile');
  }

  if (show.value) {
    bodyClass.push('stop-scroll');
  }

  bodyClass.push(platformStore.isPortraitOrientation ? 'isPortrait' : 'isAlbum');

  return bodyClass.join(' ');
});

watch(
  bodyClass,
  (classes) => {
    const classesArr = [
      'isDesktop',
      'isTablet',
      'isMobile',
      'isPortrait',
      'isAlbum',
      'stop-scroll',
    ];
    classesArr.forEach((cl) => {
      document.body.classList.remove(cl);
    });
    classes.split(' ').forEach((cl) => {
      if (!document.body.classList.contains(cl)) {
        document.body.classList.add(cl);
      }
    });
  },
  { immediate: true }
);

onBeforeMount(() => {
  saveUserType();
  useStoreStag().init();
  useOneSignalID().init();
  storeAutochangeData.load();
});

onMounted(() => {
  scriptLoader('https://cdn.seondf.com/js/v5/agent.js', 'seondf').then(() => {
    // @ts-ignore
    window.seon.config({
      host: 'seondf.com',
      session_id: uuid(),
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      max_proxy_delay: 1000,
    });

    const stag = String(route?.query?.stag || '');

    useUbidex().sendEvent({
      event: 'visit',
      stag,
    });
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view)
  ]))
}
}

})