import { defineStore } from 'pinia';
import { useStoreCountry } from '../client/country';
import { useStorePlatform } from '../client/platform';
import { useStoreStag } from '../client/stag';
import { parseAndFind } from '@/utils/parseAndFind';
import imgUrlReplacer from '@/utils/strapi/imgUrlReplacer';

export interface LandingPageInterface {
  filterCountries: any;
  filterStags: any;
  head: any;
  path: string;
  theme: string;
  template: any;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  groupToAddOnReg: string;
}

export const useStoreLanding = defineStore('landings', {
  state: () => ({
    pages: [] as LandingPageInterface[],
    selectedCurrency: '',
  }),
  getters: {
    pathes(): string[] {
      return this.pages.flatMap(({ path }) => path).filter((p) => p);
    },
    pageData(): LandingPageInterface[] {
      return this.pages;
    },
    isLoaded(): boolean {
      return !!this.pathes.length;
    },
    filteredPages(): LandingPageInterface[] {
      const storeCountry = useStoreCountry();
      const storeStag = useStoreStag();

      return this.pageData.filter(
        (p) =>
          !(
            parseAndFind(p.filterCountries.includedCoutries, storeCountry.userCountry) === false ||
            parseAndFind(p.filterCountries.excludedCountries, storeCountry.userCountry) === true ||
            parseAndFind(p.filterStags.includedStags, storeStag.queryStag) === false ||
            parseAndFind(p.filterStags.excludedStags, storeStag.queryStag) === true
          )
      );
    },
    currentPage(): LandingPageInterface | null {
      const storeCountry = useStoreCountry();
      const storeStag = useStoreStag();

      return (
        this.filteredPages.find(
          (p) =>
            parseAndFind(p.filterCountries.includedCoutries, storeCountry.userCountry) &&
            parseAndFind(p.filterStags.includedStags, storeStag.queryStag)
        ) ||
        this.filteredPages.find(
          (p) =>
            !parseAndFind(p.filterCountries.includedCoutries, storeCountry.userCountry) &&
            parseAndFind(p.filterStags.includedStags, storeStag.queryStag)
        ) ||
        this.filteredPages.find(
          (p) =>
            parseAndFind(p.filterCountries.includedCoutries, storeCountry.userCountry) &&
            !parseAndFind(p.filterStags.includedStags, storeStag.queryStag)
        ) ||
        this.filteredPages.find(
          (p) =>
            !parseAndFind(p.filterCountries.includedCoutries, storeCountry.userCountry) &&
            !parseAndFind(p.filterStags.includedStags, storeStag.queryStag)
        ) ||
        null
      );
    },
    isMiraxFrenchLand(): LandingPageInterface {
      return (
        this.currentPage?.template.__component === 'landing-templates.modern2-square' &&
        this.currentPage?.template.isMiraxFrenchLand
      );
    },
    isGoogleRegTemplate(): boolean {
      return [
        'landing-templates.bonus-hub',
        'landing-templates.modern2-lion',
        'landing-templates.bonus-hub-neon',
      ].includes(this.currentPage?.template.__component);
    },
    bannerDesktop(): any {
      const storePlatform = useStorePlatform();
      const template = this.currentPage?.template;

      if (
        template?.bannerDesktop.mime?.includes('image') &&
        (storePlatform.isDesktop || storePlatform.isTablet || storePlatform.isMobile)
      ) {
        return template.bannerDesktop || null;
      }

      return null;
    },
    bannerMobile(): any {
      const template = this.currentPage?.template;

      if (template?.bannerMobile.mime?.includes('image') && useStorePlatform().isMobile) {
        return template.bannerMobile || null;
      }

      return null;
    },
    // bannerUrl(): {
    //   '--bg-img-desktop'?: string;
    //   '--bg-img-mobile'?: string;
    // } {
    //   const storePlatform = useStorePlatform();
    //   const css = {};

    //   if (storePlatform.isMobile) {
    //     css['--bg-img-mobile'] = `url(${this.bannerMobile?.url || ''})`;
    //     css['--bg-img-desktop'] = `url(${this.bannerDesktop?.url || ''})`;
    //   }
    //   if (storePlatform.isTablet || storePlatform.isDesktop) {
    //     css['--bg-img-desktop'] = `url(${this.bannerDesktop?.url || ''})`;
    //   }
    //   return css;
    // },
    bannerUrl(): string {
      const storePlatform = useStorePlatform();
      if (storePlatform.isMobile) {
        return this.bannerMobile?.url || '';
      }
      if (storePlatform.isTablet || storePlatform.isDesktop) {
        return this.bannerDesktop?.url || '';
      }
      // Default fallback
      return '';
    },
    logoImg(): any {
      return this.currentPage?.template?.logo || null;
    },
    currency(): string {
      return this.currentPage?.template?.currency?.currencyCode || '';
    },
    secondTitle(): string {
      const titleParams = this.currentPage?.template?.secondTitleMulti;

      if (titleParams && Array.isArray(titleParams)) {
        const country = useStoreCountry().userCountry?.toLowerCase() || '';
        const userCurrency = this.selectedCurrency.toLowerCase() || this.currency || '';

        if (titleParams?.length) {
          return (
            titleParams.find((b) => {
              return (
                b.country?.toLowerCase() === country && b.currency?.toLowerCase() === userCurrency
              );
            })?.value ||
            titleParams.find((b) => {
              return b.country?.toLowerCase() === country && !b.currency;
            })?.value ||
            titleParams.find((b) => {
              return b.currency?.toLowerCase() === userCurrency && !b.country;
            })?.value ||
            titleParams.find((b) => {
              return !b.currency && !b.country;
            })?.value ||
            null
          );
        } else {
          return '';
        }
      }

      return this.currentPage?.template?.secondTitle || '';
    },
  },
  actions: {
    ADD_PAGES_FROM_STRAPI(data) {
      imgUrlReplacer(data);

      const newPages = data.map((p) => ({
        path: p.path,
        theme: p.theme,
        groupToAddOnReg: p.groupToAddOnReg || '',
        head: p.head,
        filterCountries: p.filterCountries,
        filterStags: p.filterStags,
        createdAt: new Date(p.createdAt),
        updatedAt: new Date(p.updatedAt),
        publishedAt: new Date(p.publishedAt),
        template: p.template[0],
      }));

      this.pages = newPages;
    },
    setCurrency(currency: string) {
      this.selectedCurrency = currency;
    },
  },
});
