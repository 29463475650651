import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "form-dropdown" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  disabled: "",
  selected: ""
}
const _hoisted_5 = ["value"]

import { computed, ref, Ref, watch } from 'vue';
import { useStorePlatform } from '@/store/client/platform';

export interface ChangeValueEventInterface {
  index: string | null;
  value: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'customSelect',
  props: {
    options: {},
    defaultIndex: {},
    modelValue: {},
    defaultValue: {},
    placeholder: {},
    readonly: { type: Boolean },
    isError: { type: Boolean },
    targetHost: {},
    customIndex: {},
    isSelectionListVisible: { type: Boolean }
  },
  emits: ["change", "update:modelValue", "toggleList", "closeList"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

/**
 * method for passing values
 */
const onInput = (value: string | null) => {
  emit('update:modelValue', value);
};

/**
 * method for change the value in the interface
 */
const onChange = (params: ChangeValueEventInterface) => {
  if (Array.isArray(props.options)) {
    onInput(params.value);
  } else {
    onInput(params.index);
  }
  emit('change', params);
};

const index: Ref<string | null> = ref(null);
const isListVisble = ref(false);

const platformStore = useStorePlatform();
const isDesktop = computed(() => platformStore.isDesktop);
const isMobile = computed(() => platformStore.isMobile);
const isTablet = computed(() => platformStore.isTablet);

const getIndexByValue = (value: string | number): string | null => {
  if (Array.isArray(props.options)) {
    const index = props.options.findIndex((v: string | number) => String(v) === String(value));
    if (index >= 0) {
      return String(index);
    }
  } else {
    return String(value);
  }

  return null;
};

/**
 * to maintain the connectedness of the value by v-model, if the value changes there, then we automatically change it in the select
 */
watch(
  () => props.modelValue,
  () => {
    // console.debug('value must be', this.value, ' with index ', this.getIndexByValue(this.value), typeof this.value);
    // console.debug('options now is', JSON.stringify(this.options));
    if (props.modelValue) {
      index.value = getIndexByValue(props.modelValue);
    }
  },
  { immediate: true }
);

/**
 * method for shows a list of options
 */
const toggleList = () => {
  if (props.readonly) {
    return;
  }
  if (props.customIndex) {
    emit('toggleList', props.customIndex);
  } else {
    isListVisble.value = !isListVisble.value;
  }
};

/**
 * method for closing after the selected item
 */
const hideList = () => {
  if (props.customIndex) {
    emit('toggleList', props.customIndex);
  } else {
    isListVisble.value = false;
  }
};

const oneListVisible = computed(() => {
  if (props.customIndex) {
    return props.isSelectionListVisible;
  } else {
    return isListVisble.value;
  }
});

const selectOptions = computed((): { [key: string]: string | number } => {
  if (Array.isArray(props.options)) {
    const parsedOptions: { [key: string]: string | number } = {};
    props.options.forEach((value: string | number, index: number) => {
      parsedOptions[String(index)] = value;
    });
    return parsedOptions;
  } else {
    return props.options;
  }
});

/**
 * getter for placeholder show or an empty string
 */
const placeholderValue = computed((): string => {
  return props.placeholder || '';
});

/**
 * getter for return index of selected option
 *
 * @returns selected optionindex number if exist
 * @returns null if no selected data
 *  */
const selectedOptionIndex = computed((): string | null => {
  // console.debug('index is >>>>>> ', this.index);
  if (index.value === null) {
    if (props.defaultIndex >= 0) {
      return String(props.defaultIndex);
    } else if (props.defaultValue) {
      return getIndexByValue(props.defaultValue);
    } else {
      return null;
    }
  } else {
    return String(index.value);
  }
});

/**
 * getter for return value of option
 *
 * @returns selected option if exist or selected
 * @returns empty string if not selected or not exist
 *  */
const selectedOptionValue = computed((): string => {
  if (selectedOptionIndex.value === null || !selectOptions.value[selectedOptionIndex.value]) {
    return '';
  } else {
    return String(selectOptions.value[selectedOptionIndex.value]);
  }
});

/**
 *  method for selection and close the list
 *
 * @param optionIndex - parameters for assigning a value
 */
const optionSelect = (optionIndex: any) => {
  index.value = optionIndex;
  hideList();
  onChange({
    index: selectedOptionIndex.value,
    value: selectedOptionValue.value,
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", {
        "data-name": "dropdown_name",
        class: _normalizeClass(["form-dropdown__name", {
          active: oneListVisible.value,
          placeholderActive: !selectedOptionValue.value,
          errored: _ctx.isError,
        }]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleList()))
      }, [
        _createTextVNode(_toDisplayString(selectedOptionValue.value || placeholderValue.value) + " ", 1),
        _cache[2] || (_cache[2] = _createElementVNode("svg", {
          class: "form-dropdown__icon",
          width: "16",
          height: "9",
          viewBox: "0 0 16 9",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M2.34317 0L0.928955 1.41421L8.00001 8.48531L15.0711 1.41424L13.6569 2.90871e-05L8.00003 5.65686L2.34317 0Z",
            fill: "#C6A9E3",
            "fill-opacity": "0.65"
          })
        ], -1))
      ], 2),
      _createElementVNode("ul", {
        class: _normalizeClass(["form-dropdown__list", { selectShow: oneListVisible.value }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("li", {
            key: i,
            class: _normalizeClass(["form-dropdown__item", { selected: i == selectedOptionIndex.value }]),
            onClick: ($event: any) => (optionSelect(i))
          }, _toDisplayString(option), 11, _hoisted_2))
        }), 128))
      ], 2)
    ], 512), [
      [_vShow, isDesktop.value]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createElementVNode("select", {
        class: "select",
        value: selectedOptionIndex.value,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (optionSelect($event.target.value)))
      }, [
        _createElementVNode("option", _hoisted_4, _toDisplayString(placeholderValue.value), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
          return (_openBlock(), _createElementBlock("option", {
            key: i,
            value: i
          }, _toDisplayString(option), 9, _hoisted_5))
        }), 128))
      ], 40, _hoisted_3)
    ], 512), [
      [_vShow, isMobile.value || isTablet.value]
    ])
  ]))
}
}

})