import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bitRadioButtonLabel" }
const _hoisted_2 = ["value", "checked", "name", "gtmid", "disabled"]
const _hoisted_3 = { class: "bitRadioButtonLabelIcon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bitRadioButton", [
      _ctx.variant,
      {
        bitRadioButtonDisabled: _ctx.disabled,
        bitRadioButtonError: _ctx.isError,
        bitRadioButtonChecked: _ctx.modelValue === _ctx.radioValue,
      },
    ]])
  }, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("input", {
        type: "radio",
        value: _ctx.radioValue,
        checked: _ctx.modelValue === _ctx.radioValue,
        name: _ctx.formFieldName,
        gtmid: _ctx.gtmid,
        class: "bitRadioButtonInput",
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, null, 40, _hoisted_2),
      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "bitRadioButtonCheck" }, null, -1)),
      _createTextVNode(" " + _toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "prependIcon")
      ])
    ])
  ], 2))
}