import axios from 'axios';
const PIXEL_URL = 'https://pixel-us.7-ubi.com/pixel';

const mapEventToken = {
  visit: '48ygap',
  regfinished: 'dg1eh2',
} as const;

interface IUbidexSendOptions {
  event: keyof typeof mapEventToken;
  userId?: number;
  currency?: string;
  stag: string;
}

export const useUbidex = () => {
  const sendEvent = async (options: IUbidexSendOptions) => {
    if (!window.location.host.includes('7bit')) {
      return;
    }

    const userId = options.userId;
    const stag = options.stag?.split('_')[0];

    const searchParams = new URLSearchParams();
    searchParams.append('auth', mapEventToken[options.event]);
    searchParams.append('event', options.event);

    if (userId) {
      searchParams.append('uid', userId.toString());
    }

    if (options.currency) {
      searchParams.append('cur', options.currency === 'DOG' ? 'DOGE' : options.currency);
    }

    searchParams.append('affid', stag.toString());
    searchParams.append('site', window.location.host);
    searchParams.append('ln', navigator.language);

    await axios.get(`${PIXEL_URL}?${searchParams.toString()}`);
  };

  return {
    sendEvent,
  };
};
