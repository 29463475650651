import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { SystemHostsStrapiInterface } from '~/types/strapi/systemHosts.strapi.interface';

export const useStoreSystemHosts = defineStore('systemHosts', {
  state: () => ({
    host: null as SystemHostsStrapiInterface | null,
  }),
  getters: {
    regional(state) {
      return state.host?.regionalVisibleDomain || '7bitcasino.com';
    },
    geo(state) {
      return state.host?.geoHost || 'country.7bitcasino13.com';
    },
    redirectHost(state) {
      const languageStore = useStoreLanguage();
      const langPrefix = languageStore.langPrefix;

      return `${state.host?.redirectHost || '7bitcasino.com'}${langPrefix}`;
    },
    gameImages(state) {
      return state.host?.gameImagesHost || '7bitcdn.com';
    },
    cms(state) {
      return state.host?.cmsHost || 'dinamic-content.7bitcasino.com';
    },
    ssHost(state) {
      return state.host?.ssHost || null;
    },
    isCurentHostPromo(state) {
      return state.host?.hostType === 'promo';
    },
    isCurentHostCasino(state) {
      return state.host?.hostType === 'casino';
    },
  },
  actions: {
    SAVE_HOSTS_FROM_STRAPI(data: SystemHostsStrapiInterface[]) {
      if (data[0]) {
        this.host = data[0];
      }
    },
  },
});
