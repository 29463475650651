import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"
import _imports_0 from 'assets/images/common/ico_bonus_code_border.svg'
import _imports_1 from 'assets/images/common/ico_copy_grey.svg'


const _hoisted_1 = { class: "modern2LionContent modern2LionNeonContent" }
const _hoisted_2 = {
  key: 0,
  class: "modalConfirmCloseLion modal-overlay"
}
const _hoisted_3 = { class: "modalConfirmCloseLionContent" }
const _hoisted_4 = { class: "modalConfirmCloseLionHeading" }
const _hoisted_5 = {
  key: 1,
  class: "modalLoginLion modal-overlay"
}
const _hoisted_6 = {
  key: 2,
  class: "modalLoginLion modal-overlay"
}
const _hoisted_7 = { class: "modern2LionBanner" }
const _hoisted_8 = { class: "modern2LionBannerContent" }
const _hoisted_9 = { class: "modern2LionLogos" }
const _hoisted_10 = ["href", "gtmid"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["href"]
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "modern2LionBannerHolder" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "modern2LionBannerCodeButtonContent" }
const _hoisted_18 = { class: "modern2LionBonusCodeHolder" }
const _hoisted_19 = { class: "modern2LionBonusCodeText" }
const _hoisted_20 = { class: "modern2LionBonusCodeButton" }
const _hoisted_21 = {
  key: 0,
  class: "modern2LionBonusCodeButtonCopied"
}
const _hoisted_22 = { class: "modern2LionActionButtonHolder" }
const _hoisted_23 = { class: "modern2LionTopPartnersLines" }
const _hoisted_24 = { class: "modern2LionTopPartnersLine modern2LionTopPartnersLineReversed" }
const _hoisted_25 = { class: "modern2LionTopPartnersLineContent" }
const _hoisted_26 = ["src", "alt"]
const _hoisted_27 = {
  class: "modern2LionTopPartnersLineContent",
  "aria-hidden": "true"
}
const _hoisted_28 = ["src", "alt"]
const _hoisted_29 = { class: "modern2LionTopPartnersLine" }
const _hoisted_30 = { class: "modern2LionTopPartnersLineContent" }
const _hoisted_31 = ["src", "alt"]
const _hoisted_32 = {
  class: "modern2LionTopPartnersLineContent",
  "aria-hidden": "true"
}
const _hoisted_33 = ["src", "alt"]
const _hoisted_34 = { class: "modern2LionStepsContainer" }
const _hoisted_35 = { class: "modern2LionSteper" }
const _hoisted_36 = { class: "modern2LionSteperBlock modern2LionSteperBlockOne" }
const _hoisted_37 = {
  class: "modern2LionSteperArrowTriple",
  alt: ""
}
const _hoisted_38 = {
  class: "modern2LionSteperArrowSingle",
  alt: ""
}
const _hoisted_39 = { class: "modern2LionSteperBlock modern2LionSteperBlockTwo" }
const _hoisted_40 = {
  class: "modern2LionSteperArrowTriple",
  alt: ""
}
const _hoisted_41 = {
  class: "modern2LionSteperArrowSingle",
  alt: ""
}
const _hoisted_42 = { class: "modern2LionSteperBlock modern2LionSteperBlockThree" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_BitButton = _resolveComponent("BitButton")!
  const _component_BitLinkButton = _resolveComponent("BitLinkButton")!
  const _component_ModalSignupBonusHubNeon = _resolveComponent("ModalSignupBonusHubNeon")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_ModalTerms = _resolveComponent("ModalTerms")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isConfirmCloseShowing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.storeLandingComponents.signUpCloseConfirm.title), 1),
            _createVNode(_component_Markdown, {
              "auto-link-parse": false,
              text: `${_ctx.storeLandingComponents.signUpCloseConfirm.textBeforeVariable} ${_ctx.secondTitle} ${_ctx.storeLandingComponents.signUpCloseConfirm.textAfterVariable}`,
              class: _normalizeClass(["modalConfirmCloseLionText", { smallSize: _ctx.secondTitle.length > 16 }])
            }, null, 8, ["text", "class"]),
            _createVNode(_component_BitButton, {
              onClick: _withModifiers(_ctx.hideConfirmClose, ["prevent"]),
              type: "secondary",
              "is-fluid-width": ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.storeLandingComponents.signUpCloseConfirm.btnBackToSignup), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_BitLinkButton, {
              class: "modalConfirmCloseLionCloseBtn",
              onClick: _withModifiers(_ctx.closeModalConfirmed, ["prevent"])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.storeLandingComponents.signUpCloseConfirm.btnClose), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.show === 'lion')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_Transition, {
            duration: { enter: 500, leave: 200 },
            name: "globalModalAnimation"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ModalComponent, {
                modal: "signup",
                isWithCloseConfirm: "",
                onConfirmClose: _ctx.showConfirmClose
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ModalSignupBonusHubNeon, {
                    "first-title": _ctx.secondTitle,
                    "second-title": _ctx.template.thirdTitle,
                    "step-title": _ctx.template.stepTitle,
                    "title-highlight": _ctx.template.signUpHighlightText,
                    "modal-redirect-path": _ctx.modalRedirectPath,
                    onChangeCurrency: _ctx.onChangeCurrency,
                    onClose: _ctx.showConfirmClose,
                    onStepChanged: _ctx.onStepChanged
                  }, null, 8, ["first-title", "second-title", "step-title", "title-highlight", "modal-redirect-path", "onChangeCurrency", "onClose", "onStepChanged"])
                ]),
                _: 1
              }, 8, ["onConfirmClose"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.show === 'terms')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_Transition, {
            duration: { enter: 500, leave: 200 },
            name: "globalModalAnimation"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ModalComponent, { modal: "terms" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ModalTerms, {
                    "terms-rules-text": _ctx.template.termsRulesText,
                    title: _ctx.template.termsTitle,
                    onClose: _ctx.closeModal
                  }, null, 8, ["terms-rules-text", "title", "onClose"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("a", {
            href: _ctx.template.logoHref,
            class: "modern2LionLogo",
            gtmid: `7bitMain${_ctx.abbr}Promo`
          }, [
            _createElementVNode("img", {
              src: _ctx.template.logo.url,
              alt: "logoLion"
            }, null, 8, _hoisted_11)
          ], 8, _hoisted_10),
          (_ctx.template.partnersLogoTop)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.template.logoHrefPartner,
                rel: "nofollow",
                class: "modern2LionLogo"
              }, [
                _createElementVNode("img", {
                  src: _ctx.template.partnersLogoTop.url,
                  alt: "logoLion"
                }, null, 8, _hoisted_13)
              ], 8, _hoisted_12))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _mergeProps({ class: "modern2LionBannerBlock" }, _ctx.storePlatform.dataPlatformAttr), [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("img", {
              class: "modern2LionBannerBlockBg modern2LionBannerBlockBgDesk",
              src: _ctx.template.bannerDesktop.url,
              alt: ""
            }, null, 8, _hoisted_15),
            _createElementVNode("img", {
              class: "modern2LionBannerBlockBg modern2LionBannerBlockBgMob",
              src: _ctx.template.bannerMobile.url,
              alt: ""
            }, null, 8, _hoisted_16)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["modern2LionBannerText", _ctx.template.bannerTextPosition])
          }, [
            _createVNode(_component_Markdown, {
              "auto-link-parse": false,
              text: _ctx.template.firstTitle,
              class: "modern2LionTopTitle"
            }, null, 8, ["text"]),
            _createVNode(_component_Markdown, {
              "auto-link-parse": false,
              text: _ctx.secondTitle,
              class: _normalizeClass(["modern2LionTitle", { smallSize: _ctx.secondTitle.length > 16 }])
            }, null, 8, ["text", "class"]),
            _createVNode(_component_Markdown, {
              "auto-link-parse": false,
              text: _ctx.template.thirdTitle,
              class: "modern2LionText"
            }, null, 8, ["text"]),
            _createElementVNode("div", _hoisted_17, [
              (_ctx.template.bonusCode)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["modern2LionBonusCode", { copied: _ctx.isCopied }])
                  }, [
                    _cache[3] || (_cache[3] = _createElementVNode("img", {
                      class: "modern2LionBonusCodeBorder modern2LionBonusCodeBorderTop",
                      src: _imports_0,
                      alt: "codeCode"
                    }, null, -1)),
                    _cache[4] || (_cache[4] = _createElementVNode("img", {
                      class: "modern2LionBonusCodeBorder modern2LionBonusCodeBorderBot",
                      src: _imports_0,
                      alt: "codeCode"
                    }, null, -1)),
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.template.bonusCodeText), 1),
                      _createElementVNode("div", {
                        class: "modern2LionBonusCodeBlock",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.template.bonusCode)))
                      }, [
                        _createTextVNode(_toDisplayString(_ctx.template.bonusCode) + " ", 1),
                        _createElementVNode("span", _hoisted_20, [
                          (_ctx.isCopied)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.template.bonusCodeTextCopied), 1))
                            : _createCommentVNode("", true),
                          _cache[2] || (_cache[2] = _createElementVNode("img", {
                            class: "modern2LionBonusCodeImg",
                            src: _imports_1,
                            alt: "codeCode"
                          }, null, -1))
                        ])
                      ])
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_BitButton, {
                  onClick: _withModifiers(_ctx.registerHandler, ["prevent"]),
                  "is-fluid-width": "",
                  "no-margin": "",
                  variant: "landing-lg",
                  gtmid: `RegStartMain${_ctx.abbr}Promo`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.registerTitle), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "gtmid"]),
                _createElementVNode("div", {
                  class: "modern2LionTerms",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openTermsModal && _ctx.openTermsModal(...args)))
                }, _toDisplayString(_ctx.template.termsTitle), 1)
              ])
            ])
          ], 2)
        ], 16)
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("ul", _hoisted_25, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFooter.partnersLink, (partner, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "modern2LionTopPartnersLineItem",
              key: i
            }, [
              _createElementVNode("img", {
                src: partner.img_src.url,
                alt: partner.alt || ''
              }, null, 8, _hoisted_26)
            ]))
          }), 128))
        ]),
        _createElementVNode("ul", _hoisted_27, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storeFooter.partnersLink, (partner, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "modern2LionTopPartnersLineItem",
              key: i
            }, [
              _createElementVNode("img", {
                src: partner.img_src.url,
                alt: partner.alt || ''
              }, null, 8, _hoisted_28)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("ul", _hoisted_30, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equalPaymentMethodsLink, (payment, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "modern2LionTopPartnersLineItemSmall",
              key: i
            }, [
              _createElementVNode("img", {
                src: payment.img_src.url,
                alt: payment.alt || ''
              }, null, 8, _hoisted_31)
            ]))
          }), 128))
        ]),
        _createElementVNode("ul", _hoisted_32, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.equalPaymentMethodsLink, (payment, i) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "modern2LionTopPartnersLineItemSmall",
              key: i
            }, [
              _createElementVNode("img", {
                src: payment.img_src.url,
                alt: payment.alt || ''
              }, null, 8, _hoisted_33)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_34, [
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, [
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "modern2LionSteperBlockNumber" }, "1", -1)),
          _createVNode(_component_Markdown, {
            "auto-link-parse": false,
            text: _ctx.template.firstStep,
            class: "modern2LionSteperCenter"
          }, null, 8, ["text"])
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_37, null, 512), [
          [_directive_lazy, require('~/assets/images/common/ico_arrows_steps.svg')]
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_38, null, 512), [
          [_directive_lazy, require('~/assets/images/common/ico_arrow_steps_single.svg')]
        ]),
        _createElementVNode("div", _hoisted_39, [
          _cache[6] || (_cache[6] = _createElementVNode("p", { class: "modern2LionSteperBlockNumber" }, "2", -1)),
          _createVNode(_component_Markdown, {
            "auto-link-parse": false,
            text: _ctx.template.secondStep,
            class: "modern2LionSteperCenter"
          }, null, 8, ["text"])
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_40, null, 512), [
          [_directive_lazy, require('~/assets/images/common/ico_arrows_steps.svg')]
        ]),
        _withDirectives(_createElementVNode("img", _hoisted_41, null, 512), [
          [_directive_lazy, require('~/assets/images/common/ico_arrow_steps_single.svg')]
        ]),
        _createElementVNode("div", _hoisted_42, [
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "modern2LionSteperBlockNumber" }, "3", -1)),
          _createVNode(_component_Markdown, {
            "auto-link-parse": false,
            text: _ctx.template.thirdStep,
            class: "modern2LionSteperCenter"
          }, null, 8, ["text"])
        ])
      ])
    ])
  ]))
}