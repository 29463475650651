<template>
  <div
    v-if="isLoaded && currentPage"
    class="modern2LionContainer modern2LionWrapper"
    :data-theme="theme"
  >
    <Modern2LionMainPage />
    <Modern2LionFooter :template="currentPage.template" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { onMounted } from 'vue';
import Modern2LionFooter from '@/components/partials/modern2LionFooter.vue';
import Modern2LionMainPage from '@/components/partials/modern2LionMainPage.vue';
import { useStoreLanding } from '@/store/strapi/landing';

export default defineComponent({
  components: {
    Modern2LionMainPage,
    Modern2LionFooter,
  },
  setup() {
    const storeLanding = useStoreLanding();

    onMounted(() => {
      document.body.classList.add('modern2LionLand');
    });
    return {
      theme: computed(() => storeLanding.currentPage?.theme),
      currentPage: computed(() => storeLanding.currentPage),
      isLoaded: computed(() => storeLanding.isLoaded),
    };
  },
});
</script>

<style lang="scss" src="./modern2LionPromo.scss"></style>
