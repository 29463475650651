import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, createBlock as _createBlock, vModelRadio as _vModelRadio, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from 'assets/images/landing/7bit_logo_small.svg'


const _hoisted_1 = { class: "modalContentSignup" }
const _hoisted_2 = { class: "modalContentSignupHeader" }
const _hoisted_3 = { class: "signUpLogoBlock" }
const _hoisted_4 = ["href", "gtmid"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["href", "gtmid"]
const _hoisted_7 = { class: "modalTitle" }
const _hoisted_8 = { class: "modalBanner" }
const _hoisted_9 = { class: "modalLoginLionText" }
const _hoisted_10 = { class: "modalLoginLionBanner" }
const _hoisted_11 = { class: "modalLoginLionBannerSecond" }
const _hoisted_12 = ["src", "alt", "width", "height"]
const _hoisted_13 = { class: "modalInside" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = {
  key: 0,
  class: "errorText"
}
const _hoisted_16 = {
  key: 1,
  class: "errorText"
}
const _hoisted_17 = ["type"]
const _hoisted_18 = {
  key: 0,
  class: "errorText"
}
const _hoisted_19 = {
  key: 1,
  class: "errorText"
}
const _hoisted_20 = {
  key: 0,
  class: "errorText"
}
const _hoisted_21 = {
  for: "agree",
  class: "inputFormCheckboxLabel"
}
const _hoisted_22 = ["href"]
const _hoisted_23 = {
  key: 0,
  class: "errorText errorTextAlign"
}
const _hoisted_24 = { class: "formGroup formPromoGroup formCheckbox" }
const _hoisted_25 = { class: "formPromoCheckbox" }
const _hoisted_26 = ["title"]
const _hoisted_27 = {
  for: "receivePromos",
  class: "inputFormCheckboxLabel"
}
const _hoisted_28 = { class: "infoTooltip" }
const _hoisted_29 = { class: "infoTooltipItem" }
const _hoisted_30 = { class: "formGroup formPromoGroup formCheckbox" }
const _hoisted_31 = { class: "formPromoCheckbox" }
const _hoisted_32 = ["title"]
const _hoisted_33 = {
  for: "agreed_to_partner_promotions",
  class: "inputFormCheckboxLabel inputFormCheckboxLabelFlex"
}
const _hoisted_34 = { class: "infoTooltip" }
const _hoisted_35 = { class: "infoTooltipItem" }
const _hoisted_36 = { class: "formGroup" }
const _hoisted_37 = ["disabled"]
const _hoisted_38 = {
  key: 0,
  class: "errorText"
}
const _hoisted_39 = { class: "formGroup" }
const _hoisted_40 = { class: "formGroup modalTwoBlock" }
const _hoisted_41 = ["placeholder", "title"]
const _hoisted_42 = {
  key: 0,
  class: "errorText"
}
const _hoisted_43 = ["placeholder", "title"]
const _hoisted_44 = {
  key: 0,
  class: "errorText"
}
const _hoisted_45 = {
  key: 1,
  class: "errorText"
}
const _hoisted_46 = ["placeholder", "title"]
const _hoisted_47 = {
  key: 0,
  class: "errorText"
}
const _hoisted_48 = ["placeholder"]
const _hoisted_49 = {
  key: 0,
  class: "errorText"
}
const _hoisted_50 = {
  key: 1,
  class: "errorText"
}
const _hoisted_51 = ["placeholder"]
const _hoisted_52 = {
  key: 0,
  class: "errorText"
}
const _hoisted_53 = {
  key: 1,
  class: "errorText"
}
const _hoisted_54 = { class: "modalThreeBlock" }
const _hoisted_55 = { class: "modalThreeBlockSelect" }
const _hoisted_56 = { class: "modalThreeBlockSelect" }
const _hoisted_57 = { class: "modalThreeBlockSelect" }
const _hoisted_58 = {
  key: 0,
  class: "errorText"
}
const _hoisted_59 = {
  key: 1,
  class: "errorText"
}
const _hoisted_60 = {
  key: 0,
  class: "errorText"
}
const _hoisted_61 = {
  key: 1,
  class: "errorText"
}
const _hoisted_62 = { class: "formCheckbox" }
const _hoisted_63 = ["placeholder"]
const _hoisted_64 = {
  for: "checkbox1",
  class: "inputFormCheckboxLabel"
}
const _hoisted_65 = { class: "formCheckbox" }
const _hoisted_66 = ["placeholder"]
const _hoisted_67 = {
  for: "checkbox2",
  class: "inputFormCheckboxLabel"
}
const _hoisted_68 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomSelect = _resolveComponent("CustomSelect")!
  const _component_TelephoneInput = _resolveComponent("TelephoneInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.template.logoHref,
          class: "signUpLogo",
          gtmid: `7bitMain${_ctx.abbr}Promo`
        }, [
          _createElementVNode("img", {
            src: _ctx.template.logo.url,
            alt: "logoLion"
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4),
        _createElementVNode("a", {
          href: _ctx.template.logoHref,
          class: "signUpLogo signUpLogoMobile",
          gtmid: `7bitMain${_ctx.abbr}Promo`
        }, _cache[33] || (_cache[33] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "7bit_logo_small"
          }, null, -1)
        ]), 8, _hoisted_6),
        _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.regFormData.header), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.firstTitle), 1),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.secondTitle), 1)
      ]),
      _createElementVNode("img", {
        ref: "banner",
        src: _ctx.url,
        class: "modalBannerSignUp",
        alt: _ctx.nameImg,
        width: _ctx.width,
        height: _ctx.height
      }, null, 8, _hoisted_12)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: _normalizeClass(["modalFirstStep", { 'modal-hidden-step': _ctx.step != 1 }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.regErrors.email }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "email",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.regForm.email) = $event)),
            type: "email",
            name: "email",
            class: _normalizeClass(["formInput", {
              formInputError: _ctx.regFormValidator.email.$errors.length,
              formInputSuccess:
                !_ctx.regErrors.email &&
                !_ctx.regFormValidator.email.$errors.length &&
                _ctx.regFormValidator.email.$anyDirty,
            }]),
            autocomplete: "email",
            placeholder: _ctx.regFormData.email_title,
            onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["prevent"]), ["space"])),
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.touchHeap.touch(_ctx.regFormValidator, 'email')))
          }, null, 42, _hoisted_14), [
            [_vModelText, _ctx.regForm.email]
          ]),
          (_ctx.regErrors.email)
            ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.regErrors.email), 1))
            : _createCommentVNode("", true),
          (_ctx.regFormValidator.email.$errors[0] && !_ctx.regErrors.email)
            ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.regFormValidator.email.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup formPassword", { formGroupError: !!_ctx.regErrors.password }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "password",
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.regForm.password) = $event)),
            type: _ctx.passwordType,
            name: "password",
            class: _normalizeClass(["formInput", {
              formInputError: _ctx.regFormValidator.password.$errors.length,
              [_ctx.passwordWeakness]:
                !_ctx.regFormValidator.password.$errors.length &&
                !_ctx.regErrors.password &&
                _ctx.passwordWeakness,
            }]),
            autocomplete: "current-password",
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.touchHeap.touch(_ctx.regFormValidator, 'password')))
          }, null, 42, _hoisted_17), [
            [_vModelDynamic, _ctx.regForm.password]
          ]),
          _createElementVNode("span", {
            class: "formPasswordIcon",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showPasswordToggle()))
          }, _cache[34] || (_cache[34] = [
            _createElementVNode("svg", {
              width: "13",
              height: "8",
              viewBox: "0 0 13 8",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M8.1205 3.99999C8.1205 4.79528 7.39498 5.43999 6.5 5.43999C5.60503 5.43999 4.8795 4.79528 4.8795 3.99999C4.8795 3.2047 5.60503 2.55999 6.5 2.55999C7.39498 2.55999 8.1205 3.2047 8.1205 3.99999Z",
                fill: "#8190DD"
              }),
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M0.108033 3.57333C1.25439 1.46133 3.68513 0 6.5 0C9.31487 0 11.7456 1.46133 12.892 3.57333C13.036 3.84 13.036 4.16 12.892 4.42667C11.7456 6.53867 9.31487 8 6.5 8C3.68513 8 1.25439 6.53867 0.108033 4.42667C-0.0360111 4.16 -0.0360111 3.84 0.108033 3.57333ZM3.79917 4C3.79917 5.32267 5.01154 6.4 6.5 6.4C7.98846 6.4 9.20083 5.32267 9.20083 4C9.20083 2.67733 7.98846 1.6 6.5 1.6C5.01154 1.6 3.79917 2.67733 3.79917 4Z",
                fill: "#8190DD"
              })
            ], -1)
          ])),
          _createElementVNode("span", {
            class: "formPasswordIcon formPasswordIconShow",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showPasswordToggle()))
          }, _cache[35] || (_cache[35] = [
            _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                d: "M10.1172 17.0654C10.7231 17.201 11.3531 17.2726 12 17.2726C15.6364 17.2726 18.7418 15.0108 20 11.8181C19.343 10.1509 18.1823 8.7375 16.7053 7.76749L15.1377 9.97983C15.4546 10.5193 15.6364 11.1476 15.6364 11.8181C15.6364 13.8253 14.0073 15.4544 12 15.4544C11.7626 15.4544 11.5304 15.4316 11.3056 15.3881L10.1172 17.0654ZM12.3041 13.979C13.3668 13.8315 14.1818 12.9221 14.1818 11.8181C14.1818 11.6709 14.1673 11.5272 14.1397 11.3883L12.3041 13.979ZM11.6889 9.65815L9.8589 12.2408C9.83218 12.1041 9.81818 11.9628 9.81818 11.8181C9.81818 10.7165 10.6296 9.80861 11.6889 9.65815ZM12.6888 8.24695C12.4657 8.20413 12.2355 8.18171 12 8.18171C9.99273 8.18171 8.36364 9.8108 8.36364 11.8181C8.36364 12.4865 8.5443 13.113 8.85944 13.6514L7.29042 15.8658C5.81547 14.8959 4.65637 13.4837 4 11.8181C5.25818 8.62534 8.36364 6.36353 12 6.36353C12.6449 6.36353 13.2732 6.43467 13.8773 6.56953L12.6888 8.24695Z",
                fill: "#8190DD"
              }),
              _createElementVNode("rect", {
                x: "6.78003",
                y: "18.2397",
                width: "18",
                height: "1",
                transform: "rotate(-54.6804 6.78003 18.2397)",
                fill: "#8190DD"
              })
            ], -1)
          ])),
          _createElementVNode("label", {
            class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.regForm.password }])
          }, _toDisplayString(_ctx.regFormData.password_title), 3),
          (_ctx.regErrors.password)
            ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_ctx.regErrors.password), 1))
            : _createCommentVNode("", true),
          (_ctx.regFormValidator.password.$errors[0] && !_ctx.regErrors.password)
            ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_ctx.regFormValidator.password.$errors[0].$message), 1))
            : _createCommentVNode("", true),
          (
              !_ctx.regFormValidator.password.$errors[0] &&
              !_ctx.regErrors.password &&
              _ctx.passwordWeaknsssMessage
            )
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass(["errorText", {
              [_ctx.passwordWeakness]: _ctx.passwordWeakness,
            }])
              }, _toDisplayString(_ctx.passwordWeaknsssMessage), 3))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.regErrors.currency }])
        }, [
          _createVNode(_component_CustomSelect, {
            modelValue: _ctx.regForm.currency,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.regForm.currency) = $event)),
            class: "formGroupSelect",
            options: _ctx.activeCurrenciesOptions
          }, null, 8, ["modelValue", "options"]),
          _createElementVNode("label", {
            class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.regForm.currency }])
          }, _toDisplayString(_ctx.regFormData.currency_title), 3),
          (_ctx.regErrors.currency)
            ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.regErrors.currency), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup formCheckbox", {
            formGroupError:
              !!_ctx.regErrors.terms_acceptance || !!_ctx.regFormValidator.terms_acceptance.$errors[0],
          }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "agree",
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.regForm.terms_acceptance) = $event)),
            class: "inputFormCheckbox",
            type: "checkbox",
            name: "agree"
          }, null, 512), [
            [_vModelCheckbox, _ctx.regForm.terms_acceptance]
          ]),
          _createElementVNode("label", _hoisted_21, [
            _createTextVNode(_toDisplayString(_ctx.regFormData.terms_title) + " ", 1),
            _createElementVNode("a", {
              href: _ctx.termsLink,
              class: "inputFormCheckboxLink"
            }, _toDisplayString(_ctx.regFormData.terms_link_title), 9, _hoisted_22)
          ]),
          (!!_ctx.regErrors.terms_acceptance || !!_ctx.regFormValidator.terms_acceptance.$errors[0])
            ? (_openBlock(), _createElementBlock("span", _hoisted_23, _cache[36] || (_cache[36] = [
                _createStaticVNode("<svg width=\"15\" height=\"14\" viewBox=\"0 0 15 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><g clip-path=\"url(#clip0_409_25243)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 0C3.634 0 0.5 3.134 0.5 7C0.5 10.866 3.634 14 7.5 14C11.366 14 14.5 10.866 14.5 7C14.4958 3.13573 11.3643 0.00418359 7.5 0ZM7.5 12.25C4.6005 12.25 2.25 9.8995 2.25 7C2.25 4.1005 4.6005 1.75 7.5 1.75C10.3995 1.75 12.75 4.1005 12.75 7C12.7468 9.89816 10.3982 12.2468 7.5 12.25ZM7.5 8.80311C7.98325 8.80311 8.375 8.41136 8.375 7.92811V3.84479C8.375 3.36154 7.98325 2.96979 7.5 2.96979C7.01675 2.96979 6.625 3.36154 6.625 3.84479V7.92811C6.625 8.41136 7.01675 8.80311 7.5 8.80311ZM8.37792 10.2083C8.37792 10.6916 7.98617 11.0833 7.50292 11.0833C7.01967 11.0833 6.62792 10.6916 6.62792 10.2083C6.62792 9.72508 7.01967 9.33333 7.50292 9.33333C7.98617 9.33333 8.37792 9.72508 8.37792 10.2083Z\" fill=\"#ED3C3C\"></path></g><defs><clipPath id=\"clip0_409_25243\"><rect width=\"14\" height=\"14\" fill=\"white\" transform=\"translate(0.5)\"></rect></clipPath></defs></svg> must be completed ", 2)
              ])))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _withDirectives(_createElementVNode("input", {
              id: "receivePromos",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.profileForm.receive_promos) = $event)),
              type: "checkbox",
              name: "receivePromos",
              class: "inputFormCheckbox",
              title: _ctx.profileFormData.receive_promos_title
            }, null, 8, _hoisted_26), [
              [_vModelCheckbox, _ctx.profileForm.receive_promos]
            ]),
            _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.profileFormData.receive_promos_title), 1)
          ]),
          _createElementVNode("div", _hoisted_28, [
            _cache[37] || (_cache[37] = _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z",
                fill: "white"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.storeAuth.promoTooltipText), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _withDirectives(_createElementVNode("input", {
              id: "agreed_to_partner_promotions",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.profileForm.agreed_to_partner_promotions) = $event)),
              type: "checkbox",
              name: "agreed_to_partner_promotions",
              class: "inputFormCheckbox",
              title: _ctx.storeAuth.crossSaleTitle
            }, null, 8, _hoisted_32), [
              [_vModelCheckbox, _ctx.profileForm.agreed_to_partner_promotions]
            ]),
            _createElementVNode("label", _hoisted_33, [
              _createElementVNode("span", null, _toDisplayString(_ctx.storeAuth.crossSaleTitle), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_34, [
            _cache[38] || (_cache[38] = _createElementVNode("svg", {
              width: "24",
              height: "24",
              viewBox: "0 0 24 24",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }, [
              _createElementVNode("path", {
                d: "M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z",
                fill: "white"
              })
            ], -1)),
            _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.storeAuth.partnerpromotionsTooltipText), 1)
          ])
        ]),
        _cache[40] || (_cache[40] = _createElementVNode("div", { id: "captcha_v2" }, null, -1)),
        _createElementVNode("div", _hoisted_36, [
          _createElementVNode("button", {
            class: _normalizeClass(["formGroupButton loader", { margined: _ctx.isGoogleRegTemplate }]),
            name: "sign-up",
            disabled: _ctx.waitPlease,
            onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.nextStep()), ["prevent"]))
          }, [
            _createTextVNode(_toDisplayString(_ctx.regFormData.register_button_title) + " ", 1),
            _cache[39] || (_cache[39] = _createElementVNode("span", { class: "icon-loader" }, null, -1))
          ], 10, _hoisted_37)
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["modalTwoStep", { 'modal-hidden-step': _ctx.step != 2 }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.country }])
        }, [
          _createVNode(_component_CustomSelect, {
            modelValue: _ctx.profileForm.country,
            "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.profileForm.country) = $event)),
            options: _ctx.countriesList,
            name: "country",
            class: "formGroupSelect",
            title: _ctx.profileFormData.country_title,
            "is-error": _ctx.profileFormFirstValidator.country.$error
          }, null, 8, ["modelValue", "options", "title", "is-error"]),
          _createElementVNode("label", {
            class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.profileForm.country }])
          }, _toDisplayString(_ctx.profileFormData.country_title), 3),
          (_ctx.profileFormFirstValidator.country.$errors[0])
            ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.profileFormFirstValidator.country.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_39, [
          (_ctx.isStateFieldShown)
            ? (_openBlock(), _createBlock(_component_CustomSelect, {
                key: 0,
                modelValue: _ctx.profileForm.state,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.profileForm.state) = $event)),
                options: _ctx.statesList,
                name: "state",
                class: "formGroupSelect",
                title: 'State',
                "is-error": _ctx.profileFormFirstValidator.country.$error
              }, null, 8, ["modelValue", "options", "is-error"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", {
            class: _normalizeClass(["formGroupFullWidth", { formGroupError: !!_ctx.profileErrors.city }])
          }, [
            _withDirectives(_createElementVNode("input", {
              id: "city",
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.profileForm.city) = $event)),
              type: "text",
              name: "city",
              class: _normalizeClass(["formInput", {
                formInputError: _ctx.profileFormFirstValidator.city.$errors.length,
                formInputSuccess:
                  !_ctx.profileFormFirstValidator.city.$errors.length &&
                  _ctx.profileFormFirstValidator.city.$anyDirty,
              }]),
              placeholder: _ctx.profileFormData.city_title,
              title: _ctx.profileFormData.city_title,
              onInput: _cache[15] || (_cache[15] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'city')))
            }, null, 42, _hoisted_41), [
              [_vModelText, _ctx.profileForm.city]
            ]),
            (_ctx.profileFormFirstValidator.city.$errors[0])
              ? (_openBlock(), _createElementBlock("span", _hoisted_42, _toDisplayString(_ctx.profileFormFirstValidator.city.$errors[0].$message), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["formGroupFullWidth", { formGroupError: !!_ctx.profileErrors.postal_code }])
          }, [
            _withDirectives(_createElementVNode("input", {
              id: "code",
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.profileForm.postal_code) = $event)),
              type: "text",
              name: "code",
              class: _normalizeClass(["formInput", {
                formInputError:
                  _ctx.profileFormFirstValidator.postal_code.$errors.length ||
                  !!_ctx.profileErrors.postal_code,
                formInputSuccess:
                  !_ctx.profileFormFirstValidator.postal_code.$errors.length &&
                  _ctx.profileFormFirstValidator.postal_code.$anyDirty &&
                  !_ctx.profileErrors.postal_code,
              }]),
              placeholder: _ctx.profileFormData.postal_code_title,
              title: _ctx.profileFormData.postal_code_title,
              onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'postal_code')))
            }, null, 42, _hoisted_43), [
              [_vModelText, _ctx.profileForm.postal_code]
            ]),
            (_ctx.isErrorPostalCodeIncorrect)
              ? (_openBlock(), _createElementBlock("span", _hoisted_44, " postal code correct format is xxxx "))
              : _createCommentVNode("", true),
            (_ctx.profileFormFirstValidator.postal_code.$errors[0])
              ? (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.profileFormFirstValidator.postal_code.$errors[0].$message), 1))
              : _createCommentVNode("", true)
          ], 2)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.address }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "address",
            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.profileForm.address) = $event)),
            type: "text",
            name: "address",
            class: _normalizeClass(["formInput", {
              formInputError: _ctx.profileFormFirstValidator.address.$errors.length,
              formInputSuccess:
                !_ctx.profileFormFirstValidator.address.$errors.length &&
                _ctx.profileFormFirstValidator.address.$anyDirty,
            }]),
            placeholder: _ctx.profileFormData.address_title,
            title: _ctx.profileFormData.address_title,
            onInput: _cache[19] || (_cache[19] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'address')))
          }, null, 42, _hoisted_46), [
            [_vModelText, _ctx.profileForm.address]
          ]),
          (_ctx.profileFormFirstValidator.address.$errors[0])
            ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.profileFormFirstValidator.address.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.first_name }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "name",
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.profileForm.first_name) = $event)),
            type: "text",
            name: "first-name",
            class: _normalizeClass(["formInput", {
              formInputError: _ctx.profileFormFirstValidator.first_name.$errors.length,
              formInputSuccess:
                !_ctx.profileFormFirstValidator.first_name.$errors.length &&
                _ctx.profileFormFirstValidator.first_name.$anyDirty,
            }]),
            placeholder: _ctx.profileFormData.first_name_title,
            onInput: _cache[21] || (_cache[21] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'first_name')))
          }, null, 42, _hoisted_48), [
            [_vModelText, _ctx.profileForm.first_name]
          ]),
          (_ctx.profileErrors.first_name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.profileErrors.first_name), 1))
            : _createCommentVNode("", true),
          (_ctx.profileFormFirstValidator.first_name.$errors[0] && !_ctx.profileErrors.first_name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_50, _toDisplayString(_ctx.profileFormFirstValidator.first_name.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.last_name }])
        }, [
          _withDirectives(_createElementVNode("input", {
            id: "last-name",
            "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.profileForm.last_name) = $event)),
            type: "text",
            name: "last-name",
            class: _normalizeClass(["formInput", {
              formInputError: _ctx.profileFormFirstValidator.last_name.$errors.length,
              formInputSuccess:
                !_ctx.profileFormFirstValidator.last_name.$errors.length &&
                _ctx.profileFormFirstValidator.last_name.$anyDirty,
            }]),
            placeholder: _ctx.profileFormData.last_name_title,
            onInput: _cache[23] || (_cache[23] = ($event: any) => (_ctx.touchHeap.touch(_ctx.profileFormFirstValidator, 'last_name')))
          }, null, 42, _hoisted_51), [
            [_vModelText, _ctx.profileForm.last_name]
          ]),
          (_ctx.profileErrors.last_name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_52, _toDisplayString(_ctx.profileErrors.last_name), 1))
            : _createCommentVNode("", true),
          (_ctx.profileFormFirstValidator.last_name.$errors[0] && !_ctx.profileErrors.last_name)
            ? (_openBlock(), _createElementBlock("span", _hoisted_53, _toDisplayString(_ctx.profileFormFirstValidator.last_name.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup", { formGroupError: !!_ctx.profileErrors.date_of_birth }])
        }, [
          _createElementVNode("span", _hoisted_54, [
            _createElementVNode("div", _hoisted_55, [
              _createVNode(_component_CustomSelect, {
                class: "formGroupSelect",
                modelValue: _ctx.profileForm.year,
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.profileForm.year) = $event)),
                options: _ctx.listYears,
                "is-error": _ctx.profileFormFirstValidator.year.$error,
                "custom-index": 1,
                "is-selection-list-visible": _ctx.openIndexList === 1,
                onToggleList: _ctx.toggle
              }, null, 8, ["modelValue", "options", "is-error", "is-selection-list-visible", "onToggleList"]),
              _createElementVNode("label", {
                class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.profileForm.year }])
              }, _toDisplayString(_ctx.profileFormData.year_title), 3)
            ]),
            _createElementVNode("div", _hoisted_56, [
              _createVNode(_component_CustomSelect, {
                class: "formGroupSelect",
                modelValue: _ctx.profileForm.month,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.profileForm.month) = $event)),
                options: _ctx.listMonths,
                "is-error": _ctx.profileFormFirstValidator.month.$error,
                "custom-index": 2,
                "is-selection-list-visible": _ctx.openIndexList === 2,
                onToggleList: _ctx.toggle
              }, null, 8, ["modelValue", "options", "is-error", "is-selection-list-visible", "onToggleList"]),
              _createElementVNode("label", {
                class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.profileForm.month }])
              }, _toDisplayString(_ctx.profileFormData.month_title), 3)
            ]),
            _createElementVNode("div", _hoisted_57, [
              _createVNode(_component_CustomSelect, {
                class: "formGroupSelect",
                modelValue: _ctx.profileForm.day,
                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.profileForm.day) = $event)),
                options: _ctx.listDays,
                "is-error": _ctx.profileFormFirstValidator.day.$error,
                "custom-index": 3,
                "is-selection-list-visible": _ctx.openIndexList === 3,
                onToggleList: _ctx.toggle
              }, null, 8, ["modelValue", "options", "is-error", "is-selection-list-visible", "onToggleList"]),
              _createElementVNode("label", {
                class: _normalizeClass(["formGroupPlaceholder", { placeholderFocus: _ctx.profileForm.day }])
              }, _toDisplayString(_ctx.profileFormData.day_title), 3)
            ])
          ]),
          (_ctx.profileErrors.date_of_birth)
            ? (_openBlock(), _createElementBlock("span", _hoisted_58, _toDisplayString(_ctx.profileErrors.date_of_birth), 1))
            : _createCommentVNode("", true),
          (
              (_ctx.profileFormFirstValidator.day.$errors[0] ||
                _ctx.profileFormFirstValidator.month.$errors[0] ||
                _ctx.profileFormFirstValidator.year.$errors[0]) &&
              !_ctx.profileErrors.date_of_birth
            )
            ? (_openBlock(), _createElementBlock("span", _hoisted_59, _toDisplayString((_ctx.profileFormFirstValidator.day.$errors[0] &&
                _ctx.profileFormFirstValidator.day.$errors[0].$message) ||
              (_ctx.profileFormFirstValidator.month.$errors[0] &&
                _ctx.profileFormFirstValidator.month.$errors[0].$message) ||
              (_ctx.profileFormFirstValidator.year.$errors[0] &&
                _ctx.profileFormFirstValidator.year.$errors[0].$message)), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup modal-phone", { formGroupError: !!_ctx.profileErrors.mobile_phone }])
        }, [
          _createVNode(_component_TelephoneInput, {
            modelValue: _ctx.profileForm.mobile_phone,
            "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.profileForm.mobile_phone) = $event)),
            "profile-form-data": _ctx.profileFormData,
            "player-country": _ctx.userCountry,
            "is-error": 
              !!_ctx.profileFormFirstValidator.mobile_phone.$errors[0] || !!_ctx.profileErrors.mobile_phone
            ,
            "is-success": 
              !_ctx.profileErrors.mobile_phone &&
              !_ctx.profileFormFirstValidator.mobile_phone.$error &&
              _ctx.profileFormFirstValidator.mobile_phone.$anyDirty
            ,
            onBlur: _cache[28] || (_cache[28] = ($event: any) => (_ctx.profileFormFirstValidator.mobile_phone.$touch())),
            onInput: _cache[29] || (_cache[29] = ($event: any) => (_ctx.clearProfileErrorField('mobile_phone')))
          }, null, 8, ["modelValue", "profile-form-data", "player-country", "is-error", "is-success"]),
          (_ctx.profileErrors.mobile_phone)
            ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(_ctx.profileErrors.mobile_phone), 1))
            : _createCommentVNode("", true),
          (_ctx.profileFormFirstValidator.mobile_phone.$errors[0] && !_ctx.profileErrors.mobile_phone)
            ? (_openBlock(), _createElementBlock("span", _hoisted_61, _toDisplayString(_ctx.profileFormFirstValidator.mobile_phone.$errors[0].$message), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["formGroup modalTwoBlock", {
            formGroupError: !!_ctx.profileFormFirstValidator.gender.$errors[0],
          }])
        }, [
          _createElementVNode("span", _hoisted_62, [
            _withDirectives(_createElementVNode("input", {
              id: "checkbox1",
              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.profileForm.gender) = $event)),
              value: "m",
              type: "radio",
              name: "checkbox1",
              class: "inputFormCheckbox",
              placeholder: _ctx.profileFormData.male_title
            }, null, 8, _hoisted_63), [
              [_vModelRadio, _ctx.profileForm.gender]
            ]),
            _createElementVNode("label", _hoisted_64, _toDisplayString(_ctx.regFormData.label_text_male || 'Male'), 1)
          ]),
          _createElementVNode("span", _hoisted_65, [
            _withDirectives(_createElementVNode("input", {
              id: "checkbox2",
              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.profileForm.gender) = $event)),
              value: "f",
              type: "radio",
              name: "checkbox2",
              class: "inputFormCheckbox",
              placeholder: _ctx.profileFormData.male_title
            }, null, 8, _hoisted_66), [
              [_vModelRadio, _ctx.profileForm.gender]
            ]),
            _createElementVNode("label", _hoisted_67, _toDisplayString(_ctx.regFormData.label_text_female || 'Female'), 1)
          ])
        ], 2),
        _createElementVNode("button", {
          class: "formGroupButton",
          title: _ctx.profileFormData.save_button_title,
          onClick: _cache[32] || (_cache[32] = _withModifiers(($event: any) => (_ctx.nextStep()), ["prevent"]))
        }, [
          _createTextVNode(_toDisplayString(_ctx.profileFormData.save_button_title) + " ", 1),
          _cache[41] || (_cache[41] = _createElementVNode("span", { class: "icon-loader" }, null, -1))
        ], 8, _hoisted_68)
      ], 2)
    ])
  ]))
}