import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type", "gtmid"]
const _hoisted_2 = ["gtmid"]
const _hoisted_3 = ["src", "gtmid"]
const _hoisted_4 = ["gtmid"]
const _hoisted_5 = ["gtmid"]
const _hoisted_6 = ["src", "gtmid"]
const _hoisted_7 = {
  key: 2,
  class: "buttonLoadingicon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.typeButton,
    class: _normalizeClass(["bitButton", _ctx.buttonClasses]),
    gtmid: _ctx.gtmid
  }, [
    (_ctx.appendIcon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "buttonIconLeft",
          gtmid: _ctx.gtmid
        }, [
          _createElementVNode("img", {
            src: require(`~/assets/images/common/${_ctx.appendIcon}`),
            alt: "buttonIcon",
            gtmid: _ctx.gtmid
          }, null, 8, _hoisted_3)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: "buttonTextInside",
      gtmid: _ctx.gtmid
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_4),
    (_ctx.prependIcon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: "buttonIconRight",
          gtmid: _ctx.gtmid
        }, [
          _createElementVNode("img", {
            src: require(`~/assets/images/common/${_ctx.prependIcon}`),
            alt: "buttonIcon",
            gtmid: _ctx.gtmid
          }, null, 8, _hoisted_6)
        ], 8, _hoisted_5))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_7))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}