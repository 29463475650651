<template>
  <div class="modalContentSignup">
    <div class="modalContentSignupHeader">
      <div class="signUpLogoBlock">
        <a :href="template.logoHref" class="signUpLogo" :gtmid="`7bitMain${abbr}Promo`">
          <img :src="template.logo.url" alt="logoLion" />
        </a>
        <a
          :href="template.logoHref"
          class="signUpLogo signUpLogoMobile"
          :gtmid="`7bitMain${abbr}Promo`"
        >
          <img src="~assets/images/landing/7bit_logo_small.svg" alt="7bit_logo_small" />
        </a>
        <p>{{ regFormData.header }}</p>
      </div>
      <BitCloseButton @click="$emit('close')" />
    </div>
    <div class="modalContentSignupHolder">
      <div class="modalBanner">
        <div class="modalLoginLionText">
          <div class="modalLoginLionBannerHighlight">{{ titleHighlight }}</div>
          <div class="modalLoginLionBanner">{{ firstTitle }}</div>
          <div class="modalLoginLionBannerSecond">{{ secondTitle }}</div>
        </div>
        <img
          ref="banner"
          :src="url"
          class="modalBannerSignUp"
          :alt="nameImg"
          :width="width"
          :height="height"
        />
      </div>
      <div class="modalInside">
        <div class="modalFirstStep" :class="{ 'modal-hidden-step': step != 1 }">
          <div class="" :class="{ formGroupError: !!regErrors.email }">
            <BitInput
              v-model="regForm.email"
              type="email"
              form-field-name="email"
              :placeholder="regFormData.email_title"
              :text-label="regFormData.email_title"
              :is-valid="
                !regErrors.email &&
                !regFormValidator.email.$errors.length &&
                regFormValidator.email.$anyDirty
              "
              :is-error="!!regFormValidator.email.$errors.length || !!regErrors.email"
              :error-message="regFormValidator.email.$errors[0]?.$message || regErrors.email"
              @update:modelValue="touchHeap.touch(regFormValidator, 'email')"
            />
          </div>
          <div class="" :class="{ formGroupError: !!regErrors.password }">
            <BitPasswordInput
              v-model="regForm.password"
              form-field-name="password"
              space-bottom="16"
              gtmid="SignUp_fild_Pass"
              :placeholder="regFormData.password_title"
              :text-label="regFormData.password_title"
              :is-valid="
                regErrors.password &&
                !regFormValidator.password.$error &&
                !regFormValidator.email.$anyDirty
              "
              :is-error="!!regFormValidator.password.$errors.length"
              :error-message="regErrors.password || regFormValidator.password.$errors[0]?.$message"
              @update:modelValue="touchHeap.touch(regFormValidator, 'password')"
            />
          </div>
          <div class="formGroup" :class="{ formGroupError: !!regErrors.currency }">
            <BitSelect
              v-model="regForm.currency"
              :text-label="regFormData.currency_title"
              :is-error="!!regErrors.currency"
              :error-message="regErrors.currency"
              :options="activeCurrenciesOptions"
            >
              <template #appendInputIcon>
                <img v-if="selectedCurrencyIconUrl" :src="selectedCurrencyIconUrl" />
              </template>
              <template #appendOptionIcon="{ value }">
                <img :src="activeCurrenciesOptionsIcons[value]" />
              </template>
            </BitSelect>
          </div>
          <div
            class="formGroup formCheckbox"
            :class="{
              formGroupError:
                !!regErrors.terms_acceptance || !!regFormValidator.terms_acceptance.$errors[0],
            }"
          >
            <BitCheckbox
              v-model="regForm.terms_acceptance"
              :is-error="
                !!regErrors.terms_acceptance || !!regFormValidator.terms_acceptance.$errors[0]
              "
            >
              <template #label>
                {{ regFormData.terms_title }}
                <a :href="termsLink" class="inputFormCheckboxLink">{{
                  regFormData.terms_link_title
                }}</a>
              </template>
            </BitCheckbox>
          </div>
          <div class="formGroup formPromoGroup formCheckbox">
            <div class="formPromoCheckbox">
              <BitCheckbox v-model="profileForm.receive_promos">
                <template #label>
                  <span class="checkboxWithTooltip">
                    {{ profileFormData.receive_promos_title }}
                    <div class="infoTooltip">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                          fill="white"
                        />
                      </svg>
                      <p class="infoTooltipItem">
                        {{ storeAuth.promoTooltipText }}
                      </p>
                    </div>
                  </span>
                </template>
              </BitCheckbox>
            </div>
          </div>
          <div class="formGroup formCheckbox formCheckboxLast">
            <BitCheckbox
              v-model="profileForm.agreed_to_partner_promotions"
              :label-text="storeAuth.crossSaleTitle"
            />
          </div>

          <div id="captcha_v2" />
          <div class="formGroup">
            <BitButton
              :class="{ margined: isGoogleRegTemplate }"
              name="sign-up"
              is-fluid-width
              :disabled="waitPlease"
              :loading="waitPlease"
              @click.prevent="nextStep()"
            >
              {{ regFormData.register_button_title }}
            </BitButton>
          </div>
        </div>

        <div class="modalTwoStep" :class="{ 'modal-hidden-step': step != 2 }">
          <div class="" :class="{ formGroupError: !!profileErrors.country }">
            <BitSelect
              v-model="profileForm.country"
              :options="countriesList"
              :text-label="profileFormData.country_title"
              :is-error="!!profileFormFirstValidator.country.$error"
              :error-message="profileFormFirstValidator.country.$errors[0]?.$message"
            />
          </div>
          <div class="">
            <BitSelect
              v-if="isStateFieldShown"
              v-model="profileForm.state"
              :options="statesList"
              :text-label="'State'"
              :is-error="!!profileFormFirstValidator.state.$error"
              :error-message="'Can`t be blank'"
            />
          </div>

          <div class="formGroupTwoBlock">
            <div class="" :class="{ formGroupError: !!profileErrors.city }">
              <BitInput
                v-model="profileForm.city"
                type="text"
                form-field-name="city"
                space-bottom="16"
                :placeholder="profileFormData.city_title"
                :text-label="profileFormData.city_title"
                :is-valid="
                  !profileFormFirstValidator.city.$errors.length &&
                  profileFormFirstValidator.city.$anyDirty
                "
                :is-error="!!profileFormFirstValidator.city.$errors.length"
                :error-message="profileFormFirstValidator.city.$errors[0]?.$message"
                @update:modelValue="touchHeap.touch(profileFormFirstValidator, 'city')"
              />
            </div>
            <div class="" :class="{ formGroupError: !!profileErrors.postal_code }">
              <BitInput
                v-model="profileForm.postal_code"
                type="text"
                form-field-name="code"
                space-bottom="16"
                :placeholder="profileFormData.postal_code_title"
                :text-label="profileFormData.postal_code_title"
                :is-valid="
                  !profileFormFirstValidator.postal_code.$errors.length &&
                  profileFormFirstValidator.postal_code.$anyDirty &&
                  !profileErrors.postal_code
                "
                :is-error="
                  !!profileFormFirstValidator.postal_code.$errors.length ||
                  !!profileErrors.postal_code ||
                  isErrorPostalCodeIncorrect
                "
                :error-message="
                  profileFormFirstValidator.postal_code.$errors[0]?.$message ||
                  isErrorPostalCodeIncorrect
                    ? 'postal code correct format is xxxx'
                    : ''
                "
                @update:modelValue="touchHeap.touch(profileFormFirstValidator, 'postal_code')"
              />
            </div>
          </div>
          <div class="" :class="{ formGroupError: !!profileErrors.address }">
            <BitInput
              v-model="profileForm.address"
              type="text"
              form-field-name="address"
              space-bottom="16"
              :placeholder="profileFormData.address_title"
              :text-label="profileFormData.address_title"
              :is-valid="
                !profileFormFirstValidator.address.$errors.length &&
                profileFormFirstValidator.address.$anyDirty
              "
              :is-error="!!profileFormFirstValidator.address.$errors.length"
              :error-message="profileFormFirstValidator.address.$errors[0]?.$message"
              gtmid="SignUp_Adress"
              @update:modelValue="touchHeap.touch(profileFormFirstValidator, 'address')"
            />
          </div>

          <div class="" :class="{ formGroupError: !!profileErrors.first_name }">
            <BitInput
              v-model="profileForm.first_name"
              type="text"
              form-field-name="first-name"
              :placeholder="profileFormData.first_name_title"
              :text-label="profileFormData.first_name_title"
              space-bottom="16"
              :is-valid="
                !profileFormFirstValidator.first_name.$errors.length &&
                profileFormFirstValidator.first_name.$anyDirty
              "
              :is-error="!!profileFormFirstValidator.first_name.$errors.length"
              :error-message="
                profileErrors.first_name ||
                profileFormFirstValidator.first_name.$errors[0]?.$message
              "
              @update:modelValue="touchHeap.touch(profileFormFirstValidator, 'first_name')"
            />
          </div>
          <div class="" :class="{ formGroupError: !!profileErrors.last_name }">
            <BitInput
              v-model="profileForm.last_name"
              type="text"
              form-field-name="last-name"
              space-bottom="16"
              :placeholder="profileFormData.last_name_title"
              :text-label="profileFormData.last_name_title"
              :is-valid="
                !profileFormFirstValidator.last_name.$errors.length &&
                profileFormFirstValidator.last_name.$anyDirty
              "
              :is-error="!!profileFormFirstValidator.last_name.$errors.length"
              :error-message="
                profileErrors.last_name || profileFormFirstValidator.last_name.$errors[0]?.$message
              "
              @update:modelValue="touchHeap.touch(profileFormFirstValidator, 'last_name')"
            />
          </div>
          <div class="formGroup" :class="{ formGroupError: !!profileErrors.date_of_birth }">
            <span class="modalThreeBlock">
              <div class="modalThreeBlockSelect">
                <BitSelect
                  v-model="profileForm.year"
                  :text-label="profileFormData.year_title"
                  :is-error="profileFormFirstValidator.year.$error"
                  :options="listYears"
                  :placeholder="profileFormData.year_title"
                  isNoMargin
                />
              </div>
              <div class="modalThreeBlockSelect">
                <BitSelect
                  v-model="profileForm.month"
                  :text-label="profileFormData.month_title"
                  :is-error="profileFormFirstValidator.month.$error"
                  :options="listMonths"
                  :placeholder="profileFormData.month_title"
                  isNoMargin
                />
              </div>
              <div class="modalThreeBlockSelect">
                <BitSelect
                  v-model="profileForm.day"
                  :text-label="profileFormData.day_title"
                  :is-error="profileFormFirstValidator.day.$error"
                  :options="listDays"
                  :placeholder="profileFormData.day_title"
                  isNoMargin
                />
              </div>
            </span>
            <span v-if="profileErrors.date_of_birth" class="errorText">
              {{ profileErrors.date_of_birth }}
            </span>
            <span
              v-if="
                (profileFormFirstValidator.day.$errors[0] ||
                  profileFormFirstValidator.month.$errors[0] ||
                  profileFormFirstValidator.year.$errors[0]) &&
                !profileErrors.date_of_birth
              "
              class="errorText"
            >
              {{
                (profileFormFirstValidator.day.$errors[0] &&
                  profileFormFirstValidator.day.$errors[0].$message) ||
                (profileFormFirstValidator.month.$errors[0] &&
                  profileFormFirstValidator.month.$errors[0].$message) ||
                (profileFormFirstValidator.year.$errors[0] &&
                  profileFormFirstValidator.year.$errors[0].$message)
              }}
            </span>
          </div>
          <div
            class="formGroup modal-phone"
            :class="{ formGroupError: !!profileErrors.mobile_phone }"
          >
            <TelephoneInput
              v-model="profileForm.mobile_phone"
              :profile-form-data="profileFormData"
              :player-country="userCountry"
              :is-error="
                !!profileFormFirstValidator.mobile_phone.$errors[0] || !!profileErrors.mobile_phone
              "
              :is-success="
                !profileErrors.mobile_phone &&
                !profileFormFirstValidator.mobile_phone.$error &&
                profileFormFirstValidator.mobile_phone.$anyDirty
              "
              @blur="profileFormFirstValidator.mobile_phone.$touch()"
              @input="clearProfileErrorField('mobile_phone')"
            />
            <span v-if="profileErrors.mobile_phone" class="errorText">
              {{ profileErrors.mobile_phone }}
            </span>
            <span
              v-if="
                profileFormFirstValidator.mobile_phone.$errors[0] && !profileErrors.mobile_phone
              "
              class="errorText"
            >
              {{ profileFormFirstValidator.mobile_phone.$errors[0].$message }}
            </span>
          </div>
          <div
            class="formGroup"
            :class="{
              formGroupError: !!profileFormFirstValidator.gender.$errors[0],
            }"
          >
            <BitGenderSelection
              v-model="profileForm.gender"
              :male-title="regFormData.label_text_male || 'Male'"
              :female-title="regFormData.label_text_female || 'Female'"
              :is-error="!!profileFormFirstValidator.gender.$errors[0]"
            />
          </div>
          <BitButton
            :title="profileFormData.next_button_title"
            isFluidWidth
            :loading="waitPlease"
            @click.prevent="nextStep()"
          >
            {{ profileFormData.next_button_title }}
          </BitButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  useModalSignup,
  commonComponents,
  commonEmits,
  commonProps,
} from '@/composables/useModalSignup';

export default defineComponent({
  components: commonComponents,
  props: commonProps,
  emits: commonEmits,
  setup(props, { emit }) {
    const {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    } = useModalSignup(props, emit);

    return {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    };
  },
});
</script>
<style lang="scss" src="./modalSignupBonusHubNeon.scss"></style>
