import { useGtm } from '@gtm-support/vue-gtm';
import { GtmPushInterface } from '~/types/gtmPush.interface';

export function useGtmPush() {
  const gtm = useGtm();

  const push = ({ vCategory, vAction, vLabel, vValue, ...rest }: GtmPushInterface) => {
    // @ts-ignore
    gtm.trackEvent({
      event: 'tEvent',
      category: vCategory,
      action: vAction,
      label: vLabel,
      value: vValue,
      noninteraction: false,
      ...rest,
    });
  };

  return { push };
}
