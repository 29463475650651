import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"
import _imports_0 from 'assets/images/common/formPasswordIcon.svg'
import _imports_1 from 'assets/images/common/formPasswordIcon2.svg'


const _hoisted_1 = { class: "bitPasswordInputMessage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BitInput = _resolveComponent("BitInput")!

  return (_openBlock(), _createBlock(_component_BitInput, _mergeProps({
    class: ["bitPasswordInput", !_ctx.isWithoutMessages ? `bitPasswordInput-${_ctx.passwordSecurityType}` : '']
  }, { ..._ctx.props }, {
    type: _ctx.passwordDisplayType,
    "is-with-append-main-icon": "",
    "input-wrapper-class": "bitPasswordInputWrapper",
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }), _createSlots({
    appendInputIcon: _withCtx(() => [
      (_ctx.isPasswordShown)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _imports_0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordDisplay && _ctx.togglePasswordDisplay(...args)))
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _imports_1,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordDisplay && _ctx.togglePasswordDisplay(...args)))
          }))
    ]),
    _: 2
  }, [
    (Boolean(_ctx.passwordSecurityType) && !_ctx.isWithoutMessages)
      ? {
          name: "inputMessage",
          fn: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.translatedPasswordSecurityType), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1040, ["class", "type"]))
}