<template>
  <div>
    <div class="modern2LionPartner">
      <h3 class="modern2LionPartnerTitle">
        {{ template.footerPaymentMethods }}
      </h3>
      <div class="modern2LionPartnerWrapper">
        <span class="modern2LionPartnerImg" v-for="img in template.paymentsLogo" :key="img.url">
          <img v-lazy="img.url" alt="img" />
        </span>
      </div>
    </div>
    <div class="modern2LionPartner modern2LionPartnerBottom">
      <h3 class="modern2LionPartnerTitle">
        {{ template.footerPartners }}
      </h3>
      <div class="modern2LionPartnerWrapper">
        <span class="modern2LionPartnerImg" v-for="img in template.partnersLogo" :key="img.url">
          <img v-lazy="img.url" alt="img" />
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
});
</script>
