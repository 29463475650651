import {
  SsUsersRegisterErrorsInterface,
  SsUsersRegisterErrorsParsedInterface,
} from '~/types/softswiss/ssUsers.response.interface';

const parsePlayerInfoErrors = (
  errors: SsUsersRegisterErrorsInterface
): SsUsersRegisterErrorsParsedInterface => {
  const result: { [key: string]: string } = {};

  Object.keys(errors).forEach((key) => {
    const message = Object.values(errors[key])[0] as string;

    result[key] = message;
  });

  return result;
};

export const getAllErrorMesages = (
  errors: SsUsersRegisterErrorsInterface,
  playerInfoErrors?: SsUsersRegisterErrorsInterface
): SsUsersRegisterErrorsParsedInterface => {
  const errorMessages = parsePlayerInfoErrors(errors);
  let playerInfoErrorMessages = {};

  if (playerInfoErrors) {
    playerInfoErrorMessages = parsePlayerInfoErrors(playerInfoErrors);
  }

  return { ...errorMessages, ...playerInfoErrorMessages };
};
