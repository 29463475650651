import { useVuelidate } from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';

import { ReCaptchaInstance } from 'recaptcha-v3';
import { PropType } from 'vue';
import { computed, ref, reactive, watch, onBeforeUnmount, inject, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import BannerSlider from '@/components/bannerSlider/bannerSlide.vue';
import CustomSelect from '@/components/customSelect/customSelect.vue';
import {
  BitCloseButton,
  BitInput,
  BitPasswordInput,
  BitSelect,
  BitCheckbox,
  BitButton,
  BitDateOfBirthSelect,
  BitGenderSelection,
} from '@/components/lib/shared';
import TelephoneInput from '@/components/telephoneInput.vue';

import { useAbbrFromPath } from '@/composables/abbrFromPath';
import { useGtmPush } from '@/composables/gtm';
import usePromoModal from '@/composables/promoModalState';
import { useCookie } from '@/composables/useCookie';
import { useOneSignalID } from '@/composables/useOneSignalID';
import { useMetaPixel } from '@/composables/usePixel';
import { useRecaptcha } from '@/composables/useRecaptcha';
import { useUbidex } from '@/composables/useUbidex';
import { useStoreLanguage } from '@/store/abstract/language';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsCountries } from '@/store/softswiss/ssCountries';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreAuth } from '@/store/strapi/auth';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreProfileForm } from '@/store/strapi/profileForm';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { RegFormInterface } from '@/types/regForm.interface';
import { SsCountriesResponseInterface } from '@/types/softswiss/ssCountriesResponse.interface';
import { SsUsersRegisterErrorsParsedInterface } from '@/types/softswiss/ssUsers.response.interface';
import { StrapiProfileFormInterface } from '@/types/strapiProfileForm.interface';
import { StrapiTemplatesEnum } from '@/types/strapiTemplateEnum';
import { states } from '@/utils/constants/states';
import { getAllErrorMesages } from '@/utils/registerErrorsParser';
import {
  passSecurityFn,
  required,
  email,
  password,
  requiredCheckbox,
  name,
  phoneWithoutCode,
  noSpacebar,
  minLength,
} from '@/utils/validators';
import { PasswordSecurityTypeEnum } from '@/utils/validators/helpers/passSecurityFn';
import touchHeap from '@/utils/validators/helpers/touchHeap';
import { RegistrationFormInterface } from '~/types/registrationForm.interface';

export const commonComponents = {
  CustomSelect,
  BannerSlider,
  TelephoneInput,
  BitCloseButton,
  BitInput,
  BitPasswordInput,
  BitSelect,
  BitCheckbox,
  BitButton,
  BitDateOfBirthSelect,
  BitGenderSelection,
};

export const commonProps = {
  firstTitle: {
    type: String,
    required: false,
    default: '',
  },
  secondTitle: {
    type: String,
    required: false,
    default: '',
  },
  thirdTitle: {
    type: String,
    required: false,
    default: '',
  },
  titleHighlight: {
    type: String,
    required: false,
    default: '',
  },
  modalType: {
    type: String as PropType<'short' | 'full'>,
    required: false,
    default: 'full',
  },
  modalRedirectPath: {
    type: String,
    required: false,
    default: '',
  },
};

export const commonEmits = ['changeCurrency', 'close', 'stepChanged'];
type tEmitSignup = (event: 'close' | 'changeCurrency' | 'stepChanged', ...args: any[]) => void;
export const useModalSignup = (props: any, emit: tEmitSignup) => {
  const storeSystemHosts = useStoreSystemHosts();
  const storeAuth = useStoreAuth();
  const storeCountry = useStoreCountry();
  const storePlatform = useStorePlatform();
  const ssCountriesStore = useStoreSsCountries();
  const storeSsPlayer = useStoreSsPlayer();
  const storeProfileForm = useStoreProfileForm();
  const storeRegForm = useStoreRegForm();
  const storeLanding = useStoreLanding();
  const storeCurrencyMenu = useStoreCurrencyMenu();
  const storeLanguage = useStoreLanguage();
  const recaptcha = useRecaptcha();

  const { saveUserId } = useCookie();
  const { push } = useGtmPush();
  const { abbr } = useAbbrFromPath();
  const { closeModal } = usePromoModal();

  /**
   * current step of registration
   */
  const step = ref(1);

  const userId = ref<string>('');

  const regFormData = computed((): RegFormInterface => storeRegForm.formData);

  const profileFormData = computed((): StrapiProfileFormInterface => storeProfileForm.formData);

  const isDesktop = computed(() => storePlatform.isDesktop);
  const isMobile = computed(() => storePlatform.isMobile);
  const isTablet = computed(() => storePlatform.isTablet);

  const template = computed(() => storeLanding.currentPage?.template);

  // Banner
  const bannerImg = computed(() => {
    return template.value.modalBannerDesktop;
  });
  const url = computed((): string => bannerImg.value?.url);
  const width = computed(() => bannerImg.value?.width);
  const height = computed(() => bannerImg.value?.height);
  const nameImg = computed(() => bannerImg.value?.name);

  const redirectHost = computed(() => storeSystemHosts.redirectHost);
  const ssHost = computed(() => storeSystemHosts.ssHost);
  const loginLink = computed(() => `https://${redirectHost.value}/?login`);

  const activeCurrenciesOptions = computed((): string[] => {
    return storeCurrencyMenu.activeCurrencies.map((currency) => {
      return currency.currencyCode;
    });
  });

  const activeCurrenciesOptionsIcons = computed(() =>
    storeCurrencyMenu.activeCurrencies.reduce((acc, currency) => {
      acc[currency.currencyCode] = currency.currencyIcon.url;
      return acc;
    }, {} as Record<string, any>)
  );

  const selectedCurrencyIconUrl = computed(
    () =>
      storeCurrencyMenu.activeCurrencies.find((c) => c.currencyCode === regForm.currency)
        ?.currencyIcon.url ?? ''
  );

  /**
   * list of error at registartion process (step 1)
   */
  const regErrors = ref({} as SsUsersRegisterErrorsParsedInterface);

  /**
   * user registration form data (step 1)
   */
  const regForm: RegistrationFormInterface = reactive({
    country: '',
    email: '',
    password: '',
    currency: storeLanding.currency,
    terms_acceptance: false,
    bonus_code: template.value?.bonusCode || '',
  });

  /**
   * list of validation rules for 'regForm' (step 1)
   */
  const regFormRules = {
    email: { required, email, noSpacebar },
    password: { required, password, noSpacebar, minLength: minLength(8) },
    currency: { required },
    country: {},
    terms_acceptance: { requiredCheckbox },
    bonus_code: { maxLength: maxLength(50), minLength: minLength(2) },
  };

  /**
   * frontent validator for 'regForm' (step 1)
   */
  const regFormValidator = useVuelidate(regFormRules, regForm, {
    $scope: false,
  });

  /**
   * user profile edit form data (step 2 & 3)
   */
  const profileForm = reactive({
    first_name: '',
    last_name: '',
    gender: '',
    country: '',
    state: '',
    mobile_phone: '',
    postal_code: '',
    receive_promos: false,
    agreed_to_partner_promotions: false,
    month: '',
    year: '',
    day: '',
    address: '',
    city: '',
  });
  const mobile_phone_code = ref('');

  const isStateFieldShown = computed(() =>
    Object.keys(states).includes(profileForm.country.toLowerCase())
  );

  /**
   * list of validation rules for 'profileForm' (step 2)
   */
  const profileFormFirstRules = computed(() => {
    const rules = {
      first_name: { required, name },
      last_name: { required, name },
      gender: storeLanding.isMiraxFrenchLand ? {} : { required },
      month: { required },
      year: { required },
      day: { required },
      state: {},
      country: { required },
      mobile_phone: storeLanding.isMiraxFrenchLand
        ? { required, phoneWithoutCode }
        : { required, maxLength: maxLength(20) },
      city: { required },
      address: { required },
      postal_code: { required, maxLength: maxLength(80) },
    };

    if (isStateFieldShown.value) {
      rules.state = { required };
    }
    return rules;
  });

  const step2Fields = [
    'first_name',
    'last_name',
    'date_of_birth',
    'mobile_phone',
    'state',
    'country',
    'gender',
    'city',
    'address',
    'postal_code',
  ];

  /**
   * frontent validator for 'profileForm' (step 2)
   */
  const profileFormFirstValidator = useVuelidate(profileFormFirstRules.value, profileForm, {
    $scope: false,
  });

  /**
   * list of error at profile update process (step 2 & 3)
   */
  const profileErrors = reactive({} as any);

  /**
   * step 2 field names for error detection and open step with errors
   */
  const step2fields = reactive([
    'first_name',
    'last_name',
    'date_of_birth',
    'mobile_phone',
    'state',
    'country',
    'gender',
    'city',
    'address',
    'postal_code',
  ]);

  /**
   * step 3 field names for error detection and open step with errors
   */
  // const step3fields = reactive(['city', 'address', 'postal_code']);

  const changeCurrency = (currency: string) => {
    emit('changeCurrency', currency);
  };

  watch(
    () => regForm.currency,
    (newCurrency) => {
      changeCurrency(newCurrency);
    },
    { immediate: true }
  );

  /**
   * user password visibility flag (step 1)
   */
  const showPassword = ref(false);

  /**
   * method for set status of user password visibility
   * show or hide password for user eyes
   */
  const showPasswordToggle = (setStatus?: boolean): void => {
    if (typeof setStatus === 'boolean') {
      showPassword.value = setStatus;
    } else {
      showPassword.value = !showPassword.value;
    }
  };

  // modal open send gtm
  push({
    vCategory: 'regform',
    vAction: 'regform_open',
    vLabel: `step_1`,
  });

  /**
   * flag for loading form process
   */
  const waitPlease = ref(false);

  const termsLink = computed(() => {
    return `/terms-and-conditions`;
  });

  /**
   * getter for input type return password field
   * (visibility of user password))
   */
  const passwordType = computed((): 'text' | 'password' => {
    return showPassword.value ? 'text' : 'password';
  });

  const statesList = computed(() => states[profileForm.country.toLowerCase()] || []);

  /**
   * Password creation hint message helpers (step 1)
   */
  const passwordWeakness = computed(() => passSecurityFn(regForm.password));
  const getPasswordWeaknsssMessage = (type: PasswordSecurityTypeEnum): string => {
    switch (type) {
      case PasswordSecurityTypeEnum.STRONG:
        return 'Your password is Strong';
      case PasswordSecurityTypeEnum.MEDIUM:
        return 'Your password is Medium';
      case PasswordSecurityTypeEnum.WEAK:
        return 'Your password is Weak';
      default:
        return '';
    }
  };

  /**
   * getter for list of available years for the user who are 18 years
   */
  const listYears = computed((): number[] => {
    const arrYears: number[] = [];
    const listYears = new Date().getFullYear() - 18;
    for (let i = listYears; i > listYears - 82; --i) {
      arrYears.push(i);
    }
    return arrYears;
  });

  /**
   * getter for list of available months for the user who are 18 years
   */
  const listMonths = computed((): number[] => {
    let months = 12;
    // check who are 18 years and separated permitted months
    if (+profileForm.year === listYears.value[0]) {
      const monthCount = new Date().getMonth() + 1;
      months = monthCount;
      if (+profileForm.month > months) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        profileForm.month = `${months}`;
      }
    }
    const monthArray: number[] = [];
    for (let i = 1; i <= months; i++) {
      monthArray.push(i);
    }
    return monthArray;
  });

  /**
   * getter for list of available days for the user who are 18 years
   */
  const listDays = computed((): number[] => {
    let days = 31;
    // check who select year and month and separated permitted days
    if (profileForm.year && profileForm.month) {
      days = new Date(+profileForm.year, +profileForm.month, 0).getDate();
      // Checks the days at month and overwrites if value more days-counted
      if (+profileForm.day > days) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        profileForm.day = `${days}`;
      }
      // check who are 18 years and separated permitted days
      if (
        +profileForm.year === listYears.value[0] &&
        new Date().getMonth() + 1 === +profileForm.month
      ) {
        const dayCount = new Date().getDate();
        days = dayCount - 1;
        if (+profileForm.day > days) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          profileForm.day = `${days}`;
        }
      }
    }
    const dayArray: number[] = [];
    for (let i = 1; i <= days; i++) {
      dayArray.push(i);
    }
    return dayArray;
  });

  const birthDate = computed((): string => {
    const day =
      Number(profileForm.day) > 9 ? Number(profileForm.day) : `0${Number(profileForm.day)}`;

    const month =
      Number(profileForm.month) > 9 ? Number(profileForm.month) : `0${Number(profileForm.month)}`;

    return `${profileForm.year}-${month}-${day}`;
  });

  const isBonusActive = ref(!!regForm.bonus_code);

  const toggleBonusShow = () => {
    isBonusActive.value = !isBonusActive.value;
  };

  const countries = computed((): SsCountriesResponseInterface[] => {
    return ssCountriesStore.countriesGetter;
  });

  const countriesList = computed((): { [key: string]: string } => {
    const countriesList: { [key: string]: string } = {};

    if (countries.value && countries.value.length) {
      countries.value.forEach((country) => {
        countriesList[country.code] = country.name;
      });
    }

    return countriesList;
  });

  const determineCurrency = () => {
    if (storeCountry.userCountry) {
      const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
        currency.defaultForCountries.some((country) => country === storeCountry.userCountry)
      );

      if (storeLanding.currency) {
        regForm.currency = storeLanding.currency;
      } else if (defaultCurrencyForCountry?.currencyCode) {
        regForm.currency = defaultCurrencyForCountry.currencyCode;
      } else {
        regForm.currency = storeCurrencyMenu.activeCurrencies[0]?.currencyCode;
      }
    }
  };

  const loadLocalizedUserData = () => {
    if (storeCountry.userCountry) {
      if (!regForm.country) {
        regForm.country =
          countries.value.find(
            (c) => String(c.code).toUpperCase() === String(storeCountry.userCountry).toUpperCase()
          )?.code || '';
      }
      if (!profileForm.country) {
        profileForm.country =
          countries.value.find(
            (c) => String(c.code).toUpperCase() === String(storeCountry.userCountry).toUpperCase()
          )?.code || '';
      }
      if (isStateFieldShown.value) {
        profileForm.state = statesList.value[0];
      }
    }
  };

  watch(() => countries.value, loadLocalizedUserData, { immediate: true });

  const isErrorPostalCodeIncorrect = ref(false);

  const updateProfileData = async () => {
    if (profileForm.mobile_phone[0] !== '+' && !storeLanding.isMiraxFrenchLand) {
      profileForm.mobile_phone = '+' + profileForm.mobile_phone;
    }

    const profileData = {
      ...profileForm,
      date_of_birth: birthDate.value,
      year: undefined,
      month: undefined,
      day: undefined,
    };

    Object.keys(profileErrors).forEach((field) => {
      delete profileErrors[field];
    });
    const result = await storeSsPlayer.updateProfile(profileData);

    if (result?.errors) {
      Object.keys(result.errors).forEach((e) => {
        profileErrors[e] = Object.values(result.errors[e]).join(', ');
      });

      if (result?.errors?.postal_code?.invalid_postal_code) {
        isErrorPostalCodeIncorrect.value = true;
      }

      if (step2Fields.some((field) => !!profileErrors[field])) {
        step.value = 2;
      }

      return false;
    }
    return true;
  };

  const route = useRoute();
  const stag = String(route?.query?.stag || '');
  const token = ref<null | string>(null);

  const recaptchaLoadPromise = inject<Promise<ReCaptchaInstance>>('recaptcha');

  const recaptchaKey = inject<string>('recaptchaKey');

  const isMirax = computed(
    () =>
      StrapiTemplatesEnum[template.value.__component] ===
      StrapiTemplatesEnum['landing-templates.modern2-square']
  );

  const checkFields = async (step?: number): Promise<boolean> => {
    switch (step) {
      case 1:
        return await regFormValidator.value.$validate();
      case 2:
        return await profileFormFirstValidator.value.$validate();
      default:
        return false;
    }
  };
  /**
   * register() method is used to register user with SS API
   * and send info about referral (stag) to SS if user inputs bonus code
   * that belongs to affiliate partner
   **/
  const register = async () => {
    const recaptchaV3 = await recaptchaLoadPromise;
    const recaptchaToken = await recaptchaV3?.execute('landing_' + recaptchaKey);

    let playerInfo = {
      email: regForm.email,
      password: regForm.password,
      password_confirmation: regForm.password,
      bonus_code: String(regForm.bonus_code).toUpperCase(),
      profile_attributes: {
        nickname: regForm.email.substring(0, 4) + '****',
        currency: regForm.currency.toUpperCase(),
        country:
          (storeCountry.userCountry === 'ca' && regForm.currency === 'CAD') ||
          (storeCountry.userCountry !== 'US' && !storeLanding.isMiraxFrenchLand)
            ? regForm.country
            : undefined,
        terms_acceptance: !!regForm.terms_acceptance,
        age_acceptance: !!regForm.terms_acceptance,
        receive_promos: !profileForm.receive_promos,
        receive_sms_promos: !profileForm.receive_promos,
        agreed_to_partner_promotions: profileForm.agreed_to_partner_promotions,
      },
      captcha: recaptchaToken,
      currency: regForm.currency.toUpperCase(),
      ...(isMirax.value
        ? {
            country: storeCountry.userCountry == 'US' && isMirax.value ? 'DK' : regForm.country,
          }
        : {}),
    };
    // const isEmailValid = true;

    // Disabling email checker for Magnetics emails
    // if (!playerInfo.email.includes('magnetics.io')) {
    //   isEmailValid = await storeSsPlayer.checkEmail(playerInfo.email);
    // }

    // if (!isEmailValid) {
    //   regErrors.value.email = 'Email does not exist';
    //   return false;
    // }

    if (storeLanding.isMiraxFrenchLand) {
      const updatedInfo = {
        email: regForm.email,
        password: regForm.password,
        password_confirmation: regForm.password,
        first_name: profileForm.first_name,
        last_name: profileForm.last_name,
        date_of_birth: birthDate.value,
        receive_promos: !profileForm.receive_promos,
        receive_sms_promos: !profileForm.receive_promos,
        agreed_to_partner_promotions: profileForm.agreed_to_partner_promotions,
        currency: regForm.currency.toUpperCase(),
        terms_acceptance: regForm.terms_acceptance,
        age_acceptance: regForm.terms_acceptance,
        mobile_phone: `+33${profileForm.mobile_phone}`,
        groups: [],
        captcha: recaptchaToken,
        context: 'registration',
      };

      // @ts-ignore
      playerInfo = updatedInfo;
    }

    const recaptchaHeaderParams = recaptcha.recaptchaTokenV2.value
      ? { recaptcha2: recaptcha.recaptchaTokenV2.value }
      : { recaptcha: recaptchaToken };
    const isEmailValidSs = await storeSsPlayer.validateUserEmail(playerInfo);
    if (isEmailValidSs !== true && isEmailValidSs.errors?.email) {
      regErrors.value.email =
        isEmailValidSs.errors.email[Object.keys(isEmailValidSs.errors.email)[0]];
      return false;
    }

    const isFraudEmail = await storeSsPlayer.checkIfFraudEmailWithSeon(playerInfo.email);

    if (isFraudEmail) {
      playerInfo.bonus_code = '';
    }

    const registrationResult = await storeSsPlayer.registerPlayer(
      playerInfo,
      recaptchaHeaderParams
    );

    if (registrationResult?.errors?.reCaptcha?.wrong) {
      await recaptcha.getTokenV2();
      return;
    } else {
      recaptcha.resetTokenV2();
    }

    if (registrationResult.errors || registrationResult.playerInfo.errors) {
      recaptcha.resetCaptchaV2();

      regErrors.value = getAllErrorMesages(
        // @ts-ignore
        registrationResult.errors,
        registrationResult.playerInfo.errors
      );

      return false;
    }

    userId.value = registrationResult?.playerInfo?.id?.toString() || '';

    push({
      vCategory: 'PROMO-LAND',
      vAction: 'Registration',
      vLabel: 'success',
      stag,
      PlayerId: registrationResult.playerInfo.id,
    });

    useMetaPixel().sendEvent();
    useOneSignalID().setUserGroup();
    saveUserId(registrationResult.playerInfo.id as number);

    if (registrationResult?.playerInfo?.id) {
      await useUbidex().sendEvent({
        event: 'regfinished',
        userId: registrationResult.playerInfo.id,
        stag,
        currency: regForm.currency,
      });
      await useUbidex().sendEvent({
        event: 'visit',
        userId: registrationResult.playerInfo.id,
        stag,
        currency: regForm.currency,
      });
    }

    const isCrypto = !!storeCurrencyMenu.activeCurrencies.find(
      (cur) => cur.currencyCode === playerInfo.currency
    )?.crypto;

    token.value = storeSsPlayer.generateAuthToken({
      login: playerInfo.email,
      password: playerInfo.password,
      stag,
      modal: isCrypto ? `deposit` : `signup`,
    });

    const groupsToAdd: string[] = [];

    if (storeLanding.currentPage?.groupToAddOnReg) {
      groupsToAdd.push(storeLanding.currentPage.groupToAddOnReg);
    }

    if (isFraudEmail) {
      groupsToAdd.push('Seon');
    }

    if (groupsToAdd.length) {
      await storeSsPlayer.updatePlayerGroups({
        add: groupsToAdd,
      });
    }

    return true;
  };

  const redirectToWelcomeBonus = () => {
    push({
      vCategory: 'landing_form',
      vAction: 'Success',
    });
    let redirectUrl = '';
    if (props.modalRedirectPath) {
      redirectUrl = `https://${redirectHost.value}${props.modalRedirectPath}`;
    }
    // else if (
    //   template.value.redirects &&
    //   Array.isArray(template.value.redirects) &&
    //   template.value.redirects.length
    // ) {
    //   const redirects = template.value.redirects.map((r) => {
    //     return {
    //       url: r.url,
    //       сurrency: r.currency?.currencyCode ? r.currency.currencyCode : regForm.currency,
    //     };
    //   });

    //   const redirect = redirects.find((t) => t.currency === regForm.currency) || redirects[0];

    //   // i'ved added replace to fix currency, should be burned and rewritten
    //   redirectUrl = `https://${redirectHost.value}${redirect.url.replace(
    //     'currency',
    //     `currency=${redirect.сurrency}`
    //   )}`;
    // }
    else {
      redirectUrl = `https://${redirectHost.value}?auth=${token.value}`;
    }

    window.location.href = redirectUrl.includes('?')
      ? `${redirectUrl}&stag=${stag}`
      : `${redirectUrl}?stag=${stag}`;
  };

  const prevStep = () => {
    if (step.value === 2) {
      step.value = 1;
    } else if (step.value === 3) {
      step.value = 2;
    }
  };

  const nextStepForMiraxFrenchLand = async () => {
    if (step.value === 1 && (await checkFields(1))) {
      push({
        event: `PersonInfoComplete${abbr.value}Promo`,
        user_id: userId.value,
        timestamp: Math.round(Date.now() / 1000),
      });
      step.value = 2;
    } else if (step.value === 2 && (await checkFields(2)) && (await register())) {
      push({
        event: `RegFinish${abbr.value}Promo`,
        user_id: userId.value,
        timestamp: Math.round(Date.now() / 1000),
      });
      return redirectToWelcomeBonus();
    }
  };

  const nextStep = async () => {
    waitPlease.value = true;

    push({
      vCategory: 'regform',
      vAction: 'regform_success',
      vLabel: 'step_1',
    });

    if (storeLanding.isMiraxFrenchLand) {
      await nextStepForMiraxFrenchLand();
    } else if (step.value === 1 && (await checkFields(1)) && (await register())) {
      push({
        event: `PersonInfoComplete${abbr.value}Promo`,
        user_id: userId.value,
        timestamp: Math.round(Date.now() / 1000),
      });
      if (
        storeCurrencyMenu.activeCurrencies.some(
          (c) => c.currencyCode === regForm.currency && c.crypto
        ) ||
        props.modalType === 'short'
      ) {
        return redirectToWelcomeBonus();
      } else {
        step.value = 2;
      }
    } else if (step.value === 2 && (await checkFields(2))) {
      if (await updateProfileData()) {
        push({
          vCategory: 'regform',
          vAction: 'regform_success',
          vLabel: 'step_2',
        });

        push({
          event: `RegFinish${abbr.value}Promo`,
          user_id: userId.value,
          timestamp: Math.round(Date.now() / 1000),
        });

        return redirectToWelcomeBonus();
      } else if (profileFormFirstValidator.value.$error) {
        push({
          vCategory: 'regform',
          vAction: 'regform_fail',
          vLabel: 'step_2',
        });
        step.value = 2;
      }
    }

    waitPlease.value = false;
  };

  const clearProfileErrorField = (prop: string) => {
    if (profileErrors[prop]) {
      delete profileErrors[prop];
    }
  };

  const openIndexList = ref(0);

  const toggle = (index: number) => {
    if (openIndexList.value === index) {
      openIndexList.value = 0;
    } else {
      openIndexList.value = index;
    }
  };

  const close = () => {
    if (storeLanding.isMiraxFrenchLand) {
      return;
    }

    push({
      vCategory: 'regform',
      vAction: 'regform_close',
      vLabel: `step_${step.value}`,
    });

    if (step.value > 1) {
      return redirectToWelcomeBonus();
    }
  };

  ssCountriesStore.load();
  onMounted(() => {
    determineCurrency();
    // localStorage.setItem('7BIT_COUPON_CODE', template.value?.bonusCode);
  });
  onBeforeUnmount(close);

  watch(
    () => regForm.currency,
    () => {
      regErrors.value.currency = '';
    }
  );

  watch(
    () => regForm.email,
    () => {
      regErrors.value.email = '';
    }
  );

  watch(
    () => regForm.password,
    () => {
      regErrors.value.password = '';
    }
  );

  watch(
    () => regForm.terms_acceptance,
    () => {
      regErrors.value.terms_acceptance = '';

      push({
        vCategory: 'regform',
        vAction: 'regform_terms',
      });
    }
  );

  watch(
    () => profileForm.receive_promos,
    () => {
      push({
        vCategory: 'regform',
        vAction: 'regform_promos',
      });
    }
  );

  watch(
    () => profileForm.first_name,
    () => {
      profileErrors.first_name = '';
    }
  );

  watch(
    () => profileForm.last_name,
    () => {
      profileErrors.last_name = '';
    }
  );

  watch(
    () => profileForm.year,
    () => {
      profileErrors.year = '';
    }
  );

  watch(
    () => profileForm.month,
    () => {
      profileErrors.month = '';
    }
  );

  watch(
    () => profileForm.day,
    () => {
      profileErrors.day = '';
    }
  );

  watch(
    () => profileForm.gender,
    () => {
      profileErrors.gender = '';
    }
  );

  watch(
    () => profileForm.mobile_phone,
    () => {
      profileErrors.mobile_phone = '';
    }
  );

  watch(
    () => profileForm.country,
    () => {
      profileErrors.country = '';
    }
  );

  // watch(
  //   () => regForm.country,
  //   () => {
  //     regForm.country = '';
  //   }
  // );

  watch(
    () => profileForm.city,
    () => {
      profileErrors.city = '';
    }
  );

  watch(
    () => profileForm.address,
    () => {
      profileErrors.address = '';
    }
  );

  watch(
    () => profileForm.postal_code,
    () => {
      profileErrors.postal_code = '';
    }
  );
  watch(
    () => step.value,
    () => {
      emit('stepChanged', step.value);
    },
    { immediate: true }
  );

  return {
    storeAuth,
    close,
    changeCurrency,
    showPasswordToggle,
    toggleBonusShow,
    loadLocalizedUserData,
    updateProfileData,
    register,
    isStateFieldShown,
    redirectToWelcomeBonus,
    isErrorPostalCodeIncorrect,
    prevStep,
    toggle,
    openIndexList,
    nextStep,
    regFormData,
    profileFormData,
    isDesktop,
    isMobile,
    isTablet,
    abbr,
    template,
    redirectHost,
    ssHost,
    loginLink,
    statesList,
    activeCurrenciesOptions,
    step,
    nameImg,
    regErrors,
    regForm,
    showPassword,
    waitPlease,
    profileForm,
    profileErrors,
    step2fields,
    termsLink,
    passwordType,
    listYears,
    listMonths,
    listDays,
    birthDate,
    isBonusActive,
    url,
    width,
    height,
    countries,
    countriesList,
    regFormValidator,
    profileFormFirstValidator,
    passwordWeakness,
    clearProfileErrorField,
    mobile_phone_code,
    closeModal,
    activeCurrenciesOptionsIcons,
    selectedCurrencyIconUrl,
    isGoogleRegTemplate: computed(() => storeLanding.isGoogleRegTemplate),
    isMiraxFrenchLand: computed(() => storeLanding.isMiraxFrenchLand),
    lang: computed(() => storeLanguage.language),
    activeCurrencies: computed(() => storeCurrencyMenu.activeCurrencies),
    currentPage: computed(() => storeLanding.currentPage),
    currency: computed(() => storeLanding.currency),
    userCountry: computed(() => storeCountry.userCountry),
    passwordWeaknsssMessage: computed(() => getPasswordWeaknsssMessage(passwordWeakness.value)),
    touchHeap: touchHeap(),
    googleOAuthUrl: computed(() =>
      window
        ? `${window.location.protocol}//www.${window.location.host}/users/auth/google_oauth2`
        : ''
    ),
  };
};
