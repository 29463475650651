import Bugsnag from '@bugsnag/js';
import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';

export const useStoreCountry = defineStore('country', {
  state: () => ({
    country: '',
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    userCountry(state): string {
      return state.country || '';
    },
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    async check() {
      try {
        if (this.status === LoadingStatusEnum.loaded) {
          return;
        }
        this.status = LoadingStatusEnum.loading;

        const host = `${
          location.host === 'localhost' || location.host.includes('local.7bitcasino.com')
            ? '7bitcasino.com'
            : location.host
        }/cf-api/country`;
        const response = await useTransport().get(`https://${host}`);

        if (response?.country) {
          this.country = String(response.country).toUpperCase();
          this.status = LoadingStatusEnum.loaded;
        } else {
          this.status = LoadingStatusEnum.noData;
        }
      } catch (e) {
        this.status = LoadingStatusEnum.error;

        // @ts-ignore
        Bugsnag.notify(e, function (event) {
          event.addMetadata('Metadata', { store: 'geoip', method: 'load' });
        });
      }
    },
  },
});
