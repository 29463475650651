<template>
  <div class="modalContentSignup">
    <!-- <h3 class="modalTitle">
      {{ regFormData.header }}
    </h3> -->
    <div class="modalContentSignupHeader">
      <div class="signUpLogoBlock">
        <a :href="template.logoHref" class="signUpLogo" :gtmid="`7bitMain${abbr}Promo`">
          <img :src="template.logo.url" alt="logoLion" />
        </a>
        <a
          :href="template.logoHref"
          class="signUpLogo signUpLogoMobile"
          :gtmid="`7bitMain${abbr}Promo`"
        >
          <img src="~assets/images/landing/7bit_logo_small.svg" alt="7bit_logo_small" />
        </a>
        <h3 class="modalTitle">
          {{ regFormData.header }}
        </h3>
      </div>
    </div>
    <div class="modalBanner">
      <div class="modalLoginLionText">
        <div class="modalLoginLionBanner">{{ firstTitle }}</div>
        <div class="modalLoginLionBannerSecond">{{ secondTitle }}</div>
      </div>
      <img
        ref="banner"
        :src="url"
        class="modalBannerSignUp"
        :alt="nameImg"
        :width="width"
        :height="height"
      />
    </div>
    <div class="modalInside">
      <div class="modalFirstStep" :class="{ 'modal-hidden-step': step != 1 }">
        <div class="formGroup" :class="{ formGroupError: !!regErrors.email }">
          <input
            id="email"
            v-model="regForm.email"
            type="email"
            name="email"
            class="formInput"
            autocomplete="email"
            :placeholder="regFormData.email_title"
            :class="{
              formInputError: regFormValidator.email.$errors.length,
              formInputSuccess:
                !regErrors.email &&
                !regFormValidator.email.$errors.length &&
                regFormValidator.email.$anyDirty,
            }"
            @keydown.space.prevent
            @input="touchHeap.touch(regFormValidator, 'email')"
          />
          <span v-if="regErrors.email" class="errorText">
            {{ regErrors.email }}
          </span>
          <span v-if="regFormValidator.email.$errors[0] && !regErrors.email" class="errorText">
            {{ regFormValidator.email.$errors[0].$message }}
          </span>
        </div>
        <div class="formGroup formPassword" :class="{ formGroupError: !!regErrors.password }">
          <input
            id="password"
            v-model="regForm.password"
            :type="passwordType"
            name="password"
            class="formInput"
            autocomplete="current-password"
            :class="{
              formInputError: regFormValidator.password.$errors.length,
              [passwordWeakness]:
                !regFormValidator.password.$errors.length &&
                !regErrors.password &&
                passwordWeakness,
            }"
            @input="touchHeap.touch(regFormValidator, 'password')"
          />
          <span class="formPasswordIcon" @click="showPasswordToggle()">
            <svg
              width="13"
              height="8"
              viewBox="0 0 13 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.1205 3.99999C8.1205 4.79528 7.39498 5.43999 6.5 5.43999C5.60503 5.43999 4.8795 4.79528 4.8795 3.99999C4.8795 3.2047 5.60503 2.55999 6.5 2.55999C7.39498 2.55999 8.1205 3.2047 8.1205 3.99999Z"
                fill="#8190DD"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.108033 3.57333C1.25439 1.46133 3.68513 0 6.5 0C9.31487 0 11.7456 1.46133 12.892 3.57333C13.036 3.84 13.036 4.16 12.892 4.42667C11.7456 6.53867 9.31487 8 6.5 8C3.68513 8 1.25439 6.53867 0.108033 4.42667C-0.0360111 4.16 -0.0360111 3.84 0.108033 3.57333ZM3.79917 4C3.79917 5.32267 5.01154 6.4 6.5 6.4C7.98846 6.4 9.20083 5.32267 9.20083 4C9.20083 2.67733 7.98846 1.6 6.5 1.6C5.01154 1.6 3.79917 2.67733 3.79917 4Z"
                fill="#8190DD"
              />
            </svg>
          </span>
          <span class="formPasswordIcon formPasswordIconShow" @click="showPasswordToggle()">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.1172 17.0654C10.7231 17.201 11.3531 17.2726 12 17.2726C15.6364 17.2726 18.7418 15.0108 20 11.8181C19.343 10.1509 18.1823 8.7375 16.7053 7.76749L15.1377 9.97983C15.4546 10.5193 15.6364 11.1476 15.6364 11.8181C15.6364 13.8253 14.0073 15.4544 12 15.4544C11.7626 15.4544 11.5304 15.4316 11.3056 15.3881L10.1172 17.0654ZM12.3041 13.979C13.3668 13.8315 14.1818 12.9221 14.1818 11.8181C14.1818 11.6709 14.1673 11.5272 14.1397 11.3883L12.3041 13.979ZM11.6889 9.65815L9.8589 12.2408C9.83218 12.1041 9.81818 11.9628 9.81818 11.8181C9.81818 10.7165 10.6296 9.80861 11.6889 9.65815ZM12.6888 8.24695C12.4657 8.20413 12.2355 8.18171 12 8.18171C9.99273 8.18171 8.36364 9.8108 8.36364 11.8181C8.36364 12.4865 8.5443 13.113 8.85944 13.6514L7.29042 15.8658C5.81547 14.8959 4.65637 13.4837 4 11.8181C5.25818 8.62534 8.36364 6.36353 12 6.36353C12.6449 6.36353 13.2732 6.43467 13.8773 6.56953L12.6888 8.24695Z"
                fill="#8190DD"
              />
              <rect
                x="6.78003"
                y="18.2397"
                width="18"
                height="1"
                transform="rotate(-54.6804 6.78003 18.2397)"
                fill="#8190DD"
              />
            </svg>
          </span>
          <label class="formGroupPlaceholder" :class="{ placeholderFocus: regForm.password }">
            {{ regFormData.password_title }}
          </label>
          <span v-if="regErrors.password" class="errorText">
            {{ regErrors.password }}
          </span>
          <span
            v-if="regFormValidator.password.$errors[0] && !regErrors.password"
            class="errorText"
          >
            {{ regFormValidator.password.$errors[0].$message }}
          </span>
          <span
            v-if="
              !regFormValidator.password.$errors[0] &&
              !regErrors.password &&
              passwordWeaknsssMessage
            "
            class="errorText"
            :class="{
              [passwordWeakness]: passwordWeakness,
            }"
          >
            {{ passwordWeaknsssMessage }}
          </span>
        </div>

        <div class="formGroup" :class="{ formGroupError: !!regErrors.currency }">
          <CustomSelect
            v-model="regForm.currency"
            class="formGroupSelect"
            :options="activeCurrenciesOptions"
          />
          <label class="formGroupPlaceholder" :class="{ placeholderFocus: regForm.currency }">
            {{ regFormData.currency_title }}
          </label>
          <span class="errorText" v-if="regErrors.currency">
            {{ regErrors.currency }}
          </span>
        </div>

        <div
          class="formGroup formCheckbox"
          :class="{
            formGroupError:
              !!regErrors.terms_acceptance || !!regFormValidator.terms_acceptance.$errors[0],
          }"
        >
          <input
            id="agree"
            v-model="regForm.terms_acceptance"
            class="inputFormCheckbox"
            type="checkbox"
            name="agree"
          />
          <label for="agree" class="inputFormCheckboxLabel">
            {{ regFormData.terms_title }}
            <a :href="termsLink" class="inputFormCheckboxLink">{{
              regFormData.terms_link_title
            }}</a>
          </label>
          <span
            v-if="!!regErrors.terms_acceptance || !!regFormValidator.terms_acceptance.$errors[0]"
            class="errorText errorTextAlign"
          >
            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_409_25243)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 0C3.634 0 0.5 3.134 0.5 7C0.5 10.866 3.634 14 7.5 14C11.366 14 14.5 10.866 14.5 7C14.4958 3.13573 11.3643 0.00418359 7.5 0ZM7.5 12.25C4.6005 12.25 2.25 9.8995 2.25 7C2.25 4.1005 4.6005 1.75 7.5 1.75C10.3995 1.75 12.75 4.1005 12.75 7C12.7468 9.89816 10.3982 12.2468 7.5 12.25ZM7.5 8.80311C7.98325 8.80311 8.375 8.41136 8.375 7.92811V3.84479C8.375 3.36154 7.98325 2.96979 7.5 2.96979C7.01675 2.96979 6.625 3.36154 6.625 3.84479V7.92811C6.625 8.41136 7.01675 8.80311 7.5 8.80311ZM8.37792 10.2083C8.37792 10.6916 7.98617 11.0833 7.50292 11.0833C7.01967 11.0833 6.62792 10.6916 6.62792 10.2083C6.62792 9.72508 7.01967 9.33333 7.50292 9.33333C7.98617 9.33333 8.37792 9.72508 8.37792 10.2083Z"
                  fill="#ED3C3C"
                />
              </g>
              <defs>
                <clipPath id="clip0_409_25243">
                  <rect width="14" height="14" fill="white" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>
            must be completed
          </span>
        </div>
        <div class="formGroup formPromoGroup formCheckbox">
          <div class="formPromoCheckbox">
            <input
              id="receivePromos"
              v-model="profileForm.receive_promos"
              type="checkbox"
              name="receivePromos"
              class="inputFormCheckbox"
              :title="profileFormData.receive_promos_title"
            />
            <label for="receivePromos" class="inputFormCheckboxLabel">
              {{ profileFormData.receive_promos_title }}
            </label>
          </div>
          <div class="infoTooltip">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="white"
              />
            </svg>
            <p class="infoTooltipItem">
              {{ storeAuth.promoTooltipText }}
            </p>
          </div>
        </div>
        <div class="formGroup formPromoGroup formCheckbox">
          <div class="formPromoCheckbox">
            <input
              id="agreed_to_partner_promotions"
              v-model="profileForm.agreed_to_partner_promotions"
              type="checkbox"
              name="agreed_to_partner_promotions"
              class="inputFormCheckbox"
              :title="storeAuth.crossSaleTitle"
            />
            <label
              for="agreed_to_partner_promotions"
              class="inputFormCheckboxLabel inputFormCheckboxLabelFlex"
            >
              <span>{{ storeAuth.crossSaleTitle }}</span>
            </label>
          </div>
          <div class="infoTooltip">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
                fill="white"
              />
            </svg>
            <p class="infoTooltipItem">{{ storeAuth.partnerpromotionsTooltipText }}</p>
          </div>
        </div>

        <div id="captcha_v2" />
        <div class="formGroup">
          <button
            class="formGroupButton loader"
            :class="{ margined: isGoogleRegTemplate }"
            name="sign-up"
            :disabled="waitPlease"
            @click.prevent="nextStep()"
          >
            {{ regFormData.register_button_title }}
            <span class="icon-loader" />
          </button>
        </div>
      </div>

      <div class="modalTwoStep" :class="{ 'modal-hidden-step': step != 2 }">
        <div class="formGroup" :class="{ formGroupError: !!profileErrors.country }">
          <CustomSelect
            v-model="profileForm.country"
            :options="countriesList"
            name="country"
            class="formGroupSelect"
            :title="profileFormData.country_title"
            :is-error="profileFormFirstValidator.country.$error"
          />
          <label class="formGroupPlaceholder" :class="{ placeholderFocus: profileForm.country }">
            {{ profileFormData.country_title }}
          </label>
          <span v-if="profileFormFirstValidator.country.$errors[0]" class="errorText">
            {{ profileFormFirstValidator.country.$errors[0].$message }}
          </span>
        </div>
        <div class="formGroup">
          <CustomSelect
            v-if="isStateFieldShown"
            v-model="profileForm.state"
            :options="statesList"
            name="state"
            class="formGroupSelect"
            :title="'State'"
            :is-error="profileFormFirstValidator.country.$error"
          />
        </div>
        <div class="formGroup modalTwoBlock">
          <div class="formGroupFullWidth" :class="{ formGroupError: !!profileErrors.city }">
            <input
              id="city"
              v-model="profileForm.city"
              type="text"
              name="city"
              class="formInput"
              :placeholder="profileFormData.city_title"
              :title="profileFormData.city_title"
              :class="{
                formInputError: profileFormFirstValidator.city.$errors.length,
                formInputSuccess:
                  !profileFormFirstValidator.city.$errors.length &&
                  profileFormFirstValidator.city.$anyDirty,
              }"
              @input="touchHeap.touch(profileFormFirstValidator, 'city')"
            />
            <span v-if="profileFormFirstValidator.city.$errors[0]" class="errorText">
              {{ profileFormFirstValidator.city.$errors[0].$message }}
            </span>
          </div>
          <div class="formGroupFullWidth" :class="{ formGroupError: !!profileErrors.postal_code }">
            <input
              id="code"
              v-model="profileForm.postal_code"
              type="text"
              name="code"
              class="formInput"
              :placeholder="profileFormData.postal_code_title"
              :title="profileFormData.postal_code_title"
              :class="{
                formInputError:
                  profileFormFirstValidator.postal_code.$errors.length ||
                  !!profileErrors.postal_code,
                formInputSuccess:
                  !profileFormFirstValidator.postal_code.$errors.length &&
                  profileFormFirstValidator.postal_code.$anyDirty &&
                  !profileErrors.postal_code,
              }"
              @input="touchHeap.touch(profileFormFirstValidator, 'postal_code')"
            />
            <span v-if="isErrorPostalCodeIncorrect" class="errorText">
              postal code correct format is xxxx
            </span>
            <span v-if="profileFormFirstValidator.postal_code.$errors[0]" class="errorText">
              {{ profileFormFirstValidator.postal_code.$errors[0].$message }}
            </span>
          </div>
        </div>
        <div class="formGroup" :class="{ formGroupError: !!profileErrors.address }">
          <input
            id="address"
            v-model="profileForm.address"
            type="text"
            name="address"
            class="formInput"
            :placeholder="profileFormData.address_title"
            :title="profileFormData.address_title"
            :class="{
              formInputError: profileFormFirstValidator.address.$errors.length,
              formInputSuccess:
                !profileFormFirstValidator.address.$errors.length &&
                profileFormFirstValidator.address.$anyDirty,
            }"
            @input="touchHeap.touch(profileFormFirstValidator, 'address')"
          />
          <span v-if="profileFormFirstValidator.address.$errors[0]" class="errorText">
            {{ profileFormFirstValidator.address.$errors[0].$message }}
          </span>
        </div>
        <div class="formGroup" :class="{ formGroupError: !!profileErrors.first_name }">
          <input
            id="name"
            v-model="profileForm.first_name"
            type="text"
            name="first-name"
            class="formInput"
            :placeholder="profileFormData.first_name_title"
            :class="{
              formInputError: profileFormFirstValidator.first_name.$errors.length,
              formInputSuccess:
                !profileFormFirstValidator.first_name.$errors.length &&
                profileFormFirstValidator.first_name.$anyDirty,
            }"
            @input="touchHeap.touch(profileFormFirstValidator, 'first_name')"
          />
          <span v-if="profileErrors.first_name" class="errorText">
            {{ profileErrors.first_name }}
          </span>
          <span
            v-if="profileFormFirstValidator.first_name.$errors[0] && !profileErrors.first_name"
            class="errorText"
          >
            {{ profileFormFirstValidator.first_name.$errors[0].$message }}
          </span>
        </div>
        <div class="formGroup" :class="{ formGroupError: !!profileErrors.last_name }">
          <input
            id="last-name"
            v-model="profileForm.last_name"
            type="text"
            name="last-name"
            class="formInput"
            :placeholder="profileFormData.last_name_title"
            :class="{
              formInputError: profileFormFirstValidator.last_name.$errors.length,
              formInputSuccess:
                !profileFormFirstValidator.last_name.$errors.length &&
                profileFormFirstValidator.last_name.$anyDirty,
            }"
            @input="touchHeap.touch(profileFormFirstValidator, 'last_name')"
          />
          <span v-if="profileErrors.last_name" class="errorText">
            {{ profileErrors.last_name }}
          </span>
          <span
            v-if="profileFormFirstValidator.last_name.$errors[0] && !profileErrors.last_name"
            class="errorText"
          >
            {{ profileFormFirstValidator.last_name.$errors[0].$message }}
          </span>
        </div>
        <div class="formGroup" :class="{ formGroupError: !!profileErrors.date_of_birth }">
          <span class="modalThreeBlock">
            <div class="modalThreeBlockSelect">
              <CustomSelect
                class="formGroupSelect"
                v-model="profileForm.year"
                :options="listYears"
                :is-error="profileFormFirstValidator.year.$error"
                :custom-index="1"
                :is-selection-list-visible="openIndexList === 1"
                @toggleList="toggle"
              />
              <label class="formGroupPlaceholder" :class="{ placeholderFocus: profileForm.year }">
                {{ profileFormData.year_title }}
              </label>
            </div>
            <div class="modalThreeBlockSelect">
              <CustomSelect
                class="formGroupSelect"
                v-model="profileForm.month"
                :options="listMonths"
                :is-error="profileFormFirstValidator.month.$error"
                :custom-index="2"
                :is-selection-list-visible="openIndexList === 2"
                @toggleList="toggle"
              />
              <label class="formGroupPlaceholder" :class="{ placeholderFocus: profileForm.month }">
                {{ profileFormData.month_title }}
              </label>
            </div>
            <div class="modalThreeBlockSelect">
              <CustomSelect
                class="formGroupSelect"
                v-model="profileForm.day"
                :options="listDays"
                :is-error="profileFormFirstValidator.day.$error"
                :custom-index="3"
                :is-selection-list-visible="openIndexList === 3"
                @toggleList="toggle"
              />
              <label class="formGroupPlaceholder" :class="{ placeholderFocus: profileForm.day }">
                {{ profileFormData.day_title }}
              </label>
            </div>
          </span>
          <span v-if="profileErrors.date_of_birth" class="errorText">
            {{ profileErrors.date_of_birth }}
          </span>
          <span
            v-if="
              (profileFormFirstValidator.day.$errors[0] ||
                profileFormFirstValidator.month.$errors[0] ||
                profileFormFirstValidator.year.$errors[0]) &&
              !profileErrors.date_of_birth
            "
            class="errorText"
          >
            {{
              (profileFormFirstValidator.day.$errors[0] &&
                profileFormFirstValidator.day.$errors[0].$message) ||
              (profileFormFirstValidator.month.$errors[0] &&
                profileFormFirstValidator.month.$errors[0].$message) ||
              (profileFormFirstValidator.year.$errors[0] &&
                profileFormFirstValidator.year.$errors[0].$message)
            }}
          </span>
        </div>
        <div
          class="formGroup modal-phone"
          :class="{ formGroupError: !!profileErrors.mobile_phone }"
        >
          <TelephoneInput
            v-model="profileForm.mobile_phone"
            :profile-form-data="profileFormData"
            :player-country="userCountry"
            :is-error="
              !!profileFormFirstValidator.mobile_phone.$errors[0] || !!profileErrors.mobile_phone
            "
            :is-success="
              !profileErrors.mobile_phone &&
              !profileFormFirstValidator.mobile_phone.$error &&
              profileFormFirstValidator.mobile_phone.$anyDirty
            "
            @blur="profileFormFirstValidator.mobile_phone.$touch()"
            @input="clearProfileErrorField('mobile_phone')"
          />
          <span v-if="profileErrors.mobile_phone" class="errorText">
            {{ profileErrors.mobile_phone }}
          </span>
          <span
            v-if="profileFormFirstValidator.mobile_phone.$errors[0] && !profileErrors.mobile_phone"
            class="errorText"
          >
            {{ profileFormFirstValidator.mobile_phone.$errors[0].$message }}
          </span>
        </div>

        <div
          class="formGroup modalTwoBlock"
          :class="{
            formGroupError: !!profileFormFirstValidator.gender.$errors[0],
          }"
        >
          <span class="formCheckbox">
            <input
              id="checkbox1"
              v-model="profileForm.gender"
              value="m"
              type="radio"
              name="checkbox1"
              class="inputFormCheckbox"
              :placeholder="profileFormData.male_title"
            />
            <label for="checkbox1" class="inputFormCheckboxLabel">
              {{ regFormData.label_text_male || 'Male' }}
            </label>
          </span>
          <span class="formCheckbox">
            <input
              id="checkbox2"
              v-model="profileForm.gender"
              value="f"
              type="radio"
              name="checkbox2"
              class="inputFormCheckbox"
              :placeholder="profileFormData.male_title"
            />
            <label for="checkbox2" class="inputFormCheckboxLabel">
              {{ regFormData.label_text_female || 'Female' }}
            </label>
          </span>
        </div>
        <button
          class="formGroupButton"
          :title="profileFormData.save_button_title"
          @click.prevent="nextStep()"
        >
          {{ profileFormData.save_button_title }}
          <span class="icon-loader" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {
  useModalSignup,
  commonComponents,
  commonEmits,
  commonProps,
} from '@/composables/useModalSignup';

export default defineComponent({
  components: commonComponents,
  props: commonProps,
  emits: commonEmits,
  setup(props, { emit }) {
    const {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    } = useModalSignup(props, emit);

    return {
      storeAuth,
      close,
      changeCurrency,
      showPasswordToggle,
      toggleBonusShow,
      loadLocalizedUserData,
      updateProfileData,
      register,
      isStateFieldShown,
      redirectToWelcomeBonus,
      isErrorPostalCodeIncorrect,
      prevStep,
      toggle,
      openIndexList,
      nextStep,
      regFormData,
      profileFormData,
      isDesktop,
      isMobile,
      isTablet,
      abbr,
      template,
      redirectHost,
      ssHost,
      loginLink,
      statesList,
      activeCurrenciesOptions,
      step,
      nameImg,
      regErrors,
      regForm,
      showPassword,
      waitPlease,
      profileForm,
      profileErrors,
      step2fields,
      termsLink,
      passwordType,
      listYears,
      listMonths,
      listDays,
      birthDate,
      isBonusActive,
      url,
      width,
      height,
      countries,
      countriesList,
      regFormValidator,
      profileFormFirstValidator,
      passwordWeakness,
      clearProfileErrorField,
      mobile_phone_code,
      closeModal,
      activeCurrenciesOptionsIcons,
      selectedCurrencyIconUrl,
      isGoogleRegTemplate,
      isMiraxFrenchLand,
      lang,
      activeCurrencies,
      currentPage,
      currency,
      userCountry,
      passwordWeaknsssMessage,
      touchHeap,
      googleOAuthUrl,
    };
  },
});
</script>
<style lang="scss" src="./modalSignupLion.scss"></style>
