import { defineStore } from 'pinia';
import { useStoreAuth } from '../strapi/auth';
import { useBonusesStore } from '../strapi/bonuses';
import { useStoreButtonTitles } from '../strapi/buttonTitles';
import { useStoreComponents } from '../strapi/components';
import { useStoreCurrencyMenu } from '../strapi/currencyMenu';
import { useStoreFooter } from '../strapi/footer';
import { useStoreLanding } from '../strapi/landing';
import { useStoreLandingComponents } from '../strapi/landingsComponents';
import { useStoreRaces } from '../strapi/races';
import { useStoreSnippet } from '../strapi/snippet';
import { useStoreSystemHosts } from '../strapi/systemHosts';
import { useStoreLanguage } from './language';
import { useTransport } from './transport';
import { LangsType } from '@/types/pages.types';

export const useStoreStrapi = defineStore('strapi', {
  state: () => ({}),
  actions: {
    async loadSystemHostsData(): Promise<boolean> {
      const host = window.location.host;
      const response = await useTransport().getStrapi(
        'system-hosts',
        `locale=${useStoreLanguage().language}&filters[host][$eq]=${host}&pagination[pageSize]=1000`
      );

      if (response) {
        useStoreSystemHosts().SAVE_HOSTS_FROM_STRAPI(response.data);
        return true;
      } else {
        return false;
      }
    },

    async loadLandingsData(path: string): Promise<boolean> {
      try {
        const [landingsResponse, currencyMenusResponse] = await Promise.all([
          useTransport().getStrapi('landing/' + path),
          useTransport().getStrapi('currency-menus', 'populate[0]=currencyIcon'),
        ]);

        useStoreLanguage().setLocale(useStoreLanguage().language as LangsType);

        if (landingsResponse && currencyMenusResponse) {
          useStoreLanding().ADD_PAGES_FROM_STRAPI(landingsResponse.landings);
          useStoreCurrencyMenu().SAVE_CURRENCIES_FROM_STRAPI(currencyMenusResponse.data);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading landing data:', error);
        return false;
      }
    },

    async loadBonusesAndRacesData(): Promise<boolean> {
      try {
        const locale = useStoreLanguage().language;
        const [bonusesResponse, racesResponse, bonusesTabsResponse] = await Promise.all([
          useTransport().getStrapi(
            'bonuses',
            `locale=${locale}&populate[0]=bonusType&populate[1]=bannerCardFront&populate[2]=bannerCardBack`
          ),
          useTransport().getStrapi(
            'races',
            `locale=${locale}&populate[0]=bannerCardBack&populate[1]=bannerCardFront&populate[2]=bannerSmallMobile&populate[3]=bannerSmall`
          ),
          useTransport().getStrapi('bonuses-tabs', `locale=${locale}`),
        ]);

        if (bonusesResponse && racesResponse && bonusesTabsResponse) {
          useBonusesStore().SAVE_DATA_FROM_STRAPI({
            ...bonusesResponse,
            bonusesTabs: bonusesTabsResponse.data,
          });
          useStoreRaces().SAVE_DATA_FROM_STRAPI(racesResponse.data);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading bonuses and races data:', error);
        return false;
      }
    },

    async loadCommonData(): Promise<boolean> {
      try {
        const locale = useStoreLanguage().language;
        const [
          componentResponse,
          buttonTitleResponse,
          snippetResponse,
          authModalResponse,
          footerResponse,
          landComponentsResponse,
        ] = await Promise.all([
          useTransport().getStrapi(
            'component',
            `locale=${locale}&populate[races][fields][0]=raceLabelWeeklyTitle&populate[races][fields][1]=raceLabelDailyTitle&populate[races][fields][2]=raceLabelExclusiveTitle&populate[races][fields][3]=raceLabelPartnerTitle&populate[timer][fields][0]=leftTitle&populate[timer][fields][1]=daysTitle&populate[timer][fields][2]=hoursTitle&populate[timer][fields][3]=minutesTitle&populate[timer][fields][4]=secondsTitle`
          ),
          useTransport().getStrapi(
            'button-title',
            `locale=${locale}&fields[0]=signup&fields[1]=learnMore`
          ),
          useTransport().getStrapi('snippet'),
          useTransport().getStrapi('auth-modal', `locale=${locale}&populate=*`),
          useTransport().getStrapi(
            'footer',
            `locale=${locale}&populate[0]=logo&populate[1]=informationLink&populate[2]=promotionsLink&populate[3]=gamesLink&populate[4]=helpLink&populate[5]=worldwideLink&populate[6]=europeLink&populate[7]=blogLink&populate[8]=responsibleGamingLink&populate[9]=gamesSuppliedLink&populate[10]=gamesSuppliedLink.img_src&populate[11]=paymentMethodsLink&populate[12]=paymentMethodsLink.img_src&populate[13]=partnersLink&populate[14]=partnersLink.img_src&populate[15]=gamblers&populate[16]=gamblers.img_src&populate[17]=partnersLogo&populate[18]=mirrorFooter&pagination[pageSize]=1000`
          ),
          useTransport().getStrapi(
            'landing-component',
            `locale=${locale}&populate[0]=signUpCloseConfirm`
          ),
        ]);

        if (
          componentResponse &&
          buttonTitleResponse &&
          snippetResponse &&
          authModalResponse &&
          footerResponse &&
          landComponentsResponse
        ) {
          useStoreComponents().SAVE_DATA_FROM_STRAPI(componentResponse);
          useStoreButtonTitles().SAVE_DATA_FROM_STRAPI(buttonTitleResponse);
          useStoreSnippet().SAVE_DATA_FROM_STRAPI(snippetResponse.data);
          useStoreAuth().SAVE_DATA_FROM_STRAPI(authModalResponse);
          useStoreFooter().SAVE_DATA_FROM_STRAPI(footerResponse);
          useStoreLandingComponents().SAVE_DATA_FROM_STRAPI(landComponentsResponse);

          return true;
        } else {
          return false;
        }
      } catch (error) {
        console.error('Error loading common data:', error);
        return false;
      }
    },
  },
});
