<template>
  <div
    v-if="storeLanding.isLoaded && currentPage"
    class="modern-container modernMirax"
    :data-theme="currentPage.theme"
  >
    <div v-if="show" class="modalLoginMirax modal-overlay">
      <transition :duration="{ enter: 500, leave: 200 }" name="globalModalAnimation">
        <ModalComponent modal="signup">
          <ModalSignupMirax
            :first-title="secondTitle"
            :second-title="template.thirdTitle"
            :third-title="template.fourthTitle"
            modalType="short"
            @change-currency="onChangeCurrency"
          />
        </ModalComponent>
      </transition>
    </div>

    <a href="#" class="logoMirax">
      <img :src="logoImg.url" alt="logoMirax" />
    </a>
    <div class="modernWrapperMiraxOverlay">
      <!-- <div class="modernBgOverlay" :style="bannerUrl">&nbsp;</div> -->
      <img class="modernBgOverlay" :src="bannerUrl" />

      <div class="modern-wrapper" v-bind="dataPlatformAttr" :style="bannerUrl">
        <img class="modernMiraxBannerBg" :src="bannerUrl" />
        <div v-if="isDesktop" class="modern-head">
          <div class="modern-head-content">
            <Markdown
              :auto-link-parse="false"
              :text="template.firstTitle"
              class="modern-head__title-top"
            />
            <Markdown :auto-link-parse="false" :text="secondTitle" class="modern-head__title" />
            <Markdown
              :auto-link-parse="false"
              :text="template.thirdTitle"
              class="modern-head__text"
            />
            <Markdown
              v-if="template.fourthTitle"
              :auto-link-parse="false"
              :text="template.fourthTitle"
              class="modern-head__title"
            />

            <div
              v-if="template.bonusCode"
              class="miraxBonusCodeTempate"
              @click="copyToClipboard(template.bonusCode)"
            >
              <div class="miraxBonusCodeText" :class="{ active: isCopiyed }">
                {{ template.bonusCode }}
                <span v-if="isCopiyed">
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.74264 5.65685L1.91421 2.82843L0.5 4.24264L4.74264 8.48528L11.8137 1.41421L10.3995 0L4.74264 5.65685Z"
                      fill="white"
                    />
                  </svg>
                  {{ template.copiedText }}
                </span>
                <span v-else-if="template.bonusCode">{{ template.copyBonusCodeText }}</span>
              </div>
            </div>

            <button class="modern-custom-button" @click.prevent="registerHandler">
              <span class="modern-custom-button-text">{{ registerTitle }}</span>
            </button>
          </div>
        </div>
      </div>

      <div v-if="isMobile || isTablet" class="modern-head">
        <div class="modern-head-content">
          <Markdown
            :auto-link-parse="false"
            :text="template.firstTitle"
            class="modern-head__title-top"
          />
          <Markdown :auto-link-parse="false" :text="secondTitle" class="modern-head__title" />
          <Markdown
            :auto-link-parse="false"
            :text="template.thirdTitle"
            class="modern-head__text"
          />
          <Markdown
            v-if="template.fourthTitle"
            :auto-link-parse="false"
            :text="template.fourthTitle"
            class="modern-head__title"
          />

          <div
            v-if="template.bonusCode"
            class="miraxBonusCodeTempate"
            @click="copyToClipboard(template.bonusCode)"
          >
            <div class="miraxBonusCodeText" :class="{ active: isCopiyed }">
              {{ template.bonusCode }}
              <span v-if="isCopiyed">
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.74264 5.65685L1.91421 2.82843L0.5 4.24264L4.74264 8.48528L11.8137 1.41421L10.3995 0L4.74264 5.65685Z"
                    fill="white"
                  />
                </svg>
                {{ template.copiedText }}
              </span>
              <span v-else-if="template.bonusCode">{{ template.copyBonusCodeText }}</span>
            </div>
          </div>

          <button class="modern-custom-button" @click.prevent="registerHandler">
            <span class="modern-custom-button-text">{{ registerTitle }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="modern-stepe-container">
      <div class="modern-steper">
        <div class="modern-steper__block">
          <span class="modern-steper__number">1.</span>
          <Markdown
            :auto-link-parse="false"
            :text="template.firstStep"
            class="modern-steper__center"
          />
        </div>
        <div class="modern-steper__block">
          <span class="modern-steper__number">2.</span>
          <Markdown
            :auto-link-parse="false"
            :text="template.secondStep"
            class="modern-steper__center"
          />
        </div>
        <div class="modern-steper__block">
          <span class="modern-steper__number">3.</span>
          <Markdown
            :auto-link-parse="false"
            :text="template.thirdStep"
            class="modern-steper__center"
          />
        </div>
      </div>
    </div>

    <div class="modern-supplied">
      <h3>Partners</h3>
      <span>
        <img class="modern-supplied__img" src="~assets/mirax/askgamblers.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/bitcoinplay.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/casinos_analyzer.svg" alt="img" />
      </span>
    </div>
    <div class="modern-supplied modern-supplied__bottom">
      <h3>Casino payment methods</h3>
      <span>
        <img class="modern-supplied__img" src="~assets/mirax/BTC.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/NeoSurf.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/MiFinity.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/Visa_Debit_SVG_logo.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/Mastercard-logo.png" alt="img" />
        <img class="modern-supplied__img" src="~assets/mirax/coinpad.png" alt="img" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import Markdown from '@/components/markdown.vue';
import ModalComponent from '@/components/modalComponent/modalComponent.vue';
import ModalSignupMirax from '@/components/modalSignup/modalSignupMirax.vue';
import usePromoModal from '@/composables/promoModalState';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { copyText } from '@/utils/vue3-clipboard2';
import { RegFormInterface } from '~/types/regForm.interface';

const storeSsPlayer = useStoreSsPlayer();
const storeRegForm = useStoreRegForm();
const storeCountry = useStoreCountry();
const storeLanding = useStoreLanding();
const storeSystemHosts = useStoreSystemHosts();
const storeCurrencyMenu = useStoreCurrencyMenu();

const template = computed(() => storeLanding.currentPage?.template);

const { dataPlatformAttr, isDesktop, isTablet, isMobile } = useStorePlatform();

const { logoImg, secondTitle, bannerUrl, currentPage } = useStoreLanding();

const onChangeCurrency = (newCurrency: string) => {
  storeLanding.setCurrency(newCurrency);
};

const loadLocalizedUserData = () => {
  if (storeCountry.userCountry) {
    if (Array.isArray(storeLanding.secondTitle)) {
      const titleForCountry = storeLanding.secondTitle.find(
        (c) => c.country === storeCountry.userCountry
      );
      if (titleForCountry) {
        storeLanding.setCurrency(titleForCountry.currency);
        return;
      }
    }

    const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
      currency.defaultForCountries.some((country) => country === storeCountry.userCountry)
    );

    if (storeLanding.currency) {
      storeLanding.setCurrency(storeLanding.currency);
    } else if (defaultCurrencyForCountry?.currencyCode) {
      storeLanding.setCurrency(defaultCurrencyForCountry.currencyCode);
    } else {
      storeLanding.setCurrency(storeCurrencyMenu.activeCurrencies[0]?.currencyCode);
    }
  }
};

watch(() => storeCurrencyMenu.activeCurrencies, loadLocalizedUserData, { immediate: true });

const { openModal, show } = usePromoModal();

const isPlayerAuthorised = computed((): boolean => storeSsPlayer.isPlayerAuthorised);

const registerHandler = () => {
  if (isPlayerAuthorised.value) {
    window.location.href = `https://${storeSystemHosts.redirectHost}`;
  } else {
    openModal('mirax');
  }
};

const regForm = computed((): RegFormInterface => storeRegForm.formData);

const registerTitle = computed((): string => {
  return template.value?.formButtonText || regForm.value.register_button_title;
});

const isCopiyed = ref(false);

watch(
  () => isCopiyed.value,
  () => {
    if (isCopiyed.value) {
      window.setTimeout(() => {
        isCopiyed.value = false;
      }, 3000);
    }
  }
);

const copyToClipboard = async (text: string) => {
  try {
    await copyText(text);
    isCopiyed.value = true;
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  document.body.classList.add('modern2SquareLand');
});
</script>

<style lang="scss">
/*******************
  Style login modal
*******************/
.modalLoginMirax {
  &.modal-overlay {
    background-color: rgba(17, 16, 19, 0.5);
  }
  .bonus-code {
    margin-top: -14px;
    margin-bottom: -5px !important;
  }
  .modal-title {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 600;
    letter-spacing: 1.4px;
  }
  .showFormMirax {
    display: inline-block !important;
  }
  .form-group {
    margin-bottom: 4px;
    &.form-checkbox {
      padding: 0 10px;
      margin-top: -11px;
    }
  }
  .modal-banner {
    width: 49%;
  }
  .home-slider-item-element-img {
    border-radius: 0;
    width: 100%;
  }
  .form-password__icon {
    display: none;
    &.form-password__show-password {
      display: inline-block;
    }
  }
  .marax-form-password__icon {
    display: inline-block !important;
  }
  .input,
  .form-dropdown__name {
    background: #111013;
    border: 1px solid rgba(76, 76, 78, 0.4);
    border-radius: 4px;
    height: 40px;
    width: 100%;
    filter: none;
    color: #fff;
    &:hover,
    &.active {
      border-color: #4c4c4e;
    }
    &::-moz-placeholder {
      color: #888;
    }
    &:-ms-input-placeholder {
      color: #888;
    }
    &::placeholder {
      color: #888;
    }
  }
  .form-dropdown__name {
    border-radius: 4px;
  }
  .placeholderFocusInput {
    border-radius: 4px;
  }
  .hideFormMirax {
    display: none;
  }
  .form-dropdown__item {
    color: #888;
    background-color: #111013;
  }
  .form-checkbox__label {
    color: #a3a3a3;
    font-weight: 300;
  }
  .form-switchMirax {
    display: none;
  }
  .form-checkbox__label::before {
    width: 20px;
    height: 20px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    border-color: transparent;
    background: rgba(255, 255, 255, 0.1);
  }
  .form-group__bonus,
  .form-group__bonusMirax {
    display: none !important;
  }
  [data-modal-name='signup'] .form-group__bonus-not {
    margin: 0 0 15px 0;
  }
  .form-checkbox:hover .form-checkbox__label::before {
    border-color: #fff;
  }
  .form-checkbox__label::after {
    top: 6px;
    left: 5px;
    background: url(~assets/mirax/checkMirax.svg) no-repeat center;
  }
  .form-checkbox__checkbox:checked + .form-checkbox__label {
    color: #a3a3a3;
  }
  [data-modal-name='signup'] .modal-content {
    width: 810px;
    box-shadow: none;
  }
  .modal-first-step,
  .modal-two-step,
  .modal-three-step,
  .modal-four-step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 42px 10px 43px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    background: linear-gradient(180deg, #08070a 0%, rgba(33, 31, 41, 0) 100%), rgb(22 19 27);
    backdrop-filter: blur(4px);
    min-height: 530px;
  }
  .home-slider__wrapper[data-slider-type='signUp'] {
    .home-slider {
      height: 530px;
    }
  }
  .modal-two-block .form-checkbox label::before {
    border-color: transparent;
  }
  .button.loader[disabled] {
    margin: 0 auto;
  }
  .modal-phone .vue-tel-input {
    border-color: rgba(76, 76, 78, 0.4);
  }
  .modal-phone .vti__input {
    height: 34px;
    background: transparent;
  }
  .modal-content {
    border-radius: 0;
  }
  .link {
    color: rgba(89, 187, 27, 0.6);
    text-decoration: none;
  }
  .form-checkbox__checkbox:checked + .form-checkbox__label::before {
    border-color: #fff;
  }
  .modal-close-modal {
    right: 23px;
    top: 16px;
    background: url(~assets/mirax/closeMirax.svg) no-repeat center;
  }
  .form-password__icon {
    top: 10px;
    right: 22px;
  }
  .placeholder {
    top: 8px;
    color: #888;
  }
  .form-group__bonus-label {
    line-height: normal;
    color: #5470fa;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    left: 20px;
    position: absolute;
    top: -9px;
    background-color: #111013;
    border-radius: 4px;
  }
  .placeholder-focus {
    top: -10px;
    line-height: normal;
    color: #5470fa;
    font-size: 14px;
    font-weight: 300;
    background-color: #111013;
    border-radius: 4px;
  }
  .modernMirax {
    display: none;
  }
  .form-dropdown__icon {
    top: 16px;
    right: 22px;
    path {
      fill: #fff;
      fill-opacity: 1;
    }
  }
  .select {
    height: 34px;
    background-color: #111013 !important;
    border-color: rgba(76, 76, 78, 0.4);
  }
  .button {
    font-size: 16px;
    text-transform: capitalize;
    min-width: 136px;
    background: #5470fa;
    border: 1px solid #5470fa;
    border-radius: 4px;
  }
  .form-group__bonusMiraxShow {
    display: block !important;
  }
}
/*******************
  Style login modal
*******************/
.modernMirax {
  .home-sliderFirstTitle,
  .home-sliderSecondTitle,
  .home-sliderThirdTitle {
    margin: 0;
    font-weight: 900;
    font-size: 37px;
    text-shadow: -1px 0 #090516, 0 1px #090516, 1px 0 #090516, 0 -1px #090516;
  }
  .home-sliderSecondTitle,
  .home-sliderThirdTitle {
    display: inline-block;
  }
  .home-sliderFirstTitle {
    color: #ffd600;
  }
  .home-sliderSecondTitle {
    color: #fff;
    font-weight: 400;
    margin-right: 10px;
  }
  .home-sliderThirdTitle {
    color: #ffd600;
  }
  .logoMirax {
    display: block;
    padding: 20px 0 8px;
    text-align: center;
    z-index: 1;
    position: relative;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      padding: 20px 0 9px;
    }
  }
  &.modern-container {
    padding: 0;
    position: relative;
    background-color: #08070a;
  }
  .modern-wrapper {
    position: relative;
    // background-repeat: no-repeat;
    // background-position: top;
    // background-size: cover;
    // background-image: var(--bg-img-desktop);
    max-width: 1560px;
    min-height: 600px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 80px;
    margin: 0 auto 49px;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      margin: 0 auto 48px;
    }
    .modernMiraxBannerBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 80px;
      object-position: top;
    }
    .modern-video-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      max-height: 650px;
      min-width: 100%;
      @media only screen and (min-width: 1400px) and (max-width: 1600px) {
        left: -240px;
      }
      @media only screen and (min-width: 990px) and (max-width: 1400px) {
        left: -450px;
      }
      @media only screen and (min-width: 768px) and (max-width: 990px) {
        left: -510px;
      }
    }
  }
  .modernWrapperMiraxOverlay {
    position: relative;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      padding: 0 21px;
    }
  }
  .modernBgOverlay {
    position: absolute;
    opacity: 0.2;
    pointer-events: none;
    left: 0;
    right: 0;
    top: -104px;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    mix-blend-mode: luminosity;
    width: 100%;
    height: 100%;
    display: block;
  }
  .modern-label-icon {
    display: flex;
    right: 30px;
    top: 50%;
    position: absolute;
    flex-direction: column;
    @media only screen and (max-width: $tablet-media-991) {
      display: none;
    }
    &__img {
      &:not(:last-child) {
        margin-bottom: 10px;
        @media only screen and (max-width: $tablet-media-991) {
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
    }
  }
  .modern-head {
    text-align: left;
    &__title-top {
      position: relative;
      z-index: 1;
      p {
        color: $color-modern-white;
        font-weight: 400;
        font-size: 26px;
        margin: 0 0 11px 0;
        line-height: 29px;
      }
    }
    &__img {
      margin: 0 0 148px 4%;
      max-width: 154px;
      width: 100%;
      position: relative;
      z-index: 1;
    }
    &__title {
      position: relative;
      z-index: 1;
      p {
        color: $color-modern-white;
        font-weight: 900;
        margin: 0 0 3px 0;
        font-size: 64px;
        line-height: 72px;
        color: #ffd600;
      }
      b,
      em {
        color: #ffd600;
        font-style: normal;
      }
    }
    &__text {
      p {
        font-size: 26px;
        position: relative;
        margin: 0;
        line-height: 30px;
        z-index: 1;
        color: $color-modern-white;
      }
      &-bottom {
        p {
          margin: 0;
          line-height: 72px;
          font-weight: 900;
          font-size: 64px;
          color: #ffd600;
        }
      }
    }
  }
  .modern-head-content {
    margin-right: 220px;
    float: right;
    text-align: center;
    max-width: 530px;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      margin-right: 104px;
    }
  }
  .modern-custom-button {
    font-weight: 900;
    font-size: 31px;
    color: #fff;
    cursor: pointer;
    margin-top: 30px;
    text-align: center;
    padding: 17px 10px;
    min-width: 260px;
    background: linear-gradient(180deg, rgba(80, 107, 238, 0.6) 0%, rgba(16, 57, 255, 0.6) 100%),
      url(~assets/mirax/btn-pattern_mirax.svg), #5470fa;
    border-radius: 8px;
    transition: 0.2s linear;
    &:active {
      color: rgba(255, 255, 255, 0.69);
    }
    &:hover,
    &:active {
      text-shadow: 1px 1px 0 #0024d4, -1px 1px 0 #0024d4, 1px -1px 0 #0024d4, -1px -1px 0 #0024d4,
        0px 1px 0 #0024d4, 0px -1px 0 #0024d4, -1px 0px 0 #0024d4, 1px 0px 0 #0024d4,
        2px 2px 0 #0024d4, -2px 2px 0 #0024d4, 2px -2px 0 #0024d4, -2px -2px 0 #0024d4,
        0px 2px 0 #0024d4, 0px -2px 0 #0024d4, -2px 0px 0 #0024d4, 2px 0px 0 #0024d4,
        1px 2px 0 #0024d4, -1px 2px 0 #0024d4, 1px -2px 0 #0024d4, -1px -2px 0 #0024d4,
        2px 1px 0 #0024d4, -2px 1px 0 #0024d4, 2px -1px 0 #0024d4, -2px -1px 0 #0024d4;
      background: linear-gradient(180deg, rgba(44, 79, 250, 0.6) 0%, rgba(0, 36, 213, 0.6) 100%),
        url(~assets/mirax/btn-pattern_mirax.svg), #5470fa;
    }
    &-text {
      color: #fff;
    }
  }
  .reg-form-wrapper.modern-form {
    .reg-form {
      width: 386px;
      padding: 21px 32px 13px 32px;
      margin: 0 auto;
      background: $color-modern-form;
      box-shadow: 20.7171px 36.9949px 66.5908px rgba(23, 18, 43, 0.45);
      border-radius: 10px;
      -webkit-backdrop-filter: blur(25px);
      backdrop-filter: blur(25px);
      position: relative;
      z-index: 1;
      @media only screen and (max-width: $mobile-media-480) {
        width: 100%;
        margin: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 21px 25px 10px 30px;
        background-color: $color-modern-input-bg-error;
      }
      .form-heading {
        display: none;
      }
      .input-area-icon {
        display: none;
      }
      .input-area {
        position: relative;
        margin-bottom: 1px;
      }
      .reg-form-label {
        color: $color-modern-label;
        font-size: $font-modern-label;
        font-weight: 300;
        text-align: left;
        display: block;
        margin-bottom: 5px;
      }
      .modern-form__group-password-eye {
        display: inline-block;
        position: absolute;
        right: 8px;
        top: 50%;
        cursor: pointer;
        margin-top: 14px;
        transform: translateY(-50%);
        &.showPassword {
          background: url(~assets/images/promo/eye-closed.svg) no-repeat;
        }
      }
      .modern-form__group-password-bar {
        width: 125px;
        height: 4px;
        border-radius: 2px;
        background-color: $black-alpha-30;
        position: relative;
        &-line {
          left: 2px;
          top: 1px;
          position: absolute;
          width: 20px;
          display: inline-block;
          height: 2px;
          border-radius: 2px;
          background-color: $color-modern-input-error-bar;
        }
      }
      .modern-form__two-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media only screen and (max-width: $mobile-media-480) {
          flex-direction: column;
        }
        .input-area {
          width: auto;
          @media only screen and (max-width: $mobile-media-480) {
            width: 100%;
          }
        }
      }
      .input-area-field-text {
        height: 40px;
        padding: 5px 15px;
        color: $color-modern-white;
        background: $color-modern-input;
        border: 1px solid $color-modern-input;
        border-radius: 4px;
        font-size: $font-modern-placeholder;
        &:focus,
        &:hover,
        &:active {
          outline: none;
          border-color: $color-modern-input-hover;
        }
      }
      .input-area-checkbox__square {
        width: 20px;
        height: 20px;
        border-radius: 2px;
        display: inline-block;
        border: 1px solid rgba(151, 150, 207, 0.7);
        background-color: transparent;
        transition: 0.2s linear;
        &::after {
          width: 12px;
          height: 12px;
          top: 3px;
          left: 3px;
          position: absolute;
          border-radius: 1px;
          opacity: 0;
          background: none;
          background-color: $color-modern-link;
        }
      }
      .terms-block {
        padding: 0;
        margin: 5px 0 0 20px;
        @media only screen and (max-width: $mobile-media-480) {
          width: 100%;
          margin: 5px 0 16px 0;
        }
      }
      .input-area-checkbox {
        position: relative;
        padding-left: 32px;
        cursor: pointer;
        margin-top: 0;
        font-size: $font-modern-link;
        color: $color-modern-input-hover;
        &:hover {
          .input-area-checkbox__square {
            border-color: $color-modern-label;
          }
        }
      }
      .terms-checkbox {
        &:checked {
          + .input-area-checkbox__square {
            border-color: $color-modern-label;
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .terms-link {
        font-size: $font-modern-label;
        text-decoration: none;
        color: $color-modern-link;
        &:hover {
          color: $color-modern-btn-active;
        }
      }
      .reg-form-sign-in-block {
        text-decoration: none;
        margin-top: 7px;
        @media only screen and (max-width: $mobile-media-767) {
          padding-top: 10px;
        }
        .terms-link {
          display: none;
        }
      }
      .modern-terms-link {
        display: block;
        color: $color-modern-input-hover;
        font-size: $font-modern-placeholder;
        text-decoration: none;
        .terms-link {
          display: inline-block;
        }
      }
      .input-area-placeholder {
        top: 35px;
        left: 16px;
        color: $color-modern-input-hover;
        font-size: $font-modern-placeholder;
      }
      .input-area-error-message {
        font-size: $font-modern-error;
        color: $color-modern-input-error;
        display: block;
        position: relative;
        text-align: left;
        &.password-error-yellow {
          color: $color-modern-yellow;
          .modern-form__group-password-bar .modern-form__group-password-bar-line {
            background-color: $color-modern-yellow;
            width: 50%;
          }
        }
        &.password-error-green {
          color: $color-modern-link;
          .modern-form__group-password-bar .modern-form__group-password-bar-line {
            background-color: $color-modern-link;
            width: 97%;
          }
        }
      }
      .input-area-error {
        .input-area-field-text {
          border-color: $color-modern-input-error;
          color: $color-modern-input-error;
          background-color: $color-modern-input-bg-error;
        }
        .modern-form__group-password-eye {
          margin-top: 2px;
        }
        .input-area-error-message-password {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .signup-button {
        display: none;
      }
      .modern-form__btn {
        font-size: $font-modern-btn;
        color: $color-modern-white;
        text-transform: uppercase;
        border-radius: 4px;
        min-width: 240px;
        padding: 11px 10px;
        box-shadow: none;
        outline: none;
        border: none;
        position: relative;
        cursor: pointer;
        transition: 0.2s linear;
        background-color: transparent;
        display: inline-block;
        &::before {
          border: 1px solid $color-modern-link;
          transform: scale3d(0.7, 0.7, 1);
          border-radius: 4px;
        }
        &::after {
          border-radius: 4px;
          background-color: $color-modern-link;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: transform 0.3s, background-color 0.3s;
          transition-timing-function: cubic-bezier(0.25, 0, 0.3, 1);
        }
        &:hover {
          &::before {
            transform: scale3d(1, 1, 1);
          }
          &::after {
            transform: translate(1px, 1px) scale3d(0.98, 0.9, 1);
            border-radius: 1px;
          }
        }
        &:active {
          background-color: $color-modern-btn-active;
          &::before,
          &::after {
            opacity: 0;
          }
        }
        &-img {
          margin-right: 10px;
        }
        &-span {
          position: relative;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .input-area-field-select {
        display: inline-block;
        padding: 8px 15px;
        font-size: $font-modern-placeholder;
        color: $color-modern-white;
        background: $color-modern-input url(~assets/images/promo/arrow-down.svg) no-repeat 95%
          center;
        border: 1px solid $color-modern-label;
        box-sizing: border-box;
        height: 40px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        width: 128px;
        @media only screen and (max-width: $mobile-media-480) {
          width: 100%;
        }
      }
      .form-provider-img {
        display: none;
      }
      .modern-form__text {
        text-align: center;
        color: rgba(151, 150, 207, 0.5);
        font-size: $font-modern-link;
        margin: 11px 0 15px 0;
        display: block;
      }
      .modern-form__footer {
        text-align: center;
        display: inline-block;
        color: $color-modern-label;
        font-size: $font-modern-placeholder;
        &-link {
          font-weight: 600;
          color: $color-modern-link;
          text-decoration: none;
          transition: 0.2s linear;
          &:hover {
            color: $color-modern-btn-active;
          }
        }
      }
      .modern-form__select-choice {
        width: 100%;
        display: block;
        padding: 13px 15px;
        text-align: left;
        font-size: $font-modern-placeholder;
        color: $color-modern-white;
        background-color: $color-modern-input;
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        &:hover {
          border-color: $color-modern-label;
        }
        &::before {
          content: '';
          width: 13px;
          height: 8px;
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 12px;
          transform: translateY(-50%);
          background: url(~assets/images/promo/arrow-down.svg) no-repeat;
        }
      }
      .modern-form__select-list {
        padding: 0;
        margin: 0;
        display: none;
        list-style: none;
        position: absolute;
        width: 100%;
        z-index: 3;
        text-align: left;
        margin-top: -2px;
        background: $color-modern-input;
        border: 1px solid $color-modern-input-hover;
        border-radius: 0 0 4px 4px;
      }
      .modern-form__select-item {
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: $font-modern-placeholder;
        color: $white-alpha-70;
        cursor: pointer;
        transition: 0.2s linear;
        &:hover {
          opacity: 0.7;
        }
        &:nth-child(even) {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
      .modern-form__select-group {
        display: block;
        @media only screen and (max-width: $tablet-media-991) {
          display: none;
        }
        &[data-select] {
          .modern-form__select-list {
            display: block;
          }
          .modern-form__select-choice {
            border-color: $color-modern-label;
            &::before {
              transform: translateY(-50%) rotate(180deg);
            }
          }
        }
      }
    }
  }
  .modern-steper__number {
    font-weight: 700;
    font-size: 31px;
    margin-right: 7px;
    line-height: normal;
    color: #5470fa;
    display: inline-block;
  }
  .modern-steper__block {
    display: flex;
    align-items: baseline;
    width: 33.3%;
    position: relative;
    justify-content: flex-start;
    padding: 14px 30px 16px 46px;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      padding: 14px 30px 18px 46px;
    }
  }
  .modern-stepe-container {
    max-width: 1290px;
    margin: 0 auto 20px;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      padding: 0 21px;
      margin: 0 auto 20px;
    }
  }
  .modern-steper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 0 0 20px 0;
    z-index: 2;
    position: relative;
    background: url(~assets/mirax/step.png) no-repeat center;
    background-size: 100% 100%;
    @media screen and (min-width: 1248px) and (max-width: 1350px) {
      margin: 0 0 42px 0;
    }
    p {
      font-weight: 700;
      margin: 0;
      font-size: 31px;
      line-height: 25px;
      color: #5470fa;
    }
    em {
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      color: #fff;
      line-height: 22px;
      margin-top: 5px;
      display: inline-block;
      @media screen and (min-width: 1248px) and (max-width: 1350px) {
        margin-top: 5px;
      }
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-modern-white;
      font-size: 41px;
      font-weight: 500;
      line-height: normal;
      margin: 0 0 5px 0;
    }
  }
  .modern-supplied {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    max-width: 100%;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      //animation: slider_banner 40s infinite linear;
      //animation-direction: alternate;
    }
    h3 {
      color: rgba(255, 255, 255, 0.4);
      font-weight: 400;
      font-size: 24px;
      text-align: center;
      line-height: 30px;
      margin-bottom: 45px;
      @media screen and (min-width: 1248px) and (max-width: 1350px) {
        margin-top: 0;
      }
    }
    &__img {
      margin: 0 2%;
      filter: grayscale(1);
      max-width: 240px;
      height: auto;
      @media screen and (min-width: 1248px) and (max-width: 1350px) {
        margin: 0 3%;
      }
    }
    &__bottom {
      padding-bottom: 100px;
      margin: 43px auto 0;
      @media screen and (min-width: 1248px) and (max-width: 1350px) {
        margin: 62px auto 0;
        h3 {
          margin-bottom: 55px;
        }
      }
      img {
        opacity: 0.4;
      }
    }
  }
  .modern-footer {
    display: flex;
    padding: 0 65px 72px 65px;
    margin: 0 auto;
    align-items: flex-start;
    @media only screen and (max-width: $mobile-media-480) {
      padding: 0 12px 72px 12px;
    }
    &__text {
      display: inline-block;
    }
    &__img {
      margin: 17px 46px 0 16px;
      @media only screen and (max-width: $mobile-media-480) {
        margin: 3px 20px 0 0;
      }
    }
    p {
      color: $white-alpha-30;
      font-size: 17px;
      line-height: 23px;
      font-weight: 300;
      margin: 0 0 22px 0;
    }
    &__copyright {
      margin: 0;
    }
  }
  .miraxBonusCodeTempate {
    padding: 7px 0;
    margin: 28px auto 0;
    cursor: pointer;
    background: url(~assets/mirax/miraxBonusCode.png) no-repeat center;
    &:hover {
      background: url(~assets/mirax/miraxBonusCodeHover.png) no-repeat center;
    }
  }
  .miraxBonusCodeText {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    max-width: 205px;
    max-height: 52px;
    margin: 0 auto;
    line-height: 23px;
    span {
      font-weight: 400;
      display: block;
      text-transform: none;
    }
    &.active {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  // .miraxBonusCodeBlockImg {
  //   position: relative;
  //   width: 58px;
  //   height: 58px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 100%;
  //   margin: 4px 17px 4px 4px;
  //   background: linear-gradient(144.91deg, #F5F5F9 18.06%, #DADFE7 85.87%);
  //   &::before {
  //     content: '';
  //     position: absolute;
  //     width: 64px;
  //     height: 64px;
  //     top: -3px;
  //     left: -3px;
  //     border-radius: 100%;
  //     z-index: -1;
  //     background: linear-gradient(139.71deg, rgba(0, 0, 0, 0.4) 19.24%, rgba(255, 255, 255, 0.304) 86.09%);
  //     filter: blur(1px);
  //   }
  // }
  // .miraxBonusCodeText {
  //   font-size: 18px;
  //   font-weight: 600;
  //   color: #fff;
  //   text-align: left;
  //   span {
  //     font-weight: 300;
  //     font-size: 18px;
  //     display: block;
  //     color: rgba(255, 255, 255, 0.7);
  //   }
  // }
  // .miraxBonusCodeImg {
  //   width: 21px;
  // }
  // .miraxBonusCodeTempate {
  //   display: flex;
  //   height: 74px;
  //   min-width: 260px;
  //   max-width: 280px;
  //   margin: 15px auto 0;
  //   padding-right: 20px;
  //   align-items: center;
  //   backdrop-filter: blur(371.829px);
  //   background-color: #48319D;
  //   border-radius: 46px;
  //   border: 1px solid rgba(189, 106, 255, 0.33);
  //   cursor: pointer;
  //   transition: 0.2s linear;
  //   &:hover {
  //     background-color: #6866E9;
  //   }
  // }

  /*
  * Add color theme dark \ neon \ light
  */
  [data-theme='dark'] {
    .modern-head {
      .title,
      &__title,
      &__text {
        color: $color-black;
      }
    }
  }
  [data-theme='neon'] {
    .modern-head {
      .title,
      &__title,
      &__text {
        color: $color-white;
        text-shadow: $text-neon-shadow;
      }
    }
  }
  [data-theme='light'] {
    .modern-head {
      background-color: $black-alpha-20;
      .title,
      &__title,
      &__text {
        color: $color-white;
        text-shadow: $text-neon-shadow;
        font-weight: bold;
      }
    }
  }
}
/*
* Responsive Style
*/
.isDesktop {
  .modernMirax {
    .modernWrapperMiraxOverlay {
      padding: 0 20px;
    }
    .modern-head {
      position: absolute;
      display: inline-block;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    .modern-wrapper {
      @media screen and (min-width: 976px) and (max-width: 1500px) {
        min-height: 490px;
      }
    }
    .modern-head-content {
      @media screen and (min-width: 976px) and (max-width: 1500px) {
        margin-right: 80px;
      }
    }
  }
}

.isTablet {
  .modernMirax {
    .logoMirax {
      max-width: 110px;
      margin: 0 auto;
      padding: 10px 0 3px;
    }
    .modern-custom-button {
      margin-top: 24px;
    }
    .modernWrapperMiraxOverlay,
    .modern-stepe-container {
      padding: 0 16px;
    }
    .modernBgOverlay {
      height: calc(100% - 42%);
    }
    .modern-wrapper {
      height: 100vh;
      min-height: 380px;
      max-height: 381px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .modern-head {
      padding-top: 30px;
      height: 100%;
      padding-bottom: 45px;
      text-align: center;
    }
    .modern-head__img {
      margin: 0 auto 80px;
    }
    .modern-head__title p {
      font-size: 64px;
      line-height: normal;
      margin: 0 0 5px 0;
    }
    .modern-head__text p {
      margin: 0;
    }
    .modern-head-content {
      padding: 0;
      float: none;
      text-align: center;
      max-width: 100%;
      margin: 0 auto;
    }
    .modern-stepe-container {
      padding: 0;
      max-width: 650px;
      margin: 0 auto 29px;
    }
    .modern-steper {
      background-size: 100% 110%;
      flex-direction: column;
      margin-bottom: 0;
      background: transparent;
      em {
        margin-top: 3px;
      }
    }
    .modern-supplied h3 {
      margin-top: 0;
      margin-bottom: 2px;
    }
    .modern-steper__number {
      margin-right: 5px;
    }
    .modern-steper__block:not(:last-child)::before {
      width: 66px;
      height: 71px;
    }
    .modern-steper__block {
      padding: 14px 30px 17px 40px;
      margin-bottom: 15px;
      min-height: 110px;
      width: 100%;
      background: url(~assets/mirax/stepMobile.png) no-repeat;
      background-size: 100% 100%;
    }
    .modern-footer {
      padding: 0 20px 40px 20px;
    }
    .modern-supplied {
      margin: 0 auto 40px;
    }
    .modern-supplied__bottom {
      max-width: 605px;
      padding-bottom: 10px;
      margin: 0 auto;
    }
    .modern-footer p {
      font-size: 13px;
      line-height: 17px;
    }
    .modern-supplied span {
      padding: 21px 0;
      flex-wrap: wrap;
    }
    .modern-supplied__img {
      margin: 0 5% 3% 5%;
    }
    .modalLoginMirax {
      .modal-banner {
        width: 50%;
      }
      .modal-close-modal {
        right: 35px;
      }
      .modal-inside {
        width: 50%;
      }
    }
    @media screen and (min-width: 959px) {
      .logoMirax {
        max-width: 120px;
        padding: 21px 0 8px;
      }
      .modern-head {
        padding-top: 32px;
      }
      .modernWrapperMiraxOverlay {
        padding: 0 21px;
      }
      .modern-supplied {
        margin: 0 auto 36px;
        &.modern-supplied__bottom {
          margin: 0 auto;
        }
      }
    }
  }
  .modalLoginMirax {
    [data-modal-name='signup'] {
      .form-password__icon {
        right: 18px;
      }
    }
  }
  &.isAlbum {
    .modalLoginMirax {
      [data-modal-name='signup'] {
        .modal-content {
          width: 100%;
          max-width: 704px;
          .modal-banner {
            width: 43%;
          }
          .modal-inside {
            width: 57%;
          }
          .form-password__icon {
            right: 17px;
          }
        }
      }
    }
  }
  &.isPortrait {
    .modernMirax {
      .logoMirax {
        max-width: 81px;
        padding: 11px 0 4px;
      }
      .modern-head {
        right: 90px;
        padding-top: 0;
        padding-bottom: 0;
        position: absolute;
        z-index: 1;
        display: inline-block;
        width: auto;
        top: 50%;
        transform: translateY(-50%);
        height: auto;
      }
      .modern-wrapper {
        min-height: 400px;
        max-height: 401px;
      }
      .miraxBonusCodeTempate {
        margin: 8px auto 0;
      }
      .modern-head__title-top,
      .modern-head__text {
        p {
          font-size: 17px;
          margin: 0;
        }
      }
      .modern-head__title p {
        font-size: 43px;
        margin: 0;
      }
      .modern-custom-button {
        padding: 11px 10px;
        min-width: 173px;
        font-size: 20px;
        margin-top: 15px;
      }
      .modern-stepe-container {
        max-width: 100%;
        padding: 0 20px;
        margin: 32px auto 10px;
      }
      .modern-steper__block {
        min-height: 73px;
        padding: 8px 30px 12px 24px;
        background: url(~assets/mirax/stepMobileMirax.png) no-repeat;
        background-size: 100% 100%;
        width: 32%;
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
      }
      .modern-steper__number {
        font-size: 20px;
      }
      .modern-steper {
        flex-direction: row;
        p {
          font-size: 20px;
          line-height: 15px;
        }
        em {
          font-size: 12px;
          line-height: 15px;
          margin-top: 3px;
        }
      }
      .modern-supplied {
        h3 {
          font-size: 16px;
        }
      }
      .modern-supplied__img {
        max-width: 107px;
        margin: 0 4% 3% 4%;
      }
      .modern-supplied {
        margin: 0 auto 0;
      }
    }
  }
}
.isMobile {
  .modernMirax {
    .modern-stepe-container {
      margin: 0 auto 29px;
    }
    .modernWrapperMiraxOverlay,
    .modern-stepe-container {
      padding: 0 16px;
    }
    .logoMirax {
      max-width: 100px;
      margin: 0 auto;
      padding: 10px 0 3px;
    }
    .modern-head__title-top {
      margin: 0 0 8px 0;
    }
    .modern-wrapper {
      margin-bottom: 30px;
      min-height: 175px;
      max-height: 176px;
      border-radius: 32px;
      margin: 0 auto 24px;
    }
    .modernBgOverlay {
      height: calc(100% - 57%);
    }
    .modern-head-content {
      float: none;
      max-width: 100%;
      margin-right: 0;
    }
    .modern-head__img {
      margin: 0 auto 50px;
      max-width: 120px;
    }
    .modern-head {
      height: 100%;
      text-align: center;
      padding-top: 0;
      padding-bottom: 45px;
    }
    .modern-head__title p {
      font-size: 36px;
      margin: 0 0 6px 0;
      line-height: normal;
    }
    .modern-custom-button {
      margin-top: 24px;
      padding: 7px 10px;
      background: #5470fa;
    }
    .miraxBonusCodeTempate {
      margin: 21px auto 0;
    }
    .modern-head__text-bottom p {
      font-size: 36px;
      line-height: normal;
    }
    .modern-head__text p {
      margin: 0 0 6px 0;
    }
    .modern-steper {
      margin: 0 0 15px 0;
      flex-direction: column;
      box-shadow: none;
      background: none;
      p {
        line-height: 22px;
      }
      em {
        margin-top: 6px;
      }
    }
    .modern-steper__block {
      padding: 13px 25px 15px 41px;
      margin-bottom: 17px;
      min-height: 106px;
      width: 100%;
      background: url(~assets/mirax/stepMobileMirax.png) no-repeat;
      background-size: 100% 100%;
    }
    .modern-supplied h3 {
      margin-top: 0;
      margin-bottom: 25px;
    }
    .modern-supplied__bottom {
      h3 {
        margin-bottom: 35px;
      }
    }
    .modern-supplied span {
      padding: 0 0 21px 0;
      flex-direction: column;
    }
    .modern-supplied__img {
      margin: 0 0 22px 0;
    }
    .modern-supplied__bottom {
      margin: 0;
      padding-bottom: 90px;
      span {
        flex-direction: row;
        flex-wrap: wrap;
      }
      img {
        margin: 0 15px 20px 15px;
      }
    }
    .modern-footer p {
      font-size: 13px;
      line-height: 17px;
    }
    [data-modal-name='signup'] .modal-text {
      display: none !important;
    }
    @media only screen and (max-width: 350px) {
      .modern-head__text p {
        font-size: 18px;
      }
    }
    [data-modal-name='signup'] .modal-first-step,
    [data-modal-name='signup'] .modal-two-step,
    [data-modal-name='signup'] .modal-three-step,
    [data-modal-name='signup'] .modal-four-step {
      padding: 8px 0 10px 0;
      justify-content: flex-start;
    }
    [data-modal-name='signup'] .modal-content-signup {
      background: linear-gradient(180deg, #08070a 0%, rgba(33, 31, 41, 0) 100%), rgb(22 19 27);
      backdrop-filter: blur(4px);
    }
    [data-modal-name='signup'] .modal-content form {
      background-color: transparent;
    }
    [data-modal-name='signup'] .select {
      color: #fff;
      margin-bottom: 20px;
    }
    .modalLoginMirax {
      .modal-title {
        margin: 0 0 18px 0;
        letter-spacing: 0;
      }
      .form-group.form-checkbox {
        margin-top: -8px;
      }
    }
    .home-slider {
      &::before {
        display: none;
      }
    }
    .home-sliderText {
      max-width: 145px;
      padding: 0;
      transform: translateX(0);
      left: auto;
      right: 28px;
      top: 35px;
    }
    .home-sliderFirstTitle {
      font-size: 32px;
    }
    .home-sliderSecondTitle {
      font-weight: 400;
      font-size: 16px;
    }
    .home-sliderThirdTitle {
      font-weight: 400;
      font-size: 16px;
    }
  }
  &.isAlbum {
    .modernMirax {
      .modern-wrapper {
        padding-bottom: 0;
      }
      .home-slider__wrapper[data-slider-type='signUp'] {
        .home-slider {
          height: 170px !important;
        }
      }
    }
  }
  &.isPortrait {
    .modernMirax {
      .modern-wrapper {
        max-height: 340px;
        background-position: center;
        background-size: cover;
        min-height: 340px;
      }
      // .modern-head {
      //   position: absolute;
      //   bottom: 45px;
      //   padding: 0 20px;
      //   max-width: 340px;
      //   width: 100%;
      //   right: 40px;
      //   height: auto;
      // }
      .modern-steper {
        max-width: 450px;
        margin: 0 auto 15px;
      }
      .modern-custom-button {
        min-width: 173px;
        padding: 12px 10px;
        font-size: 20px;
        margin-top: 15px;
      }
      .miraxBonusCodeTempate {
        margin: 5px auto 0;
      }
      .modern-head__title-top,
      .modern-head__text {
        p {
          font-size: 17px;
          margin: 0;
        }
      }
      .modern-head__title {
        p {
          margin: 0;
        }
      }
      .modern-head__title p {
        font-size: 42px;
      }
    }
  }
  .modalLoginMirax {
    .modal-close-modal {
      right: 21px;
      top: 12px;
      width: 15px;
    }
  }
}
</style>
