import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { useTransport } from '../abstract/transport';

export const useStoreSsUpdateLocale = defineStore('ssUpdateLocale', {
  state: () => ({}),
  actions: {
    async patch() {
      try {
        useTransport().patchSs('/api/player/update_locale', {
          locale: useStoreLanguage().language,
        });
      } catch (e) {
        console.error('locale update network error');
      }
    },
  },
});
