import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { useCookie } from '@/composables/useCookie';

const SOURCE_STAG_FACT = 'SourceStagFact';
const stagToPureStag = (stag: string) => {
  const splittedStag = String(stag).split('_');
  if (splittedStag && splittedStag.length) {
    return splittedStag[0];
  }
  return '';
};

export const useStoreStag = defineStore('stag', {
  state: () => ({
    stag: '',
    wasRegisteredByPureStag: '',
    isStagToDelay: false,
  }),
  getters: {
    queryStag() {
      return window.location.href.match(/stag=(\d+(_[\d\w]+)?)/)?.[1];
    },
    currentStag(state): string {
      if (state.stag && !state.stag.includes('_')) {
        return state.stag + '_noclickid';
      }
      return state.stag;
    },
    currentPureStag(): string {
      return stagToPureStag(this.currentStag || localStorage.stag || '');
    },
    wasRegisteredByPureStagGetter(state) {
      return state.wasRegisteredByPureStag;
    },
    isStagToDelayGetter(state) {
      return state.isStagToDelay;
    },
  },
  actions: {
    async checkStag() {
      const stagParams = window.location.href.match(/stag=(\d+(_[\d\w]+)?)/);
      if (stagParams && stagParams[1]) {
        this.stag = stagParams[1];
      } else if (localStorage && localStorage?.stag) {
        this.stag = localStorage.stag;
      }

      if (localStorage && this.currentStag) {
        localStorage.stag = this.currentStag;
      }
    },
    saveCurrentStag() {
      useTransport().getSs('/api/info/locales', { stag: this.currentStag });
    },
    checkPureStagByWhichWasRegistered() {
      if (localStorage && localStorage?.stag) {
        this.wasRegisteredByPureStag = stagToPureStag(localStorage.stag);
      }
      return '';
    },
    init() {
      if (!window) {
        return;
      }

      const stag = window.location.href.match(/stag=(\d+(_[\d\w]+)?)/)?.[1];
      const currentCookieStag = useCookie().getCookieValue(SOURCE_STAG_FACT);
      const domain = useCookie().rootDomain(document.location.hostname);

      if (currentCookieStag) {
        window.document.cookie = `${SOURCE_STAG_FACT}=${currentCookieStag};Max-Age=63158400;Domain=${domain};Path=/`;
      } else if (stag) {
        window.document.cookie = `${SOURCE_STAG_FACT}=${stag};Max-Age=63158400;Domain=${domain};Path=/`;
      } else if (document.referrer) {
        window.document.cookie = `${SOURCE_STAG_FACT}=${document.referrer};Max-Age=63158400;Domain=${domain};Path=/`;
      } else {
        window.document.cookie = `${SOURCE_STAG_FACT}=Direct;Max-Age=63158400;Domain=${domain};Path=/`;
      }

      if (!localStorage.getItem(SOURCE_STAG_FACT)) {
        if (stag) {
          localStorage.setItem(SOURCE_STAG_FACT, stag);
        } else if (document.referrer) {
          localStorage.setItem(SOURCE_STAG_FACT, document.referrer);
        } else {
          localStorage.setItem(SOURCE_STAG_FACT, 'Direct');
        }
      }

      const stagFromLocalStorage = localStorage.getItem(SOURCE_STAG_FACT);

      if (stagFromLocalStorage?.trim()) {
        window.document.cookie = `${SOURCE_STAG_FACT}2=${stagFromLocalStorage};Max-Age=63158400;Domain=${domain};Path=/`;
      }
    },
  },
});
