import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from 'assets/images/common/ico_male.svg'
import _imports_1 from 'assets/images/common/ico_female.svg'


const _hoisted_1 = { class: "bitGenderSelection" }
const _hoisted_2 = {
  key: 0,
  class: "bitGenderSelectionErrorMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BitRadioButton = _resolveComponent("BitRadioButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BitRadioButton, {
      "model-value": _ctx.modelValue,
      "radio-value": "m",
      variant: "group",
      label: _ctx.maleTitle,
      gtmid: "SignUp_TypeSex",
      "form-field-name": "checkboxGender",
      "is-error": _ctx.isError,
      disabled: _ctx.isDisabled,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      prependIcon: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("img", { src: _imports_0 }, null, -1)
      ])),
      _: 1
    }, 8, ["model-value", "label", "is-error", "disabled"]),
    _createVNode(_component_BitRadioButton, {
      "model-value": _ctx.modelValue,
      "radio-value": "f",
      variant: "group",
      label: _ctx.femaleTitle,
      gtmid: "SignUp_TypeSex",
      "form-field-name": "checkboxGender",
      "is-error": _ctx.isError,
      disabled: _ctx.isDisabled,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      prependIcon: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("img", { src: _imports_1 }, null, -1)
      ])),
      _: 1
    }, 8, ["model-value", "label", "is-error", "disabled"]),
    (_ctx.isError && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ]))
}