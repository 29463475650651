<template>
  <div class="modern2LionFooter">
    <ul class="modern2LionFooterInfoLinks">
      <li v-for="link in storeFooter.informationLink" :key="link.link">
        <a
          :href="`https://${regionalDomain}${link.link}`"
          class="modern2LionFooterLink"
          target="_blank"
        >
          {{ link.title }}
        </a>
      </li>
    </ul>
    <div class="portals">
      <p class="portalsHeading">{{ storeFooter.partnersTitle }}</p>
      <div class="portalsHeadingList">
        <div
          v-for="(img, imgIndex) in storeFooter.partnersLink"
          :key="imgIndex"
          class="portalsHeadingImg footerImageGray"
        >
          <img
            v-lazy="img.img_src.url"
            :width="img.img_src.width"
            :height="img.img_src.height"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="footerLicense">
      <div class="footerLicenseLeft">
        <ul class="footerLicenseText">
          <li>{{ storeFooter.responsibleGamingTitle }}</li>
          <a
            :href="`https://${regionalDomain}${storeFooter.responsibleGamingLink.link}`"
            target="_blank"
            class="modern2LionFooterLink"
          >
            {{ storeFooter.responsibleGamingLink.title }}
          </a>
        </ul>

        <div class="footerLicenseLogo">
          <div
            v-for="(img, imgIndex) in storeFooter.gamblers"
            :key="imgIndex"
            class="footerLicenseLogoImg"
          >
            <img
              v-lazy="img.img_src.url"
              :width="img.img_src.width"
              :height="img.img_src.height"
              class="imageLinkLicenceImg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="licenseInfo">
        <div v-if="storeFooter.logo" class="licenseInfoImagesBlock">
          <div v-if="storeFooter.partnersLogo.url" class="licenseImg">
            <a href="https://7bitpartners.com/" target="_blank">
              <img
                :data-src="storeFooter.partnersLogo.url"
                :width="storeFooter.partnersLogo.width"
                :height="storeFooter.partnersLogo.height"
                :alt="storeFooter.partnersLogo.alternativeText || ''"
                v-lazy="storeFooter.partnersLogo.url"
              />
            </a>
          </div>
          <div class="licenseImg">
            <img
              :data-src="storeFooter.logo.url"
              :width="storeFooter.logo.width"
              :height="storeFooter.logo.height"
              :alt="storeFooter.logo.alternativeText || ''"
              v-lazy="storeFooter.logo.url"
            />
          </div>
        </div>
        <Markdown class="licenseText" :text="storeFooter.licenseText" />
      </div>
    </div>
    <p class="copyright">{{ copyright }}</p>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import Markdown from '@/components/markdown.vue';
import { useStoreFooter } from '@/store/strapi/footer';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';

export default defineComponent({
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  components: {
    Markdown,
  },
  setup() {
    const storeFooter = useStoreFooter();
    const storeSystemHosts = useStoreSystemHosts();

    const regionalDomain = computed((): string => {
      return storeSystemHosts.regional;
    });

    const copyright = computed(() => {
      return (storeFooter.copyright || '').replace(
        /%domain_regional%/gm,
        () => regionalDomain.value
      );
    });

    return {
      storeFooter,
      regionalDomain,
      copyright,
    };
  },
});
</script>

<style lang="scss" src="./bonusHubNeonFooter.scss"></style>
