import { defineStore } from 'pinia';
import { useStoreLanguage } from '../abstract/language';
import { useTransport } from '../abstract/transport';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { RegFormInterface } from '~/types/regForm.interface';

export const useStoreRegForm = defineStore('regForm', {
  state: () => ({
    data: {} as RegFormInterface,
    status: LoadingStatusEnum.noData as LoadingStatusEnum,
  }),
  getters: {
    formLang(state) {
      return state.data.lang;
    },
    formData(state) {
      return state.data;
    },
    isLoading(state): boolean {
      return state.status === LoadingStatusEnum.loading;
    },
    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    async load(): Promise<void> {
      if (!this.isLoaded) {
        this.status = LoadingStatusEnum.loading;
      }

      const host = `${
        location.host === 'localhost' || location.host.includes('local.7bitcasino.com')
          ? '7bitcasino.com'
          : location.host
      }/dinamic-content`;
      const response = await useTransport().get(`https://${host}/reg-forms`, {
        lang: useStoreLanguage().language,
      });

      if (response[0].lang) {
        this.data = response[0];
        this.status = LoadingStatusEnum.loaded;
      } else {
        this.status = LoadingStatusEnum.error;
      }
    },
  },
});
