import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["data-theme"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modern2LionMainPage = _resolveComponent("Modern2LionMainPage")!
  const _component_Modern2LionFooter = _resolveComponent("Modern2LionFooter")!

  return (_ctx.isLoaded && _ctx.currentPage)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modern2LionContainer modern2LionWrapper",
        "data-theme": _ctx.theme
      }, [
        _createVNode(_component_Modern2LionMainPage),
        _createVNode(_component_Modern2LionFooter, {
          template: _ctx.currentPage.template
        }, null, 8, ["template"])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}