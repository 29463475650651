import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["data-theme"]
const _hoisted_2 = { class: "contentBlocksContainer" }
const _hoisted_3 = ["data-position"]
const _hoisted_4 = { class: "contentBlockTitle" }
const _hoisted_5 = { class: "contentBlockSubTitle" }
const _hoisted_6 = { class: "welcomeBonusesWrapper" }
const _hoisted_7 = ["data-position"]
const _hoisted_8 = { class: "contentBlockTitle" }
const _hoisted_9 = { class: "contentBlockSubTitle" }
const _hoisted_10 = { class: "promotionsWrapper" }
const _hoisted_11 = ["data-position"]
const _hoisted_12 = { class: "contentBlockTitle" }
const _hoisted_13 = { class: "contentBlockSubTitle" }
const _hoisted_14 = { class: "tournamentsWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BonusHubNeonMainPage = _resolveComponent("BonusHubNeonMainPage")!
  const _component_WelcomeCard = _resolveComponent("WelcomeCard")!
  const _component_PromotionCard = _resolveComponent("PromotionCard")!
  const _component_BitButton = _resolveComponent("BitButton")!
  const _component_TournamentCard = _resolveComponent("TournamentCard")!
  const _component_BuyCryptoBlock = _resolveComponent("BuyCryptoBlock")!
  const _component_BonusHubNeonFooter = _resolveComponent("BonusHubNeonFooter")!

  return (_ctx.isLoaded && _ctx.currentPage)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "data-theme": _ctx.theme,
        class: "bonusHubNeonWrapper"
      }, [
        _createVNode(_component_BonusHubNeonMainPage, {
          "modal-redirect-path": _ctx.modalRedirectPath,
          onSetModalRedirectPath: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setModalRedirectPath($event)))
        }, null, 8, ["modal-redirect-path"]),
        _createElementVNode("div", _hoisted_2, [
          (_ctx.welcomePackBonuses.length && _ctx.currentPage.template.welcomePackBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "contentBlock contentBlockWelcome",
                "data-position": _ctx.currentPage.template.welcomePackBlock.position
              }, [
                _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.currentPage.template.welcomePackBlock.title), 1),
                _createElementVNode("h4", _hoisted_5, _toDisplayString(_ctx.currentPage.template.welcomePackBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.welcomePackBonuses, (bonus, indx) => {
                    return (_openBlock(), _createBlock(_component_WelcomeCard, {
                      key: bonus.bonusId,
                      title: _ctx.currentPage.template.welcomePackBlock[`depTitle${indx + 1}`],
                      offer: _ctx.currentPage.template.welcomePackBlock[`depOfferTitle${indx + 1}`],
                      "card-icon": _ctx.currentPage.template.welcomePackBlock[`cardIconFront${indx + 1}`],
                      "card-icon-back": _ctx.currentPage.template.welcomePackBlock[`cardIconBack${indx + 1}`]
                    }, null, 8, ["title", "offer", "card-icon", "card-icon-back"]))
                  }), 128))
                ])
              ], 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.promotionBonusesToShow.length && _ctx.currentPage.template.promotionsBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.promotionsBlock.position
              }, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(_ctx.currentPage.template.promotionsBlock.title), 1),
                _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.currentPage.template.promotionsBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionBonusesToShow, (bonus, index) => {
                    return (_openBlock(), _createBlock(_component_PromotionCard, {
                      key: bonus.bonusId,
                      bonus: bonus,
                      variant: "neon",
                      index: index,
                      "get-bonus-title": _ctx.currentPage.template.promotionsBlock.getBonusTitle,
                      onSetModalRedirectPath: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setModalRedirectPath($event)))
                    }, null, 8, ["bonus", "index", "get-bonus-title"]))
                  }), 128))
                ]),
                (!_ctx.storePlatform.isDesktop && _ctx.promotionBonuses.length > 4)
                  ? (_openBlock(), _createBlock(_component_BitButton, {
                      key: 0,
                      type: "secondary",
                      class: _normalizeClass(["promoShowMoreBtn", { showMoreButtonRotated: _ctx.isAllPromotionBonusesShown }]),
                      prependIcon: "arrow-down.svg",
                      onClick: _ctx.togglePromotionBonusesDisplayCount
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isAllPromotionBonusesShown ? 'Show less' : 'Show more'), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_7))
            : _createCommentVNode("", true),
          (_ctx.tournamentsToShow.length && _ctx.currentPage.template.tournamentsBlock)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.tournamentsBlock.position
              }, [
                _createElementVNode("h3", _hoisted_12, _toDisplayString(_ctx.currentPage.template.tournamentsBlock.title), 1),
                _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.currentPage.template.tournamentsBlock.subtitle), 1),
                _createElementVNode("div", _hoisted_14, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tournamentsToShow, (race, index) => {
                    return (_openBlock(), _createBlock(_component_TournamentCard, {
                      key: race.raceData.ssId,
                      index: index,
                      race: race,
                      variant: "neon",
                      "prize-pool-title": _ctx.currentPage.template.tournamentsBlock.prizePoolTitle,
                      onSetModalRedirectPath: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setModalRedirectPath($event)))
                    }, null, 8, ["index", "race", "prize-pool-title"]))
                  }), 128))
                ]),
                (!_ctx.storePlatform.isDesktop && _ctx.tournamentsExtended.length > 4)
                  ? (_openBlock(), _createBlock(_component_BitButton, {
                      key: 0,
                      class: _normalizeClass(["promoShowMoreBtn", { showMoreButtonRotated: _ctx.isAllPromotionBonusesShown }]),
                      prependIcon: "arrow-down.svg",
                      type: "secondary",
                      onClick: _ctx.toggleTournamentsDisplayCount
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isAllTournamentsShown ? 'Show less' : 'Show more'), 1)
                      ]),
                      _: 1
                    }, 8, ["class", "onClick"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_11))
            : _createCommentVNode("", true),
          (_ctx.currentPage.template.buyCryptoBlock)
            ? (_openBlock(), _createBlock(_component_BuyCryptoBlock, {
                key: 3,
                class: "contentBlock",
                "data-position": _ctx.currentPage.template.buyCryptoBlock.position,
                block: _ctx.currentPage.template.buyCryptoBlock,
                onSetModalRedirectPath: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setModalRedirectPath($event)))
              }, null, 8, ["data-position", "block"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_BonusHubNeonFooter, {
          template: _ctx.currentPage.template
        }, null, 8, ["template"])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}