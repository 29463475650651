import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "bitBirthSelectLabelText" }
const _hoisted_2 = { class: "bitBirthSelectWrapper" }
const _hoisted_3 = {
  key: 0,
  class: "bitBirthSelectErrorMessage"
}
const _hoisted_4 = {
  key: 1,
  class: "bitBirthSelectErrorMessage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BitSelect = _resolveComponent("BitSelect")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bitBirthSelect", [
      {
        bitBirthSelectSelectError: !!_ctx.lessThen18ErrorMessage || _ctx.isError,
        bitBirthSelectDisabled: _ctx.locked || _ctx.disabled,
      },
    ]])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.labelText), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BitSelect, {
        modelValue: _ctx.dateForm.day,
        "onUpdate:modelValue": [
          _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateForm.day) = $event)),
          _cache[1] || (_cache[1] = ($event: any) => (_ctx.dirtyDateStatus.day = true))
        ],
        class: "bitBirthSelectItem",
        "is-no-margin": "",
        options: _ctx.listDays,
        "is-error": !!_ctx.lessThen18ErrorMessage || _ctx.isError,
        "is-success": !_ctx.isAnyDateOfBirthValidatorErrors && _ctx.isAnyDirtyDateOfBirthFields,
        placeholder: _ctx.dayPlaceholder,
        locked: _ctx.locked
      }, null, 8, ["modelValue", "options", "is-error", "is-success", "placeholder", "locked"]),
      _createVNode(_component_BitSelect, {
        modelValue: _ctx.dateForm.month,
        "onUpdate:modelValue": [
          _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dateForm.month) = $event)),
          _cache[3] || (_cache[3] = ($event: any) => (_ctx.dirtyDateStatus.month = true))
        ],
        class: "bitBirthSelectItem",
        options: _ctx.listMonths,
        "is-no-margin": "",
        "is-error": !!_ctx.lessThen18ErrorMessage || _ctx.isError,
        "is-success": !_ctx.isAnyDateOfBirthValidatorErrors && _ctx.isAnyDirtyDateOfBirthFields,
        placeholder: _ctx.monthPlaceholder,
        locked: _ctx.locked
      }, null, 8, ["modelValue", "options", "is-error", "is-success", "placeholder", "locked"]),
      _createVNode(_component_BitSelect, {
        modelValue: _ctx.dateForm.year,
        "onUpdate:modelValue": [
          _cache[4] || (_cache[4] = ($event: any) => ((_ctx.dateForm.year) = $event)),
          _cache[5] || (_cache[5] = ($event: any) => (_ctx.dirtyDateStatus.year = true))
        ],
        class: "bitBirthSelectItem",
        options: _ctx.listYears,
        "is-no-margin": "",
        "is-error": !!_ctx.lessThen18ErrorMessage || _ctx.isError,
        "is-success": !_ctx.isAnyDateOfBirthValidatorErrors && _ctx.isAnyDirtyDateOfBirthFields,
        placeholder: _ctx.yearPlaceholder,
        locked: _ctx.locked
      }, null, 8, ["modelValue", "options", "is-error", "is-success", "placeholder", "locked"])
    ]),
    (_ctx.isAnyDateOfBirthValidatorErrors)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.dateOfBirthValidatorMessage), 1))
      : (_ctx.isError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1))
        : _createCommentVNode("", true)
  ], 2))
}