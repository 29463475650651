import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from 'assets/images/common/lock.svg'
import _imports_1 from 'assets/images/common/warning_amber.svg'
import _imports_2 from 'assets/images/common/done.svg'


const _hoisted_1 = {
  key: 0,
  class: "bitInputLabel"
}
const _hoisted_2 = {
  key: 0,
  class: "bitInputPrependIcon"
}
const _hoisted_3 = ["id", "value", "type", "name", "placeholder", "maxlength", "disabled", "gtmid"]
const _hoisted_4 = ["id", "value", "type", "name", "placeholder", "maxlength", "disabled", "gtmid"]
const _hoisted_5 = {
  key: 3,
  class: "bitInputRightIcon"
}
const _hoisted_6 = {
  key: 0,
  class: "bitInputIcon",
  src: _imports_0
}
const _hoisted_7 = {
  key: 1,
  class: "bitInputIcon",
  src: _imports_1
}
const _hoisted_8 = {
  key: 2,
  class: "bitInputIcon",
  src: _imports_2
}
const _hoisted_9 = {
  key: 3,
  class: "bitInputAppendIcon"
}
const _hoisted_10 = {
  key: 1,
  class: "bitInputMessages"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "bitInputErrorText"
}
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bitInput", [
      {
        bitInputNoMargin: _ctx.isNoMargin,
        bitInputTextarea: _ctx.variant === 'textarea',
        bitInputError: _ctx.isError && _ctx.isInputActive,
        bitInputSuccess: _ctx.isValid && _ctx.isInputActive,
        bitInputWarning: _ctx.isWarning && _ctx.isInputActive,
        bitInputDisabled: _ctx.locked || _ctx.disabled,
        bitInputWithMessage:
          (_ctx.errorMessage || _ctx.warningMessage || _ctx.$slots.inputMessage) && _ctx.isInputActive,
      },
    ]])
  }, [
    (_ctx.textLabel)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.textLabel), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["bitInputWrapper", _ctx.inputWrapperClass])
    }, [
      (_ctx.$slots.prependInputIcon)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "prependInputIcon")
          ]))
        : _createCommentVNode("", true),
      (_ctx.variant !== 'textarea')
        ? (_openBlock(), _createElementBlock("input", {
            key: 1,
            id: _ctx.formFieldName,
            ref: "inputRef",
            value: _ctx.modelValue,
            type: _ctx.type,
            name: _ctx.formFieldName,
            class: _normalizeClass(_ctx.inputClass),
            placeholder: _ctx.placeholder,
            maxlength: _ctx.maxlength,
            disabled: _ctx.disabled,
            tabindex: "0",
            gtmid: _ctx.gtmid,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            onKeyup: [
              _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyupEnter && _ctx.onKeyupEnter(...args)), ["enter"])),
              _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args)))
            ],
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
            onKeypress: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
            onFocus: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
          }, null, 42, _hoisted_3))
        : (_openBlock(), _createElementBlock("textarea", {
            key: 2,
            id: _ctx.formFieldName,
            ref: "inputRef",
            value: _ctx.modelValue,
            type: _ctx.type,
            name: _ctx.formFieldName,
            class: _normalizeClass(_ctx.inputClass),
            placeholder: _ctx.placeholder,
            maxlength: _ctx.maxlength,
            disabled: _ctx.disabled,
            tabindex: "0",
            gtmid: _ctx.gtmid,
            onInput: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
            onChange: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            onKeyup: [
              _cache[10] || (_cache[10] = _withKeys(
//@ts-ignore
(...args) => (_ctx.onKeyupEnter && _ctx.onKeyupEnter(...args)), ["enter"])),
              _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.onKeyup && _ctx.onKeyup(...args)))
            ],
            onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
            onKeypress: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onKeypress && _ctx.onKeypress(...args))),
            onFocus: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
            onBlur: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
          }, null, 42, _hoisted_4)),
      (
          _ctx.locked ||
          (_ctx.isError && _ctx.isInputActive && !_ctx.isWithAppendMainIcon) ||
          (_ctx.isValid && _ctx.isInputActive && !_ctx.isWithAppendMainIcon) ||
          (_ctx.$slots.appendInputIcon && !_ctx.isError) ||
          _ctx.isWithAppendMainIcon
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.locked)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true),
            (_ctx.isError && _ctx.isInputActive && !_ctx.isWithAppendMainIcon)
              ? (_openBlock(), _createElementBlock("img", _hoisted_7))
              : _createCommentVNode("", true),
            (_ctx.isValid && _ctx.isInputActive && !_ctx.isWithAppendMainIcon)
              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
              : _createCommentVNode("", true),
            ((_ctx.$slots.appendInputIcon && !_ctx.isError) || _ctx.isWithAppendMainIcon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                  _renderSlot(_ctx.$slots, "appendInputIcon")
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    ((_ctx.errorMessage || _ctx.warningMessage || _ctx.$slots.inputMessage) && _ctx.isInputActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.isHrefInError)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "bitInputErrorText",
                innerHTML: _ctx.errorMessageWithHref
              }, null, 8, _hoisted_11))
            : (_ctx.isError)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1))
              : (_ctx.isWarning)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 2,
                    class: "bitInputWarningText",
                    innerHTML: _ctx.warningMessage
                  }, null, 8, _hoisted_13))
                : (_ctx.$slots.inputMessage)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                      _renderSlot(_ctx.$slots, "inputMessage")
                    ]))
                  : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}