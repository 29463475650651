import axios from 'axios';
import { defineStore } from 'pinia';
import { useStoreLanguage } from './language';

export const useTransport = defineStore('transport', {
  actions: {
    async get(url: string, params?: any) {
      const response = await axios.get(url, { params });
      return response.data;
    },
    async post(url: string, body: any) {
      const response = await axios.post(url, body);
      return response.data;
    },
    async getStrapi(dataName: string, params?: string) {
      const locale = useStoreLanguage().language;
      try {
        const paramsString = params ? `${params}` : `locale=${locale}&populate=*`;
        const url = `/strapi/${dataName}?${paramsString}`;

        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getSs(url: string, params?: any) {
      const response = await axios.get(url, {
        withCredentials: true,
        params,
        headers: {
          Accept: 'application/vnd.s.v1+json',
          'accept-language': useStoreLanguage().language,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });

      return response.data;
    },
    async postSs(url: string, body: any, headers?: any) {
      const response = await axios.post(url, body, {
        withCredentials: true,
        headers: {
          Accept: 'application/vnd.s.v1+json',
          'accept-language': useStoreLanguage().language,
          'Content-Type': 'application/json;charset=UTF-8',
          ...(headers || {}),
        },
      });

      return response.data;
    },
    async patchSs(url: string, body: any) {
      const response = await axios.patch(url, body, {
        withCredentials: true,
        headers: {
          Accept: 'application/vnd.s.v1+json',
          'accept-language': useStoreLanguage().language,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });

      return response.data;
    },
  },
});
