import { defineStore } from 'pinia';
import { useTransport } from '../abstract/transport';
import { useStoreSystemHosts } from './systemHosts';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { AutochangeStrapiItem } from '~/types/strapi/autochangeItem.strapi.interface';

export const useStoreAutochangeData = defineStore('autochangeData', {
  state: () => ({
    autochangeItems: [] as AutochangeStrapiItem[],
    status: LoadingStatusEnum.noData,
  }),
  getters: {
    autochangeItemsGetter(state) {
      return state.autochangeItems;
    },

    isLoaded(state): boolean {
      return state.status === LoadingStatusEnum.loaded;
    },
  },
  actions: {
    async load() {
      if (this.status !== LoadingStatusEnum.noData && this.status !== LoadingStatusEnum.error) {
        return;
      }
      this.status = LoadingStatusEnum.loading;

      try {
        const storeSystemHosts = useStoreSystemHosts();
        const response = await useTransport().get(
          `https://${storeSystemHosts.cms}/autochange-data`
        );

        if (response) {
          this.autochangeItems = response;
          this.status = LoadingStatusEnum.loaded;
        } else {
          this.status = LoadingStatusEnum.error;
        }
      } catch (e) {
        this.status = LoadingStatusEnum.error;
      }
    },
  },
});
