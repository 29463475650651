<template>
  <div
    class="bitInput"
    :class="[
      {
        bitInputNoMargin: isNoMargin,
        bitInputTextarea: variant === 'textarea',
        bitInputError: isError && isInputActive,
        bitInputSuccess: isValid && isInputActive,
        bitInputWarning: isWarning && isInputActive,
        bitInputDisabled: locked || disabled,
        bitInputWithMessage:
          (errorMessage || warningMessage || $slots.inputMessage) && isInputActive,
      },
    ]"
  >
    <label v-if="textLabel" class="bitInputLabel">{{ textLabel }}</label>
    <div class="bitInputWrapper" :class="inputWrapperClass">
      <span v-if="$slots.prependInputIcon" class="bitInputPrependIcon">
        <slot name="prependInputIcon" />
      </span>

      <input
        v-if="variant !== 'textarea'"
        :id="formFieldName"
        ref="inputRef"
        :value="modelValue"
        :type="type"
        :name="formFieldName"
        :class="inputClass"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :disabled="disabled"
        tabindex="0"
        :gtmid="gtmid"
        @input="onInput"
        @change="onChange"
        @keyup.enter="onKeyupEnter"
        @keyup="onKeyup"
        @click="onClick"
        @keypress="onKeypress"
        @focus="onFocus"
        @blur="onBlur"
      />
      <textarea
        v-else
        :id="formFieldName"
        ref="inputRef"
        :value="modelValue"
        :type="type"
        :name="formFieldName"
        :class="inputClass"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :disabled="disabled"
        tabindex="0"
        :gtmid="gtmid"
        @input="onInput"
        @change="onChange"
        @keyup.enter="onKeyupEnter"
        @keyup="onKeyup"
        @click="onClick"
        @keypress="onKeypress"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div
        v-if="
          locked ||
          (isError && isInputActive && !isWithAppendMainIcon) ||
          (isValid && isInputActive && !isWithAppendMainIcon) ||
          ($slots.appendInputIcon && !isError) ||
          isWithAppendMainIcon
        "
        class="bitInputRightIcon"
      >
        <img v-if="locked" class="bitInputIcon" src="~assets/images/common/lock.svg" />
        <img
          v-if="isError && isInputActive && !isWithAppendMainIcon"
          class="bitInputIcon"
          src="~assets/images/common/warning_amber.svg"
        />
        <img
          v-if="isValid && isInputActive && !isWithAppendMainIcon"
          class="bitInputIcon"
          src="~assets/images/common/done.svg"
        />
        <span
          v-if="($slots.appendInputIcon && !isError) || isWithAppendMainIcon"
          class="bitInputAppendIcon"
        >
          <slot name="appendInputIcon" />
        </span>
      </div>
    </div>
    <div
      v-if="(errorMessage || warningMessage || $slots.inputMessage) && isInputActive"
      class="bitInputMessages"
    >
      <span v-if="isHrefInError" class="bitInputErrorText" v-html="errorMessageWithHref" />
      <span v-else-if="isError" class="bitInputErrorText">
        {{ errorMessage }}
      </span>
      <span v-else-if="isWarning" class="bitInputWarningText" v-html="warningMessage" />
      <span v-else-if="$slots.inputMessage">
        <slot name="inputMessage" />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { basicInputProps } from '../constants';
import type { InputTypes } from '../types';

export default defineComponent({
  props: {
    ...basicInputProps,
    type: {
      type: String as PropType<InputTypes>,
      default: 'text',
    },
    isWithAppendMainIcon: {
      type: Boolean,
      default: false,
    },
    inputWrapperClass: {
      type: String,
      default: null,
    },
    variant: {
      type: String as PropType<'default' | 'bonusCode' | 'verificationCode' | 'textarea'>,
      default: 'default',
    },
  },
  emits: ['update:modelValue', 'keyup', 'keyup-enter', 'click', 'change', 'keypress'],
  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement | null>(null);

    watch(
      () => props.focusTrigger,
      () => inputRef.value?.focus()
    );

    const onInput = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('update:modelValue', target.value);
    };

    const onChange = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('change', target.value);
    };

    const onKeyup = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('keyup', target.value);
    };

    const onKeyupEnter = (event: Event) => {
      const target = event.target as HTMLInputElement;
      emit('keyup-enter', target.value);
    };

    const onClick = (event: Event) => {
      emit('click', event);
    };

    const onKeypress = (event: KeyboardEvent) => {
      emit('keypress', event);
    };

    const isInputActive = computed(() => !props.locked && !props.disabled);

    const loseFocus = () => {
      inputRef.value?.blur();
    };

    const errorMessageWithHref = computed(() => {
      return props.errorMessage.replace(/\[([^[]+)\]\(([^(]+)\)/gm, (...v: string[]) => {
        return `<a href="${v[2]}">${v[1]}</a>`;
      });
    });
    const isHrefInError = computed(() => {
      return /\[([^[]+)\]\(([^(]+)\)/gm.test(props.errorMessage);
    });
    const onFocus = () => {
      window.addEventListener('touchmove', loseFocus, { once: true });
    };
    const onBlur = () => {
      window.removeEventListener('touchmove', loseFocus);
    };

    onMounted(() => {
      if (props.defaultValue) {
        emit('update:modelValue', props.defaultValue);
        return;
      }

      if (props.focusTrigger) {
        inputRef.value?.focus({ preventScroll: props.focusWithoutScrolling });
      }
    });
    onBeforeUnmount(() => window.removeEventListener('touchmove', loseFocus));

    return {
      inputRef,
      errorMessageWithHref,
      isHrefInError,
      onInput,
      onKeyup,
      onKeyupEnter,
      onChange,
      onClick,
      onKeypress,
      onFocus,
      onBlur,
      isInputActive,
    };
  },
});
</script>

<style lang="scss" src="./input.scss"></style>
