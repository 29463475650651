import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Transition as _Transition, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, mergeProps as _mergeProps, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from 'assets/mirax/askgamblers.png'
import _imports_1 from 'assets/mirax/bitcoinplay.png'
import _imports_2 from 'assets/mirax/casinos_analyzer.svg'
import _imports_3 from 'assets/mirax/BTC.png'
import _imports_4 from 'assets/mirax/NeoSurf.png'
import _imports_5 from 'assets/mirax/MiFinity.png'
import _imports_6 from 'assets/mirax/Visa_Debit_SVG_logo.png'
import _imports_7 from 'assets/mirax/Mastercard-logo.png'
import _imports_8 from 'assets/mirax/coinpad.png'


const _hoisted_1 = ["data-theme"]
const _hoisted_2 = {
  key: 0,
  class: "modalLoginMirax modal-overlay"
}
const _hoisted_3 = {
  href: "#",
  class: "logoMirax"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "modernWrapperMiraxOverlay" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "modern-head"
}
const _hoisted_9 = { class: "modern-head-content" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "modern-custom-button-text" }
const _hoisted_13 = {
  key: 0,
  class: "modern-head"
}
const _hoisted_14 = { class: "modern-head-content" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "modern-custom-button-text" }
const _hoisted_18 = { class: "modern-stepe-container" }
const _hoisted_19 = { class: "modern-steper" }
const _hoisted_20 = { class: "modern-steper__block" }
const _hoisted_21 = { class: "modern-steper__block" }
const _hoisted_22 = { class: "modern-steper__block" }

import { computed, onMounted, ref, watch } from 'vue';
import Markdown from '@/components/markdown.vue';
import ModalComponent from '@/components/modalComponent/modalComponent.vue';
import ModalSignupMirax from '@/components/modalSignup/modalSignupMirax.vue';
import usePromoModal from '@/composables/promoModalState';
import { useStoreCountry } from '@/store/client/country';
import { useStorePlatform } from '@/store/client/platform';
import { useStoreSsPlayer } from '@/store/softswiss/ssPlayer';
import { useStoreCurrencyMenu } from '@/store/strapi/currencyMenu';
import { useStoreLanding } from '@/store/strapi/landing';
import { useStoreRegForm } from '@/store/strapi/regForm';
import { useStoreSystemHosts } from '@/store/strapi/systemHosts';
import { copyText } from '@/utils/vue3-clipboard2';
import { RegFormInterface } from '~/types/regForm.interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'modern2SquarePromo',
  setup(__props) {

const storeSsPlayer = useStoreSsPlayer();
const storeRegForm = useStoreRegForm();
const storeCountry = useStoreCountry();
const storeLanding = useStoreLanding();
const storeSystemHosts = useStoreSystemHosts();
const storeCurrencyMenu = useStoreCurrencyMenu();

const template = computed(() => storeLanding.currentPage?.template);

const { dataPlatformAttr, isDesktop, isTablet, isMobile } = useStorePlatform();

const { logoImg, secondTitle, bannerUrl, currentPage } = useStoreLanding();

const onChangeCurrency = (newCurrency: string) => {
  storeLanding.setCurrency(newCurrency);
};

const loadLocalizedUserData = () => {
  if (storeCountry.userCountry) {
    if (Array.isArray(storeLanding.secondTitle)) {
      const titleForCountry = storeLanding.secondTitle.find(
        (c) => c.country === storeCountry.userCountry
      );
      if (titleForCountry) {
        storeLanding.setCurrency(titleForCountry.currency);
        return;
      }
    }

    const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
      currency.defaultForCountries.some((country) => country === storeCountry.userCountry)
    );

    if (storeLanding.currency) {
      storeLanding.setCurrency(storeLanding.currency);
    } else if (defaultCurrencyForCountry?.currencyCode) {
      storeLanding.setCurrency(defaultCurrencyForCountry.currencyCode);
    } else {
      storeLanding.setCurrency(storeCurrencyMenu.activeCurrencies[0]?.currencyCode);
    }
  }
};

watch(() => storeCurrencyMenu.activeCurrencies, loadLocalizedUserData, { immediate: true });

const { openModal, show } = usePromoModal();

const isPlayerAuthorised = computed((): boolean => storeSsPlayer.isPlayerAuthorised);

const registerHandler = () => {
  if (isPlayerAuthorised.value) {
    window.location.href = `https://${storeSystemHosts.redirectHost}`;
  } else {
    openModal('mirax');
  }
};

const regForm = computed((): RegFormInterface => storeRegForm.formData);

const registerTitle = computed((): string => {
  return template.value?.formButtonText || regForm.value.register_button_title;
});

const isCopiyed = ref(false);

watch(
  () => isCopiyed.value,
  () => {
    if (isCopiyed.value) {
      window.setTimeout(() => {
        isCopiyed.value = false;
      }, 3000);
    }
  }
);

const copyToClipboard = async (text: string) => {
  try {
    await copyText(text);
    isCopiyed.value = true;
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  document.body.classList.add('modern2SquareLand');
});

return (_ctx: any,_cache: any) => {
  return (_unref(storeLanding).isLoaded && _unref(currentPage))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modern-container modernMirax",
        "data-theme": _unref(currentPage).theme
      }, [
        (_unref(show))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_Transition, {
                duration: { enter: 500, leave: 200 },
                name: "globalModalAnimation"
              }, {
                default: _withCtx(() => [
                  _createVNode(ModalComponent, { modal: "signup" }, {
                    default: _withCtx(() => [
                      _createVNode(ModalSignupMirax, {
                        "first-title": _unref(secondTitle),
                        "second-title": template.value.thirdTitle,
                        "third-title": template.value.fourthTitle,
                        modalType: "short",
                        onChangeCurrency: onChangeCurrency
                      }, null, 8, ["first-title", "second-title", "third-title"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("a", _hoisted_3, [
          _createElementVNode("img", {
            src: _unref(logoImg).url,
            alt: "logoMirax"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "modernBgOverlay",
            src: _unref(bannerUrl)
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _mergeProps({ class: "modern-wrapper" }, _unref(dataPlatformAttr), { style: _unref(bannerUrl) }), [
            _createElementVNode("img", {
              class: "modernMiraxBannerBg",
              src: _unref(bannerUrl)
            }, null, 8, _hoisted_7),
            (_unref(isDesktop))
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(Markdown, {
                      "auto-link-parse": false,
                      text: template.value.firstTitle,
                      class: "modern-head__title-top"
                    }, null, 8, ["text"]),
                    _createVNode(Markdown, {
                      "auto-link-parse": false,
                      text: _unref(secondTitle),
                      class: "modern-head__title"
                    }, null, 8, ["text"]),
                    _createVNode(Markdown, {
                      "auto-link-parse": false,
                      text: template.value.thirdTitle,
                      class: "modern-head__text"
                    }, null, 8, ["text"]),
                    (template.value.fourthTitle)
                      ? (_openBlock(), _createBlock(Markdown, {
                          key: 0,
                          "auto-link-parse": false,
                          text: template.value.fourthTitle,
                          class: "modern-head__title"
                        }, null, 8, ["text"]))
                      : _createCommentVNode("", true),
                    (template.value.bonusCode)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "miraxBonusCodeTempate",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (copyToClipboard(template.value.bonusCode)))
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["miraxBonusCodeText", { active: isCopiyed.value }])
                          }, [
                            _createTextVNode(_toDisplayString(template.value.bonusCode) + " ", 1),
                            (isCopiyed.value)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                  _cache[2] || (_cache[2] = _createElementVNode("svg", {
                                    width: "12",
                                    height: "9",
                                    viewBox: "0 0 12 9",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }, [
                                    _createElementVNode("path", {
                                      d: "M4.74264 5.65685L1.91421 2.82843L0.5 4.24264L4.74264 8.48528L11.8137 1.41421L10.3995 0L4.74264 5.65685Z",
                                      fill: "white"
                                    })
                                  ], -1)),
                                  _createTextVNode(" " + _toDisplayString(template.value.copiedText), 1)
                                ]))
                              : (template.value.bonusCode)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(template.value.copyBonusCodeText), 1))
                                : _createCommentVNode("", true)
                          ], 2)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("button", {
                      class: "modern-custom-button",
                      onClick: _withModifiers(registerHandler, ["prevent"])
                    }, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(registerTitle.value), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 16),
          (_unref(isMobile) || _unref(isTablet))
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(Markdown, {
                    "auto-link-parse": false,
                    text: template.value.firstTitle,
                    class: "modern-head__title-top"
                  }, null, 8, ["text"]),
                  _createVNode(Markdown, {
                    "auto-link-parse": false,
                    text: _unref(secondTitle),
                    class: "modern-head__title"
                  }, null, 8, ["text"]),
                  _createVNode(Markdown, {
                    "auto-link-parse": false,
                    text: template.value.thirdTitle,
                    class: "modern-head__text"
                  }, null, 8, ["text"]),
                  (template.value.fourthTitle)
                    ? (_openBlock(), _createBlock(Markdown, {
                        key: 0,
                        "auto-link-parse": false,
                        text: template.value.fourthTitle,
                        class: "modern-head__title"
                      }, null, 8, ["text"]))
                    : _createCommentVNode("", true),
                  (template.value.bonusCode)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "miraxBonusCodeTempate",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (copyToClipboard(template.value.bonusCode)))
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["miraxBonusCodeText", { active: isCopiyed.value }])
                        }, [
                          _createTextVNode(_toDisplayString(template.value.bonusCode) + " ", 1),
                          (isCopiyed.value)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                _cache[3] || (_cache[3] = _createElementVNode("svg", {
                                  width: "12",
                                  height: "9",
                                  viewBox: "0 0 12 9",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }, [
                                  _createElementVNode("path", {
                                    d: "M4.74264 5.65685L1.91421 2.82843L0.5 4.24264L4.74264 8.48528L11.8137 1.41421L10.3995 0L4.74264 5.65685Z",
                                    fill: "white"
                                  })
                                ], -1)),
                                _createTextVNode(" " + _toDisplayString(template.value.copiedText), 1)
                              ]))
                            : (template.value.bonusCode)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(template.value.copyBonusCodeText), 1))
                              : _createCommentVNode("", true)
                        ], 2)
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    class: "modern-custom-button",
                    onClick: _withModifiers(registerHandler, ["prevent"])
                  }, [
                    _createElementVNode("span", _hoisted_17, _toDisplayString(registerTitle.value), 1)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "modern-steper__number" }, "1.", -1)),
              _createVNode(Markdown, {
                "auto-link-parse": false,
                text: template.value.firstStep,
                class: "modern-steper__center"
              }, null, 8, ["text"])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _cache[5] || (_cache[5] = _createElementVNode("span", { class: "modern-steper__number" }, "2.", -1)),
              _createVNode(Markdown, {
                "auto-link-parse": false,
                text: template.value.secondStep,
                class: "modern-steper__center"
              }, null, 8, ["text"])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _cache[6] || (_cache[6] = _createElementVNode("span", { class: "modern-steper__number" }, "3.", -1)),
              _createVNode(Markdown, {
                "auto-link-parse": false,
                text: template.value.thirdStep,
                class: "modern-steper__center"
              }, null, 8, ["text"])
            ])
          ])
        ]),
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"modern-supplied\"><h3>Partners</h3><span><img class=\"modern-supplied__img\" src=\"" + _imports_0 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_1 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_2 + "\" alt=\"img\"></span></div><div class=\"modern-supplied modern-supplied__bottom\"><h3>Casino payment methods</h3><span><img class=\"modern-supplied__img\" src=\"" + _imports_3 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_4 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_5 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_6 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_7 + "\" alt=\"img\"><img class=\"modern-supplied__img\" src=\"" + _imports_8 + "\" alt=\"img\"></span></div>", 2))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}
}

})