import { PropType } from 'vue';

export const basicInputProps = {
  modelValue: {
    type: [String, Number],
    default: () => '',
  },
  placeholder: {
    type: String as PropType<string | undefined | null>,
    default: '',
  },
  formFieldName: {
    type: String as PropType<string | undefined | null>,
    default: null,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: '',
  },
  isValid: {
    type: Boolean,
    default: false,
  },
  isWarning: {
    type: Boolean,
    default: false,
  },
  warningMessage: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  locked: {
    type: Boolean,
    default: false,
  },
  inputClass: {
    type: String,
    default: '',
  },
  maxlength: {
    type: Number,
    default: null,
  },
  focusTrigger: {
    type: Boolean,
    default: false,
  },
  isNoMargin: {
    type: Boolean,
    default: false,
  },
  textLabel: {
    type: String,
    default: '',
  },
  defaultValue: {
    type: String as PropType<string | undefined | null>,
    default: '',
  },
  focusWithoutScrolling: {
    type: Boolean,
    default: false,
  },
  gtmid: {
    type: String,
    default: null,
  },
};
