import axios from 'axios';

export const useMetaPixel = () => {
  const sendEvent = async () => {
    if (!localStorage.getItem('isPwa')) {
      return;
    }

    const data = [
      {
        event_name: 'CompleteRegistration',
        event_time: Math.floor(Date.now() / 1000),
        // event_id: 'Event id',
        event_source_url: 'https://wild-cash.site',
        user_data: {
          client_ip_address: (await axios.get('https://api.ipify.org?format=json')).data.ip,
          client_user_agent: navigator.userAgent,
        },
      },
    ];

    const accessToken =
      'EAAG7ZAz5xuycBO2lurDoOZBwOZA03m0uiwW1MUCSMiZCNJXvmy4ZAZCIOX1iZBmtuSoaNO5t61Gqss3Ob6YLrS7EGIPvDasGnRzUY6MPLVSqMMpNhizglyWUn7Ui0mZAEvrfXgMUrZCbjX5ZCrV1YJKOCqmZCcj709ZCZBUgCjGXmHZCdrF9M9LnmKzZCFT5JE7sz1QCWf96QZDZD';

    const pixelId = '474764162300880';

    axios
      .post(
        `https://graph.facebook.com/v19.0/${pixelId}/events`,
        { data },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        console.info('Response:', response.data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return {
    sendEvent,
  };
};
