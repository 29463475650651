import { defineStore } from 'pinia';
import { useStoreCountry } from '../client/country';
import { useStoreCurrencyMenu } from './currencyMenu';
import { useStoreLanding } from './landing';

export const useStoreSnippet = defineStore('snippet', {
  state: () => ({
    data: {} as Record<string, number>,
    listCryptoWithFiatRules: ['XRP', 'ADA', 'DOG', 'DOGE', 'TRX', 'USDT'],
  }),
  actions: {
    SAVE_DATA_FROM_STRAPI(data) {
      if (data.snippets) {
        this.data = data.snippets.reduce((result: Record<string, number>, item: any) => {
          if (item && item.currency) {
            result[item.currency] = Number(item.eurEquivalent);
          }
          return result;
        }, {} as Record<string, number>);
      }
    },
    rounding(value: number, currency: string) {
      const currencyMenuStore = useStoreCurrencyMenu();
      const isCrypto = currencyMenuStore.isCryptoCurrency(currency);
      const precision =
        currencyMenuStore.currenciesGetter.find((c) => c.currencyCode === currency)?.precision || 0;

      if (!isCrypto || this.listCryptoWithFiatRules.includes(currency)) {
        const v = Math.round(value);

        if (v % 5 === 0 || v % 10 === 0) {
          return v;
        }

        return v % 10 > 5 ? v + (10 - (v % 10)) : v + (5 - (v % 5));
      }

      const roundedValue = Number(value.toFixed(precision));

      if (roundedValue < 1) {
        return value;
      }
      return roundedValue;
    },
    format(text: string) {
      const storeLanding = useStoreLanding();
      const storeCurrencyMenu = useStoreCurrencyMenu();
      const defaultCurrencyForCountry = storeCurrencyMenu.activeCurrencies.find((currency) =>
        currency.defaultForCountries.some((country) => country === useStoreCountry().userCountry)
      );

      let userCurrency = '';

      if (storeLanding.currency) {
        userCurrency = storeLanding.currency;
      } else if (defaultCurrencyForCountry?.currencyCode) {
        userCurrency = defaultCurrencyForCountry.currencyCode;
      } else {
        userCurrency = storeCurrencyMenu.activeCurrencies[0]?.currencyCode;
      }

      return text.replace(/\{[^}]*_auto_currency[^}]*\}/g, (value: string) => {
        const [autoCurrency, customCurrency] = value.replace(/\{|\}/g, '').split('_auto_currency');

        const customCurrencyObj = customCurrency.split(' / ').reduce((result, item) => {
          if (item) {
            const [v, k] = item.split(' ');

            result[k] = v;
          }

          return result;
        }, {} as Record<string, string>);
        const finalCurrencyValue =
          customCurrencyObj[userCurrency] !== undefined
            ? customCurrencyObj[userCurrency]
            : this.rounding(this.data[userCurrency] * Number(autoCurrency), userCurrency);

        return `${finalCurrencyValue} ${userCurrency}`;
      });
    },
  },
});
