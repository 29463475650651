import { ValidationRuleWithParams } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import passSecurityFn from './helpers/passSecurityFn';

export default (password: string): ValidationRuleWithParams =>
  helpers.withParams(
    {
      message: 'Your password is too weak',
      value: password,
    },
    (value: string) => helpers.req(value) && !!passSecurityFn(value)
  );
