<template>
  <div
    v-click-outside="hideList"
    class="bitSelect"
    :class="[
      {
        bitSelectNoMargin: isNoMargin,
        bitSelectError: isError && isSelectActive,
        bitSelectErrorNoMessage: isError && isNoErrorMessage,
        bitSelectDisabled: disabled || locked,
        bitSelectVariantFilter: variant === 'filter',
        bitSelectVariantCurrencySm: variant === 'currency-small',
        bitSelectVariantLanguage: variant === 'language',
      },
    ]"
  >
    <label v-if="textLabel" class="bitSelectLabelText">{{ textLabel }}</label>
    <span
      data-name="dropdown_name"
      class="bitSelectName"
      :class="[
        {
          bitSelectNameActive: isSelectionListVisble,
          bitSelectNamePlaceholderActive: !selectedOptionValue,
        },
      ]"
      :gtmid="gtmid"
      @click="toggleList()"
    >
      <span v-if="$slots.appendInputIcon" class="bitSelectIconAppendInput">
        <slot name="appendInputIcon" />
      </span>

      <span class="bitSelectText" :gtmid="gtmid">
        {{ customSelectedLabel || selectedOptionValue || placeholderValue }}
      </span>
      <span class="bitSelectIconDefault">
        <img
          v-if="!locked"
          class="bitSelectIconArrow"
          src="~assets/images/common/ico_slider_arrow_right.svg"
          :gtmid="gtmid"
        />
        <img v-else src="~assets/images/common/lock.svg" alt="lock" />
      </span>
    </span>
    <div class="bitSelectContainer" :class="{ bitSelectShow: isSelectionListVisble }">
      <ul class="bitSelectList">
        <SelectItem
          v-for="(option, i) of options"
          :key="i"
          :option-text="option"
          :is-selected="i == selectedOptionIndex"
          @click="optionSelect(String(i))"
        >
          <template v-if="$slots.appendOptionIcon" #appendOptionIcon>
            <slot name="appendOptionIcon" :value="option" />
          </template>
        </SelectItem>
      </ul>
    </div>
    <span
      v-if="isError && isSelectActive && !isNoErrorMessage && errorMessage"
      class="bitSelectErrorText"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import { PropType, computed, defineComponent, ref, watch } from 'vue';
import SelectItem from '../selectItem/selectItem.vue';
import clickOutside from '~/utils/directives/vClickOutside';

export interface ChangeValueEventInterface {
  index: string | null;
  value: string;
}

export default defineComponent({
  components: {
    SelectItem,
  },
  directives: {
    clickOutside,
  },
  props: {
    options: {
      type: [Object, Array] as PropType<number[] | string[] | { [key: string]: string | number }>,
      required: true,
    },
    defaultIndex: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: () => null,
    },
    defaultValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String as PropType<string | undefined | null>,
      default: '',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    textLabel: {
      type: String,
      default: '',
    },
    customSelectedLabel: {
      type: String,
      default: null,
    },
    isNoMargin: {
      type: Boolean,
      default: false,
    },
    isNoErrorMessage: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<'currency-small' | 'filter' | 'language' | null>,
      default: null,
    },
    gtmid: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const index = ref<string | null>(null);

    const isSelectionListVisble = ref(false);

    const isSelectActive = computed(() => !props.disabled && !props.locked);

    const getIndexByValue = (value: string | number): string | null => {
      if (Array.isArray(props?.options)) {
        const index = props.options.findIndex((v: string | number) => String(v) === String(value));
        if (index >= 0) {
          return String(index);
        }
      } else {
        return String(value);
      }

      return null;
    };

    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          index.value = getIndexByValue(props.modelValue);
        }
      },
      { immediate: true }
    );

    /**
     * method for shows a list of options
     */
    const toggleList = () => {
      if (!isSelectActive.value) {
        return;
      }
      isSelectionListVisble.value = !isSelectionListVisble.value;
    };

    /**
     * method for closing after the selected item
     */
    const hideList = () => {
      isSelectionListVisble.value = false;
    };

    const selectOptions = computed((): { [key: string]: string | number } => {
      if (Array.isArray(props?.options)) {
        const parsedOptions: { [key: string]: string | number } = {};
        props.options.forEach((value: string | number, index: number) => {
          parsedOptions[String(index)] = value;
        });
        return parsedOptions;
      } else {
        return props?.options;
      }
    });

    /**
     * method for change the value in the interface
     */
    const onChange = (params: ChangeValueEventInterface) => {
      if (Array.isArray(props?.options)) {
        emit('update:modelValue', params.value);
      } else {
        emit('update:modelValue', params.index);
      }
    };

    /**
     * getter for placeholder show or an empty string
     */
    const placeholderValue = computed((): string => {
      return props.placeholder || '';
    });

    /**
     * getter for return index of selected option
     *
     * @returns selected optionindex number if exist
     * @returns null if no selected data
     *  */
    const selectedOptionIndex = computed((): string | null => {
      if (index.value === null) {
        if (props.defaultIndex) {
          return String(props.defaultIndex);
        } else if (props.defaultValue) {
          return getIndexByValue(props.defaultValue);
        } else {
          return '';
        }
      } else {
        return String(index.value);
      }
    });

    /**
     * getter for return value of option
     *
     * @returns selected option if exist or selected
     * @returns empty string if not selected or not exist
     *  */
    const selectedOptionValue = computed((): string => {
      if (selectedOptionIndex.value === null || !selectOptions.value[selectedOptionIndex.value]) {
        return '';
      } else {
        return String(selectOptions.value[selectedOptionIndex.value]);
      }
    });

    /**
     *  method for selection and close the list
     *
     * @param optionIndex - parameters for assigning a value
     */
    const optionSelect = (optionIndex: string) => {
      index.value = optionIndex;
      hideList();
      onChange({
        index: selectedOptionIndex.value,
        value: selectedOptionValue.value,
      });
    };

    return {
      optionSelect,
      placeholderValue,
      toggleList,
      hideList,
      isSelectionListVisble,
      selectedOptionValue,
      selectedOptionIndex,
      isSelectActive,
    };
  },
});
</script>

<style lang="scss" src="./select.scss"></style>
